import * as React from 'react'
import type { SVGProps } from 'react'
import { memo } from 'react'

const SvgDeleteIcon: React.FC<SVGProps<SVGSVGElement>> = (
  props: SVGProps<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="currentColor"
      d="M6.333 12.25c-.017.26-.156.4-.416.417-.26-.018-.4-.157-.417-.417V6.833c.017-.26.156-.399.417-.416.26.017.399.156.416.416v5.417Zm2.084 0c-.018.26-.157.4-.417.417-.26-.018-.4-.157-.417-.417V6.833c.018-.26.157-.399.417-.416.26.017.4.156.417.416v5.417Zm2.083 0c-.017.26-.156.4-.417.417-.26-.018-.399-.157-.416-.417V6.833c.017-.26.156-.399.416-.416.26.017.4.156.417.416v5.417Zm-.052-9.766.937 1.433h1.823c.382.034.59.243.625.625-.034.382-.243.59-.625.625H13v7.916c-.017.59-.217 1.085-.599 1.485-.4.382-.894.581-1.484.599H5.083c-.59-.018-1.085-.217-1.484-.6-.382-.399-.582-.893-.599-1.484V5.167h-.208c-.382-.035-.59-.243-.625-.625.034-.382.243-.59.625-.625h1.823l.963-1.433a1.46 1.46 0 0 1 1.198-.65h2.448c.503.017.911.234 1.224.65ZM6.125 3.917h3.776l-.495-.73c-.052-.069-.113-.104-.182-.104H6.776c-.087 0-.139.035-.156.104l-.495.73ZM4.25 13.083c0 .243.078.443.234.6a.811.811 0 0 0 .6.234h5.833a.811.811 0 0 0 .599-.235.811.811 0 0 0 .234-.599V5.167h-7.5v7.916Z"
    />
  </svg>
)
const Memo = memo(SvgDeleteIcon)
export default Memo
