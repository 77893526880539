import * as React from 'react'
import { useMemo } from 'react'

import { Transport } from '@connectrpc/connect'
import { QueryClient } from '@tanstack/react-query'
import { RouterProvider, RouterProviderProps } from 'react-router-dom'

import { FullLoadingView } from '../components/full-loading-view'

type AppRouterProviderProps = {
  transport: Transport
  queryClient: QueryClient
  createRouter: (
    transport: Transport,
    queryClient: QueryClient,
  ) => RouterProviderProps['router']
} & Omit<RouterProviderProps, 'router'>

const AppRouterProvider: React.FC<AppRouterProviderProps> = ({
  transport,
  queryClient,
  createRouter,
  fallbackElement = <FullLoadingView />,
  ...rest
}) => {
  const router = useMemo(() => {
    return createRouter(transport, queryClient)
  }, [transport, queryClient, createRouter])

  return (
    <RouterProvider
      fallbackElement={fallbackElement}
      router={router}
      {...rest}
    />
  )
}

export default AppRouterProvider
