import * as React from 'react'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Flex, Text, UseDisclosureReturn } from '@chakra-ui/react'

import {
  PrimaryModal,
  Spacing,
  Typography,
} from '@enechain/ecloud-designsystem'

import { routePaths } from '~/routes/routePaths'

type Props = {
  disclosure: UseDisclosureReturn
}

const OrderCompleteModal: React.FC<Props> = ({ disclosure }) => {
  const { t: commonT } = useTranslation('common')
  const { t } = useTranslation('trader', {
    keyPrefix: 'order_confirm.order_complete_modal',
  })

  const navigate = useNavigate()

  const onClickTender = (): void => {
    navigate(routePaths.tender())
  }

  const onClickClose = (): void => {
    navigate(routePaths.history())
  }

  return (
    <PrimaryModal
      actionText={t('proceed')}
      bodyChildren={
        <Flex
          flexDirection="column"
          gap={Spacing['4']}
          paddingBottom={Spacing['0']}
        >
          <Text {...Typography.headerSm}>{t('message')}</Text>
          <Text
            wordBreak="keep-all"
            overflowWrap="break-word"
            {...Typography.textMd}
          >
            {t('description')}
          </Text>
        </Flex>
      }
      closeText={commonT('action.close')}
      disclosure={disclosure}
      onClick={onClickTender}
      onCloseComplete={onClickClose}
      size="lg"
      title={t('title')}
    />
  )
}

export default React.memo(OrderCompleteModal)
