import { SleeveType } from '@enechain/etender-proto/proto/etender/order/v1/model_pb'
import { Tender } from '@enechain/etender-proto/proto/etender/tender/v1/model_pb'

export const TenderStatuses = [
  'biddingNotStarted',
  'biddingInProgress',
  'biddingClosed',
] as const
export type TenderStatus = (typeof TenderStatuses)[number]

export function getTenderStatus(
  tender: Tender | undefined,
  now: Date,
  sleeveType: SleeveType,
): TenderStatus {
  const openDate = tender?.schedule?.openAt?.toDate()
  const closeDate = tender?.schedule?.closeAt?.toDate()
  const ultimateBuyerCloseDate =
    tender?.schedule?.ultimateBuyerCloseAt?.toDate()

  if (openDate == null || now < openDate) {
    return 'biddingNotStarted'
  }

  // スリーバーありのUB、USでは早めにclosedになる
  if (
    sleeveType !== SleeveType.UNSPECIFIED &&
    sleeveType !== SleeveType.NONE &&
    ultimateBuyerCloseDate != null &&
    !(now < ultimateBuyerCloseDate)
  ) {
    return 'biddingClosed'
  }

  if (closeDate == null || now < closeDate) {
    return 'biddingInProgress'
  }

  return 'biddingClosed'
}
