import * as React from 'react'

import { Code, ConnectError } from '@connectrpc/connect'
import { useRouteError } from 'react-router-dom'

import { Box } from '@chakra-ui/react'

import NotFoundLayout from '../NotFoundLayout'
import BasicErrorPage from './BasicErrorPage'
import PermissionDeniedErrorPage from './PermissionDeniedErrorPage'
import UnauthorizedErrorPage from './UnauthorizedErrorPage'

const ErrorLayout: React.FC = () => {
  const error = useRouteError()

  if (!(error instanceof ConnectError)) {
    // TODO: エラーページのデザインが更新されたら対応する
    // @ts-ignore
    if (error?.status != null && error.status === 404) {
      return <NotFoundLayout />
    }
    return <BasicErrorPage />
  }

  const errorPage = (): JSX.Element => {
    console.log('error.code', error.code)
    switch (error.code) {
      case Code.NotFound:
        return <NotFoundLayout />
      case Code.PermissionDenied:
        return <PermissionDeniedErrorPage />
      case Code.Unauthenticated:
        return <UnauthorizedErrorPage />
      default:
        return <BasicErrorPage />
    }
  }

  return <Box>{errorPage()}</Box>
}

export default ErrorLayout
