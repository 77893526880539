// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file bff/v1/data_set_model.proto (package bff.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { Organization, User } from "./user_model_pb.js";
import { MyDeal } from "@enechain/etender-proto/proto/etender/deal/v1/model_pb.js";
import { Tender } from "@enechain/etender-proto/proto/etender/tender/v1/model_pb.js";
import { TenderTerms } from "@enechain/etender-proto/proto/etender/tender_terms/v1/model_pb.js";
import { Order } from "@enechain/etender-proto/proto/etender/order/v1/model_pb.js";

/**
 * BFFによって付帯されるデータセット
 *
 * @generated from message bff.v1.DataSet
 */
export const DataSet = /*@__PURE__*/ proto3.makeMessageType(
  "bff.v1.DataSet",
  () => [
    { no: 1, name: "users", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: User} },
    { no: 2, name: "organizations", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: Organization} },
  ],
);

/**
 * @generated from message bff.v1.MyDealsDataset
 */
export const MyDealsDataset = /*@__PURE__*/ proto3.makeMessageType(
  "bff.v1.MyDealsDataset",
  () => [
    { no: 1, name: "deals", kind: "message", T: MyDeal, repeated: true },
  ],
);

/**
 * Order用のデータセット
 *
 * @generated from message bff.v1.DataSetForOrder
 */
export const DataSetForOrder = /*@__PURE__*/ proto3.makeMessageType(
  "bff.v1.DataSetForOrder",
  () => [
    { no: 1, name: "tenders", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: Tender} },
    { no: 2, name: "tender_terms", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: TenderTerms} },
    { no: 3, name: "orders", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: Order} },
    { no: 4, name: "deals", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: MyDealsDataset} },
  ],
);

