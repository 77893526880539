import * as React from 'react'
import type { SVGProps } from 'react'
import { memo } from 'react'

const SvgGavelIcon: React.FC<SVGProps<SVGSVGElement>> = (
  props: SVGProps<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#000"
      fillOpacity={0.62}
      d="M16.855 8.788a.62.62 0 0 1-.187.455L13.24 12.67a.62.62 0 0 1-.455.188.606.606 0 0 1-.402-.16c-.143-.108-.223-.269-.24-.483a.64.64 0 0 1 .16-.428l-1.66-1.66-1.902 1.9.161.161c.16.179.241.375.241.59 0 .232-.08.437-.24.615l-3.08 3.08a.894.894 0 0 1-.616.24.802.802 0 0 1-.59-.24L3.387 15.24a.862.862 0 0 1-.241-.59c0-.231.08-.437.241-.615l3.08-3.08a.894.894 0 0 1 .615-.24c.214 0 .41.08.59.24l.16.161 1.901-1.9-1.66-1.661a.64.64 0 0 1-.428.16c-.393-.035-.607-.25-.643-.642a.62.62 0 0 1 .187-.455l3.428-3.428a.62.62 0 0 1 .455-.187c.143 0 .277.053.402.16.143.09.223.25.24.482a.64.64 0 0 1-.16.429l4.23 4.23a.64.64 0 0 1 .43-.16c.231.018.392.098.481.241.107.143.161.277.161.402Zm-3.642 2.088 1.66-1.66-4.23-4.23-1.66 1.66 4.23 4.23Z"
    />
  </svg>
)
const Memo = memo(SvgGavelIcon)
export default Memo
