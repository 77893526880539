import * as React from 'react'

import { useTranslation } from 'react-i18next'

import { Flex, Text } from '@chakra-ui/react'

import {
  FlexTableRow,
  SemanticColors,
  Spacing,
  Typography,
} from '@enechain/ecloud-designsystem'
import { EmptyIcon } from '@enechain/etender-icons'

const EmptyBidRow: React.FC = () => {
  const { t } = useTranslation('trader', {
    keyPrefix: 'bid_table',
  })
  return (
    <FlexTableRow>
      <Flex
        alignItems="center"
        justifyContent="center"
        gap={Spacing['0.5']}
        width="100%"
        minHeight="64px"
      >
        <EmptyIcon
          width="20px"
          height="20px"
          fill={SemanticColors.Object.lowEmphasis}
        />
        <Text color={SemanticColors.Text.lowEmphasis} {...Typography.textMd}>
          {t('empty')}
        </Text>
      </Flex>
    </FlexTableRow>
  )
}

export default EmptyBidRow
