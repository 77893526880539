import * as React from 'react'

import { useTranslation } from 'react-i18next'

import {
  Accordion,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
} from '@chakra-ui/react'

import AccordionAllButton from '@enechain/common-ui/src/components/Accordion/AccordionAllButton'
import {
  BorderRadius,
  FlexTable,
  FlexTableBody,
  FlexTableCell,
  FlexTableHeader,
  FlexTableRow,
  SemanticColors,
  useAccordion,
} from '@enechain/ecloud-designsystem'

import {
  TenderColumnKey,
  tenderColumnStyles,
} from '~/pages/tender/components/TenderTable/TenderColumnStyles'
import TenderTableRow from '~/pages/tender/components/TenderTable/TenderTableRow'
import TenderTermsTable from '~/pages/tender/components/TenderTermsTable'
import { useTenderIds } from '~/pages/tender/hooks/useTenderList'

type TenderData = {
  tenderIds: string[]
}

const useTenderData = (): TenderData => {
  const tenderIds = useTenderIds()

  return {
    tenderIds,
  }
}

const TenderList: React.FC = () => {
  const { t } = useTranslation('trader', { keyPrefix: 'tender.list' })

  const { tenderIds } = useTenderData()
  const { onCollapseAll, onExpandAll, index, onChange, isAllCollapsed } =
    useAccordion(
      tenderIds,
      tenderIds.map((_, i) => i),
    )

  return (
    <Flex flexDirection="column" gap={2} width="100%">
      <Flex justifyContent="flex-end">
        <AccordionAllButton
          isAllCollapsed={isAllCollapsed}
          onClickCollapseAll={onCollapseAll}
          onClickExpandAll={onExpandAll}
        />
      </Flex>
      <Box>
        <FlexTable<TenderColumnKey>
          borderWidth="1px"
          borderRadius={BorderRadius.radiiLg}
          columnStyles={tenderColumnStyles}
        >
          <FlexTableHeader style="multi-line">
            <FlexTableRow>
              <FlexTableCell<TenderColumnKey> columnKey="accordion" />
              <FlexTableCell<TenderColumnKey>
                columnKey="tenderId"
                contents={{ text: t('label.tender_id') }}
              />
              <FlexTableCell<TenderColumnKey>
                columnKey="ownerOrganization"
                contents={{
                  text: t('label.owner_organization_name'),
                }}
              />
              <FlexTableCell<TenderColumnKey>
                columnKey="name"
                contents={{ text: t('label.name') }}
              />
              <FlexTableCell<TenderColumnKey>
                columnKey="openAt"
                contents={{ text: t('label.open_at') }}
              />
              <FlexTableCell<TenderColumnKey>
                columnKey="closeAt"
                contents={{
                  text: t('label.close_at'),
                }}
              />
              <FlexTableCell<TenderColumnKey>
                columnKey="ultimateBuyerCloseAt"
                contents={{
                  text: t('label.close_except_ultimate_buyer_at'),
                  textMaxLines: 4,
                }}
              />
              <FlexTableCell<TenderColumnKey>
                columnKey="awardAnnounceAt"
                contents={{ text: t('label.award_announce_at') }}
              />
              <FlexTableCell<TenderColumnKey>
                columnKey="attachments"
                contents={{ text: t('label.attachment_name') }}
              />
              <FlexTableCell<TenderColumnKey> columnKey="action" />
            </FlexTableRow>
          </FlexTableHeader>
          <FlexTableBody style="multi-line">
            <Accordion
              borderColor={SemanticColors.Border.midEmphasis}
              allowMultiple
              index={index}
              onChange={onChange}
            >
              {tenderIds.map((tenderId) => (
                <AccordionItem key={tenderId} _last={{ borderBottomWidth: 0 }}>
                  <TenderTableRow tenderId={tenderId} />
                  <AccordionPanel padding={0}>
                    <TenderTermsTable tenderId={tenderId} />
                  </AccordionPanel>
                </AccordionItem>
              ))}
            </Accordion>
          </FlexTableBody>
        </FlexTable>
      </Box>
    </Flex>
  )
}

export default TenderList
