import * as React from 'react'

import { Transport } from '@connectrpc/connect'
import { createQueryOptions } from '@connectrpc/connect-query'
import { QueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { Await, LoaderFunction, defer, useLoaderData } from 'react-router-dom'

import { Flex } from '@chakra-ui/react'

import { EmptyLayout, ErrorLayout } from '@enechain/common-ui'
import {
  SideMenuLayoutBreadcrumb,
  SideMenuLayoutContentLayout,
} from '@enechain/ecloud-designsystem'
import { DataSet } from '@enechain/etender-proto/proto/bff/v1/data_set_model_pb'
import { listForTrader } from '@enechain/etender-proto/proto/bff/v1/tender_service-TenderService_connectquery'
import { ListForTraderResponse } from '@enechain/etender-proto/proto/bff/v1/tender_service_pb'
import { BatchListResponse } from '@enechain/etender-proto/proto/etender/tender_terms/v1/service_pb'

import Header from '~/pages/tender/components/Header'
import TenderTable from '~/pages/tender/components/TenderTable'
import { useTenderIds } from '~/pages/tender/hooks/useTenderList'

export type TenderLoaderData = {
  listForTraderResponse: ListForTraderResponse
  batchListResponse: BatchListResponse
  dataSet: DataSet
  pageToken: string
  totalSize: number
}

export const tenderLoader: (
  transport: Transport,
  queryClient: QueryClient,
) => LoaderFunction =
  (transport, queryClient) =>
  ({ request }) => {
    const url = new URL(request.url)
    const pageToken = url.searchParams.get('pageToken') ?? ''
    return defer({
      pageToken,
      response: queryClient.ensureQueryData(
        createQueryOptions(
          listForTrader,
          {
            pageToken,
            pageSize: 20,
          },
          { transport },
        ),
      ),
    })
  }

const TenderPage: React.FC = () => {
  const { t } = useTranslation('trader', { keyPrefix: 'tender' })
  const { listForTraderResponse } = useLoaderData() as TenderLoaderData
  const tenderIds = useTenderIds()

  return (
    <Await errorElement={<ErrorLayout />} resolve={listForTraderResponse}>
      <SideMenuLayoutContentLayout
        header={
          <SideMenuLayoutBreadcrumb
            items={[{ label: t('page_title'), bold: true }]}
          />
        }
      >
        <Header />
        {tenderIds.length === 0 ? (
          <Flex height="100%">
            <EmptyLayout />
          </Flex>
        ) : (
          <Flex
            overflow="scroll"
            width="100%"
            paddingTop={4}
            paddingBottom={16}
            paddingX={4}
          >
            <TenderTable />
          </Flex>
        )}
      </SideMenuLayoutContentLayout>
    </Await>
  )
}

export default TenderPage
