import { Code, ConnectError, Transport } from '@connectrpc/connect'
import { createQueryOptions } from '@connectrpc/connect-query'
import { QueryClient } from '@tanstack/react-query'
import { LoaderFunction, defer } from 'react-router-dom'

import { getEditingOrder } from '@enechain/etender-proto/proto/bff/v1/order_service-OrderService_connectquery'
import { verifyBiddable } from '@enechain/etender-proto/proto/etender/tender/v1/service-Service_connectquery'

export type OrderConfirmUrlParams = {
  tenderId: string
  orderId: string
}

export type OrderConfirmLoaderData = {
  urlParams: OrderConfirmUrlParams
}

export function orderConfirmLoader(
  transport: Transport,
  queryClient: QueryClient,
): LoaderFunction {
  return ({ params }) => {
    async function getUrlParams(): Promise<OrderConfirmUrlParams> {
      const tenderId = params.tenderId
      if (tenderId == null) {
        throw new Response('tenderId is required', { status: 400 })
      }
      const orderId = params.orderId
      if (orderId == null) {
        throw new Response('orderId is required', { status: 400 })
      }

      try {
        await queryClient.ensureQueryData(
          createQueryOptions(
            verifyBiddable,
            {
              tenderId: tenderId,
            },
            {
              transport,
            },
          ),
        )
      } catch (error) {
        if (
          error instanceof ConnectError &&
          error.code === Code.FailedPrecondition
        ) {
          throw new Response('tender is not biddable', { status: 404 })
        }

        throw error
      }

      const orderResponse = await queryClient.ensureQueryData(
        createQueryOptions(
          getEditingOrder,
          {
            orderId: orderId,
          },
          {
            transport,
          },
        ),
      )

      if (orderResponse.editingOrder?.validationResult?.isValid !== true) {
        throw new Response('Order is not valid', { status: 400 })
      }

      return {
        tenderId,
        orderId,
      }
    }

    return defer({
      urlParams: getUrlParams(),
    })
  }
}
