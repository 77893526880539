// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file bff/v1/bid_service.proto (package bff.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { DataSet, DataSetForOrder } from "./data_set_model_pb.js";
import { OrderHistory } from "./order_model_pb.js";
import { Bids } from "@enechain/etender-proto/proto/etender/bid/v1/model_pb.js";

/**
 * 指定した入札案件の自分の応札を取得するリクエスト
 *
 * @generated from message bff.v1.ListByTenderIDRequest
 */
export const ListByTenderIDRequest = /*@__PURE__*/ proto3.makeMessageType(
  "bff.v1.ListByTenderIDRequest",
  () => [
    { no: 1, name: "tender_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * 指定した入札案件の自分の応札を取得するレスポンス
 *
 * @generated from message bff.v1.ListByTenderIDResponse
 */
export const ListByTenderIDResponse = /*@__PURE__*/ proto3.makeMessageType(
  "bff.v1.ListByTenderIDResponse",
  () => [
    { no: 1, name: "data_set", kind: "message", T: DataSet },
    { no: 2, name: "data_set_for_order", kind: "message", T: DataSetForOrder },
    { no: 3, name: "order_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "histories", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: OrderHistory} },
    { no: 5, name: "last_applied_at", kind: "message", T: Timestamp, opt: true },
  ],
);

/**
 * 指定した入札案件のすべての応札を取得するリクエスト
 *
 * @generated from message bff.v1.ListAllByTenderIDRequest
 */
export const ListAllByTenderIDRequest = /*@__PURE__*/ proto3.makeMessageType(
  "bff.v1.ListAllByTenderIDRequest",
  () => [
    { no: 1, name: "tender_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * 指定した入札案件のすべての応札を取得するレスポンス
 *
 * @generated from message bff.v1.ListAllByTenderIDResponse
 */
export const ListAllByTenderIDResponse = /*@__PURE__*/ proto3.makeMessageType(
  "bff.v1.ListAllByTenderIDResponse",
  () => [
    { no: 1, name: "bids", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: Bids} },
    { no: 2, name: "data_set", kind: "message", T: DataSet },
    { no: 3, name: "data_set_for_order", kind: "message", T: DataSetForOrder },
    { no: 4, name: "tender_terms_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * 指定した入札案件のすべての応札をCSV形式で取得するリクエスト
 *
 * @generated from message bff.v1.GetAllByTenderIDAsCsvRequest
 */
export const GetAllByTenderIDAsCsvRequest = /*@__PURE__*/ proto3.makeMessageType(
  "bff.v1.GetAllByTenderIDAsCsvRequest",
  () => [
    { no: 1, name: "tender_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * 指定した入札案件のすべての応札をCSV形式で取得するレスポンス
 *
 * @generated from message bff.v1.GetAllByTenderIDAsCsvResponse
 */
export const GetAllByTenderIDAsCsvResponse = /*@__PURE__*/ proto3.makeMessageType(
  "bff.v1.GetAllByTenderIDAsCsvResponse",
  () => [
    { no: 1, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

