import * as React from 'react'

import { ActionMeta, MultiValue } from 'chakra-react-select'

import Select, { SelectOption, SelectProps } from './index'

export type MultiSelectProps = {
  selectedOptions: SelectOption[]
  onChangeSelectedOptions?: (options: SelectOption[]) => void
  onOptionSelected?: (option: SelectOption) => void
  onOptionRemoved?: (option: SelectOption) => void
} & SelectProps<true>

const MultiSelect: React.FC<MultiSelectProps> = ({
  selectedOptions,
  onChangeSelectedOptions,
  onOptionSelected,
  onOptionRemoved,
  ...rest
}) => {
  const onChange = (
    inputValue: MultiValue<SelectOption>,
    actionMeta: ActionMeta<SelectOption>,
  ): void => {
    if (onChangeSelectedOptions != null) {
      onChangeSelectedOptions(inputValue as SelectOption[])
    }

    switch (actionMeta.action) {
      case 'select-option': {
        if (actionMeta.option?.value == null || onOptionSelected == null) {
          break
        }
        onOptionSelected(actionMeta.option)
        break
      }
      case 'remove-value':
      case 'pop-value': {
        if (actionMeta.removedValue?.value == null || onOptionRemoved == null) {
          break
        }
        onOptionRemoved(actionMeta.removedValue)
        break
      }
    }
  }

  return (
    <Select<true>
      isMulti
      onChange={onChange}
      value={selectedOptions}
      {...rest}
    />
  )
}

export default React.memo(MultiSelect)
