import * as React from 'react'
import type { SVGProps } from 'react'
import { memo } from 'react'

const SvgDownloadFileIcon: React.FC<SVGProps<SVGSVGElement>> = (
  props: SVGProps<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="m12.938 15.398 1.523-1.562a.92.92 0 0 1 1.289 0c.39.39.39.976 0 1.328l-3.125 3.125a.856.856 0 0 1-1.29 0l-3.124-3.125c-.39-.351-.39-.937 0-1.328a.943.943 0 0 1 .664-.274c.234 0 .469.118.625.274l1.563 1.562v-4.023c0-.508.39-.938.937-.938a.95.95 0 0 1 .938.938v4.023Zm5.82-9.726c.469.469.742 1.094.742 1.758V19.5c0 1.406-1.133 2.5-2.5 2.5H7a2.468 2.468 0 0 1-2.5-2.5v-15C4.5 3.133 5.594 2 7 2h7.07c.664 0 1.29.273 1.758.742l2.93 2.93ZM17.625 19.5h-.04V8.25H14.5c-.703 0-1.25-.547-1.25-1.25V3.914H7a.617.617 0 0 0-.625.625V19.5c0 .352.273.625.625.625h10a.642.642 0 0 0 .625-.625Z"
    />
  </svg>
)
const Memo = memo(SvgDownloadFileIcon)
export default Memo
