import * as React from 'react'

import { useSuspenseQuery } from '@connectrpc/connect-query'
import { useTranslation } from 'react-i18next'

import { AccordionItem, AccordionPanel } from '@chakra-ui/react'

import {
  BorderRadius,
  FlexTable,
  FlexTableBody,
  FlexTableCell,
  FlexTableHeader,
  FlexTableRow,
  Spacing,
} from '@enechain/ecloud-designsystem'
import { listOrdered } from '@enechain/etender-proto/proto/bff/v1/tender_service-TenderService_connectquery'

import {
  TenderColumnKey,
  tenderColumnStyles,
} from '~/pages/history/components/OrderedTenderSection/TenderTable/TenderColumnStyles'
import TenderTableBodyRow from '~/pages/history/components/OrderedTenderSection/TenderTable/TenderTableBodyRow'
import TenderTermsTable from '~/pages/history/components/OrderedTenderSection/TenderTermsTable'

type TenderTableData = {
  tenderIds: string[]
}

const useTenderTableData = (
  pageSize: number,
  pageToken?: string,
): TenderTableData => {
  const { data } = useSuspenseQuery(
    listOrdered,
    { pageSize, pageToken },
    {
      select: (response): TenderTableData => {
        return {
          tenderIds: response.tenderIds,
        }
      },
    },
  )
  return data
}

type Props = {
  pageSize: number
  pageToken?: string
}

const TenderTable: React.FC<Props> = ({ pageSize, pageToken }) => {
  const { t } = useTranslation('trader', { keyPrefix: 'history.tender_table' })

  const { tenderIds } = useTenderTableData(pageSize, pageToken)

  return (
    <FlexTable<TenderColumnKey>
      borderWidth="1px"
      borderRadius={BorderRadius.radiiLg}
      columnStyles={tenderColumnStyles}
    >
      <FlexTableHeader style="single-line">
        <FlexTableRow>
          <FlexTableCell<TenderColumnKey> columnKey="accordion" />
          <FlexTableCell<TenderColumnKey>
            columnKey="tenderId"
            contents={{ text: t('tender_id') }}
          />
          <FlexTableCell<TenderColumnKey>
            columnKey="ownerOrganization"
            contents={{ text: t('owner_organization_name') }}
          />
          <FlexTableCell<TenderColumnKey>
            columnKey="name"
            contents={{ text: t('name') }}
          />
          <FlexTableCell<TenderColumnKey>
            columnKey="openAt"
            contents={{ text: t('open_at') }}
          />
          <FlexTableCell<TenderColumnKey>
            columnKey="closeAt"
            contents={{
              text: t('close_at'),
            }}
          />
          <FlexTableCell<TenderColumnKey>
            columnKey="awardAnnounceAt"
            contents={{ text: t('award_announce_at') }}
          />
          <FlexTableCell<TenderColumnKey>
            columnKey="lastAppliedAt"
            contents={{ text: t('last_applied_at') }}
          />
          <FlexTableCell<TenderColumnKey>
            columnKey="attachments"
            contents={{ text: t('attachment_name') }}
          />
          <FlexTableCell<TenderColumnKey> columnKey="detail" />
        </FlexTableRow>
      </FlexTableHeader>
      <FlexTableBody style="single-line">
        {tenderIds.map((tenderId) => (
          <AccordionItem key={tenderId} _last={{ borderBottomWidth: 0 }}>
            <TenderTableBodyRow
              pageSize={pageSize}
              pageToken={pageToken}
              tenderId={tenderId}
            />
            <AccordionPanel padding={Spacing['0']}>
              <TenderTermsTable
                pageSize={pageSize}
                pageToken={pageToken}
                tenderId={tenderId}
              />
            </AccordionPanel>
          </AccordionItem>
        ))}
      </FlexTableBody>
    </FlexTable>
  )
}

export default React.memo(TenderTable)
