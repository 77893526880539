import * as React from 'react'
import type { SVGProps } from 'react'
import { memo } from 'react'

const SvgCloseIcon: React.FC<SVGProps<SVGSVGElement>> = (
  props: SVGProps<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 16 17"
    {...props}
  >
    <path
      fill="#000"
      fillOpacity={0.62}
      d="M11.257 6.064c.2-.243.2-.486 0-.728-.243-.2-.486-.2-.729 0L8 7.886l-2.55-2.55c-.242-.2-.485-.2-.728 0-.2.242-.2.485 0 .728l2.55 2.55-2.55 2.57c-.2.244-.2.487 0 .73.243.2.486.2.728 0L8 9.341l2.55 2.55c.242.2.485.2.728 0 .2-.243.2-.486 0-.729l-2.55-2.55 2.529-2.549Z"
    />
  </svg>
)
const Memo = memo(SvgCloseIcon)
export default Memo
