// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file bff/v1/order_model.proto (package bff.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { Bids } from "@enechain/etender-proto/proto/etender/bid/v1/model_pb.js";
import { ValidationError } from "@enechain/etender-proto/proto/etender/order/v1/model_pb.js";

/**
 * @generated from message bff.v1.OrderHistory
 */
export const OrderHistory = /*@__PURE__*/ proto3.makeMessageType(
  "bff.v1.OrderHistory",
  () => [
    { no: 1, name: "order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "tender_terms_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "bids", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: Bids} },
  ],
);

/**
 * 注文画面で入力が必須な追加項目の有無
 *
 * @generated from message bff.v1.OrderAdditionalRequirements
 */
export const OrderAdditionalRequirements = /*@__PURE__*/ proto3.makeMessageType(
  "bff.v1.OrderAdditionalRequirements",
  () => [
    { no: 1, name: "eclear", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "eex", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "tocom", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * 注文画面で入力が必要な追加項目の内容
 *
 * @generated from message bff.v1.OrderAdditionalField
 */
export const OrderAdditionalField = /*@__PURE__*/ proto3.makeMessageType(
  "bff.v1.OrderAdditionalField",
  () => [
    { no: 1, name: "is_valid", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "errors", kind: "message", T: ValidationError, repeated: true },
  ],
);

/**
 * 注文画面で入力が必要な追加項目
 *
 * @generated from message bff.v1.OrderAdditionalFields
 */
export const OrderAdditionalFields = /*@__PURE__*/ proto3.makeMessageType(
  "bff.v1.OrderAdditionalFields",
  () => [
    { no: 1, name: "requirements", kind: "message", T: OrderAdditionalRequirements },
    { no: 2, name: "eclear_trader_id", kind: "message", T: OrderAdditionalField, opt: true },
    { no: 3, name: "eclear_keyword", kind: "message", T: OrderAdditionalField, opt: true },
    { no: 4, name: "eex_trader_name", kind: "message", T: OrderAdditionalField, opt: true },
    { no: 5, name: "eex_clearing_bank", kind: "message", T: OrderAdditionalField, opt: true },
    { no: 6, name: "eex_clearing_account", kind: "message", T: OrderAdditionalField, opt: true },
    { no: 7, name: "tocom_trader_name", kind: "message", T: OrderAdditionalField, opt: true },
    { no: 8, name: "tocom_clearing_bank", kind: "message", T: OrderAdditionalField, opt: true },
    { no: 9, name: "tocom_clearing_account", kind: "message", T: OrderAdditionalField, opt: true },
  ],
);

