// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file bff/v1/tender_model.proto (package bff.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * 一覧画面に表示するための入札条件IDの一覧
 *
 * @generated from message bff.v1.TenderTermsIDs
 */
export const TenderTermsIDs = /*@__PURE__*/ proto3.makeMessageType(
  "bff.v1.TenderTermsIDs",
  () => [
    { no: 1, name: "tender_terms_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

