import * as React from 'react'

import { useSuspenseQuery } from '@connectrpc/connect-query'
import { useTranslation } from 'react-i18next'
import { Await, useLoaderData, useNavigate } from 'react-router-dom'

import { Flex } from '@chakra-ui/react'

import { ErrorLayout } from '@enechain/common-ui'
import {
  SideMenuLayoutBreadcrumb,
  SideMenuLayoutContentLayout,
  Spacing,
} from '@enechain/ecloud-designsystem'
import { listByTenderID } from '@enechain/etender-proto/proto/bff/v1/bid_service-BidService_connectquery'

import OrderHistorySection from '~/pages/history-detail/components/OrderHistorySection'
import TenderInformationSection from '~/pages/history-detail/components/TenderInformationSection'
import {
  HistoryDetailLoaderData,
  HistoryDetailUrlParams,
} from '~/pages/history-detail/loader'
import { routePaths } from '~/routes/routePaths'

type HistoryDetailPageData = {
  tenderName: string
}

const useHistoryDetailPageData = (
  tenderId: string,
): HistoryDetailPageData | undefined => {
  const { data } = useSuspenseQuery(
    listByTenderID,
    {
      tenderId,
    },
    {
      select: (response): HistoryDetailPageData | undefined => {
        const tender = response.dataSetForOrder?.tenders[tenderId]
        if (tender == null) {
          return undefined
        }
        return {
          tenderName: tender.properties?.name ?? '',
        }
      },
    },
  )
  return data
}

const Layout: React.FC<{ tenderId: string }> = ({ tenderId }) => {
  const { t } = useTranslation('trader', { keyPrefix: 'history_detail' })
  const navigate = useNavigate()

  const data = useHistoryDetailPageData(tenderId)

  return (
    <SideMenuLayoutContentLayout
      header={
        <SideMenuLayoutBreadcrumb
          items={[
            {
              label: t('breadcrumb.history'),
              onClick: (): void => navigate(routePaths.history()),
            },
            { label: data?.tenderName ?? '', bold: true },
          ]}
        />
      }
    >
      <Flex
        flexDirection="column"
        gap={Spacing['6']}
        width="100%"
        height="100%"
        padding={Spacing['4']}
      >
        <TenderInformationSection tenderId={tenderId} />
        <Flex flex={1}>
          <OrderHistorySection tenderId={tenderId} />
        </Flex>
      </Flex>
    </SideMenuLayoutContentLayout>
  )
}

const HistoryDetailPage: React.FC = () => {
  const { urlParams } = useLoaderData() as HistoryDetailLoaderData

  return (
    <Await errorElement={<ErrorLayout />} resolve={urlParams}>
      {(urlParams: HistoryDetailUrlParams): JSX.Element => (
        <Layout tenderId={urlParams.tenderId} />
      )}
    </Await>
  )
}

export default HistoryDetailPage
