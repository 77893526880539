import { Timestamp } from '@bufbuild/protobuf'
import { useSuspenseQuery } from '@connectrpc/connect-query'

import { type MyDeal } from '@enechain/etender-proto/proto/etender/deal/v1/model_pb'
import { listMyDeals } from '@enechain/etender-proto/proto/etender/deal/v1/service-Service_connectquery'

type ListMyDealsResponse = {
  deals: MyDeal[]
  publishedAt: Timestamp | undefined
}

export function useListMyDeals({
  tenderId,
  tenderTermsId,
  filter,
}: {
  tenderId: string
  tenderTermsId?: string
  filter?: 'bid' | 'sleever' | 'tender_owner'
}): ListMyDealsResponse {
  const { data } = useSuspenseQuery(
    listMyDeals,
    {
      tenderId,
    },
    {
      select: (response): ListMyDealsResponse => {
        const myDeals = response.myDeals.filter((deal) => {
          if (filter === 'sleever') {
            return deal.isCreditSleeveDeal
          }
          if (filter === 'tender_owner') {
            return deal.isTenderOwnerDeal
          }
          if (filter === 'bid') {
            return !deal.isCreditSleeveDeal && !deal.isTenderOwnerDeal
          }
          return true
        })

        if (tenderTermsId != null) {
          return {
            deals: myDeals.filter(
              (deal) => deal.tenderTermsId === tenderTermsId,
            ),
            publishedAt: response.publishedAt,
          }
        }

        return {
          deals: myDeals,
          publishedAt: response.publishedAt,
        }
      },
    },
  )

  return {
    ...data,
  }
}
