import { useCallback } from 'react'

import { ToastId, useToast as useChakraToast } from '@chakra-ui/react'

import { Toast, ToastProps } from '@enechain/ecloud-designsystem'

export type ShowToastOptions = {
  toastId?: ToastId
  status?: ToastProps['status']
  action?: ToastProps['action']
  actionLabel?: ToastProps['actionLabel']
  toastMessage?: ToastProps['toastMessage']
}

export type ReturnType = {
  showToast: (options?: ShowToastOptions) => void
}

type ToastWithoutId = {
  toastId?: undefined
  toasts?: undefined
  add?: undefined
  remove?: undefined
}

type ToastWithId = {
  /**
   * toastId
   * プロダクト側のグローバルステートで toast 一覧を管理する想定
   */
  toastId: ToastId
  /**
   * toastId の array
   * プロダクト側のグローバルステートで toast 一覧を管理する想定
   */
  toasts: ToastId[]
  /**
   * toastId を追加する関数
   * プロダクト側のグローバルステートで toast 一覧を管理する想定
   */
  add: (toastId: ToastId) => void
  /**
   * toastId を削除する関数
   * プロダクト側のグローバルステートで toast 一覧を管理する想定
   */
  remove: (toastId: ToastId) => void
}

export type UseToastOptions = ToastProps & (ToastWithoutId | ToastWithId)

export const useToast = ({
  toastId,
  status = 'error',
  toastMessage = '',
  action,
  actionLabel,
  toasts,
  add,
  remove,
}: UseToastOptions): ReturnType => {
  const toast = useChakraToast({
    id: toastId,
    status,
    position: 'top',
    duration: 5000,
    variant: 'solid',
    onCloseComplete: () => {
      remove?.(toastId ?? '')
    },
  })

  const showToast = useCallback(
    (options: ShowToastOptions = {}) => {
      if (toastId == null) {
        toast({
          render: () => (
            <Toast
              action={action}
              status={status}
              toastMessage={toastMessage}
            />
          ),
        })
        return
      }

      if (!toasts.includes(toastId)) {
        const id = toast({
          render: () => (
            <Toast
              action={action}
              status={status}
              toastMessage={toastMessage}
            />
          ),
        })
        const toastIdString = id?.toString() ?? ''
        add(toastIdString)
      }

      const {
        toastId: _toastId = toastId,
        status: _status = status,
        action: _action = action,
        actionLabel: _actionLabel = actionLabel,
        toastMessage: _toastMessage = toastMessage,
      } = options

      toast.update(_toastId, {
        status,
        render: () => (
          <Toast
            action={_action}
            actionLabel={_actionLabel}
            status={_status}
            toastMessage={_toastMessage}
          />
        ),
      })
    },
    [toastId, toasts, status, action, actionLabel, toastMessage, toast, add],
  )

  return {
    showToast,
  }
}
