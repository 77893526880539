import * as React from 'react'

import { Auth0Provider } from '@auth0/auth0-react'
import { Transport } from '@connectrpc/connect'
import { QueryClient } from '@tanstack/react-query'
import { createRoot } from 'react-dom/client'
import { Helmet } from 'react-helmet-async'

import {
  ApiClientProvider,
  AppRouterProvider,
  LibProvider,
} from '@enechain/common-ui'
import { i18n } from '@enechain/etender-i18n'

import '~/libs/datadog'
import { router } from '~/routes'

i18n({
  nameSpace: 'trader',
  cookieDomain: import.meta.env.VITE_COOKIE_DOMAIN,
  debug: import.meta.env.DEV,
})

const container = document.getElementById('root')

if (container != null) {
  createRoot(container).render(
    <React.StrictMode>
      <LibProvider>
        {import.meta.env.MODE === 'development' && (
          <Helmet>
            <link href="/favicon_local.svg" rel="icon" type="image/svg+xml" />
          </Helmet>
        )}
        <Auth0Provider
          authorizationParams={{
            redirect_uri: window.location.origin,
            audience: import.meta.env.VITE_AUTH0_AUDIENCE,
          }}
          clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
          domain={import.meta.env.VITE_AUTH0_DOMAIN}
        >
          <ApiClientProvider baseUrl={import.meta.env.VITE_API_URL}>
            {(
              transport: Transport,
              queryClient: QueryClient,
            ): React.ReactNode => (
              <AppRouterProvider
                createRouter={router}
                queryClient={queryClient}
                transport={transport}
              />
            )}
          </ApiClientProvider>
        </Auth0Provider>
      </LibProvider>
    </React.StrictMode>,
  )
}
