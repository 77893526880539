import * as React from 'react'

import { useTranslation } from 'react-i18next'

import { SemanticColors, Tag } from '@enechain/ecloud-designsystem'

type Props = {
  published?: boolean
}
const RecapStatus: React.FC<Props> = ({ published }: Props) => {
  const { t } = useTranslation('operator', {
    keyPrefix: 'tender_detail.deal',
  })

  return (
    <Tag
      color={
        published === true
          ? SemanticColors.Text.postiveHigh
          : SemanticColors.Text.infoHigh
      }
      backgroundColor={
        published === true
          ? SemanticColors.Surface.positiveMid
          : SemanticColors.Surface.infoMid
      }
    >
      {published === true
        ? t('recap_status.published')
        : t('recap_status.not_published')}
    </Tag>
  )
}

export default React.memo(RecapStatus)
