import * as React from 'react'

import { useSuspenseQuery } from '@connectrpc/connect-query'

import { Accordion, Flex } from '@chakra-ui/react'

import { AccordionAllButton } from '@enechain/common-ui'
import {
  SemanticColors,
  Spacing,
  useAccordion,
} from '@enechain/ecloud-designsystem'
import { listOrdered } from '@enechain/etender-proto/proto/bff/v1/tender_service-TenderService_connectquery'

import TenderTable from '~/pages/history/components/OrderedTenderSection/TenderTable'

type OrderedTenderSectionData = {
  tenderIds: string[]
}

const useOrderedTenderSectionData = (
  pageSize: number,
  pageToken?: string,
): OrderedTenderSectionData => {
  const { data } = useSuspenseQuery(
    listOrdered,
    {
      pageSize,
      pageToken,
    },
    {
      select: (response): OrderedTenderSectionData => {
        return {
          tenderIds: response.tenderIds,
        }
      },
    },
  )
  return data
}

type Props = {
  pageSize: number
  pageToken?: string
}

const OrderedTenderSection: React.FC<Props> = ({ pageSize, pageToken }) => {
  const { tenderIds } = useOrderedTenderSectionData(pageSize, pageToken)

  const { onCollapseAll, onExpandAll, index, onChange, isAllCollapsed } =
    useAccordion(tenderIds, [0])

  return (
    <Flex flexDirection="column" gap={Spacing['4']} width="100%">
      <Flex alignItems="center" justifyContent="flex-end" flexDirection="row">
        <AccordionAllButton
          isAllCollapsed={isAllCollapsed}
          onClickCollapseAll={onCollapseAll}
          onClickExpandAll={onExpandAll}
        />
      </Flex>
      <Accordion
        borderColor={SemanticColors.Border.midEmphasis}
        allowMultiple
        index={index}
        onChange={onChange}
      >
        <TenderTable pageSize={pageSize} pageToken={pageToken} />
      </Accordion>
    </Flex>
  )
}

export default React.memo(OrderedTenderSection)
