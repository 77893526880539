import * as React from 'react'

import Datetime from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import { Flex } from '@chakra-ui/react'

import { SemanticColors } from '@enechain/ecloud-designsystem'

import { DateFormat } from '../../constants/date'

export type DatetimePickerProps = {
  selected: Date | undefined
  placeholder?: string
  selectPastDate?: boolean
  onChange: (date: Date | undefined) => void
}

const DatetimePicker: React.FC<DatetimePickerProps> = ({
  selected,
  placeholder,
  selectPastDate,
  onChange,
}) => {
  const onDatetimeChange = (date: Date | null): void => {
    onChange(date ?? undefined)
  }

  return (
    <>
      <Flex alignItems="center" width="100%" height="40px">
        <Datetime
          dateFormat={DateFormat.DATE_TIME_MINUTE_SLASH}
          minDate={selectPastDate === true ? undefined : new Date()}
          onChange={onDatetimeChange}
          placeholderText={placeholder}
          selected={selected}
          showTimeSelect
          timeFormat={DateFormat.HOUR_MINUTE}
        />
      </Flex>
      <style>{`
        .react-datepicker-wrapper {
          width: 100%;
          height: 100%;
          border: 1px solid ${SemanticColors.Border.midEmphasis};
          border-radius: 6px;
        }
        .react-datepicker__input-container {
          width: 100%;
          height: 100%;
        }
        .react-datepicker__input-container input {
          width: 100%;
          height: 100%;
          border-radius: 6px;
          padding-left: 12px;
          padding-right: 12px;
        }
        .react-datepicker__input
      `}</style>
    </>
  )
}

export default React.memo(DatetimePicker)
