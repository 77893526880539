export type BidConfirmColumnKey =
  | 'accordionSpace'
  | 'bidId'
  | 'unitPrice'
  | 'volume'

export const BidConfirmColumnStyle = {
  accordionSpace: {
    alignment: 'left',
    width: {
      type: 'fixed',
      width: '36px',
    },
  },
  bidId: {
    alignment: 'left',
    width: {
      type: 'ratio',
      ratio: 1,
    },
  },
  unitPrice: {
    alignment: 'left',
    width: {
      type: 'ratio',
      ratio: 1,
    },
  },
  volume: {
    alignment: 'left',
    width: {
      type: 'ratio',
      ratio: 1,
    },
  },
} as const
