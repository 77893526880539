import * as React from 'react'

import { Box } from '@chakra-ui/react'

import { SemanticColors } from '@enechain/ecloud-designsystem'

import DealHistoryTableBodyRow from '~/pages/history-detail/components/OrderHistorySection/HistoryTable/DealHistoryTableBodyRow'

type Props = {
  tenderId: string
  tenderTermsId: string
  displayType: 'bid' | 'sleever' | 'tender_owner'
  displayBidIDs?: string[]
}

const DealHistoryBodyBox: React.FC<Props> = ({
  tenderId,
  tenderTermsId,
  displayType,
  displayBidIDs,
}) => {
  return (
    <Box
      borderColor={SemanticColors.Border.lowEmphasis}
      borderBottomWidth="1px"
      _last={{ borderBottomWidth: 0 }}
    >
      <DealHistoryTableBodyRow
        displayBidIDs={displayBidIDs}
        displayType={displayType}
        tenderId={tenderId}
        tenderTermsId={tenderTermsId}
      />
    </Box>
  )
}

export default React.memo(DealHistoryBodyBox)
