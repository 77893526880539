import * as React from 'react'

import { Stack, Text, ThemeTypings } from '@chakra-ui/react'
import { TypographyProps } from '@chakra-ui/system'

import { SemanticColors, Typography } from '@enechain/ecloud-designsystem'

type Props = {
  required?: boolean
  tooltip?: JSX.Element
  textColor?: ThemeTypings['colors']
  typography?: TypographyProps
  children: string
}
const Label: React.FC<Props> = ({
  children,
  required,
  textColor = SemanticColors.Text.highEmphasis,
  typography = Typography.headerSm,
  ...props
}: Props) => {
  return (
    <Stack alignItems="center" direction="row" spacing={1}>
      <Text textColor={textColor} {...typography}>
        {children}
      </Text>
      {required != null && required && (
        <Text textColor={SemanticColors.Text.negativeMid} {...typography}>
          *
        </Text>
      )}
      {props.tooltip}
    </Stack>
  )
}

export default React.memo(Label)
