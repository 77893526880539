import { PartialMessage } from '@bufbuild/protobuf'
import { createConnectQueryKey, useMutation } from '@connectrpc/connect-query'
import { UseMutationResult, useQueryClient } from '@tanstack/react-query'

import { listByTenderID } from '@enechain/etender-proto/proto/bff/v1/bid_service-BidService_connectquery'
import { listOrdered } from '@enechain/etender-proto/proto/bff/v1/tender_service-TenderService_connectquery'
import { delete$ } from '@enechain/etender-proto/proto/etender/order/v1/service-Service_connectquery'
import {
  DeleteRequest,
  DeleteResponse,
} from '@enechain/etender-proto/proto/etender/order/v1/service_pb'

type Result = UseMutationResult<
  DeleteResponse,
  Error,
  PartialMessage<DeleteRequest>
>

export function useDeleteOrder(tenderId: string): Result {
  const queryClient = useQueryClient()
  return useMutation(delete$, {
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: createConnectQueryKey(listOrdered),
          type: 'all',
        }),
        queryClient.invalidateQueries({
          queryKey: createConnectQueryKey(listByTenderID, {
            tenderId,
          }),
          type: 'all',
        }),
      ])
    },
  })
}
