import * as React from 'react'

import { useSuspenseQuery } from '@connectrpc/connect-query'
import { useTranslation } from 'react-i18next'

import { Flex } from '@chakra-ui/react'

import {
  DateFormat,
  formatTimestamp,
  isNotEmptyOrNull,
} from '@enechain/common-ui'
import PositionLabel from '@enechain/common-ui/src/components/PositionLabel'
import TextWithTooltip from '@enechain/common-ui/src/components/Table/TextWithTooltip'
import {
  FlexTableCell,
  FlexTableRow,
  Typography,
} from '@enechain/ecloud-designsystem'
import { listByTenderID } from '@enechain/etender-proto/proto/bff/v1/bid_service-BidService_connectquery'
import {
  DeliveryUnit,
  Position,
} from '@enechain/etender-proto/proto/etender/tender_terms/v1/model_pb'

import { TenderTermsColumnKey } from '~/pages/history-detail/components/OrderHistorySection/TenderTermsTable/TenderTermsColumnStyle'

type TenderTermsTableData = {
  tenderTermsId: string
  position: 'bid' | 'offer' | undefined
  area: string
  excludePeriods: string[]
  deliveryTerms: string
  hourType: string
  productType: string
  fuelSurchargeType?: string
  priceRestriction?: string
  maxVolume?: string
  minVolume?: string
  note?: string
  bidCount: number
}

const useTenderTermsTableData = (
  tenderId: string,
  tenderTermsId: string,
  orderId?: string,
): TenderTermsTableData | undefined => {
  const { data } = useSuspenseQuery(
    listByTenderID,
    {
      tenderId,
    },
    {
      select: (response): TenderTermsTableData | undefined => {
        const terms = response.dataSetForOrder?.tenderTerms[tenderTermsId]
        if (terms == null) {
          return undefined
        }

        const getPosition = (): 'bid' | 'offer' | undefined => {
          switch (terms.position) {
            case Position.BID:
              return 'bid'
            case Position.ASK:
              return 'offer'
            default:
              return undefined
          }
        }

        const getDateFormat = (): DateFormat => {
          switch (terms?.deliveryUnit) {
            case DeliveryUnit.MONTH:
            case DeliveryUnit.YEAR:
              return DateFormat.YEAR_MONTH_SLASH
            case DeliveryUnit.WEEK_OR_DAY:
            default:
              return DateFormat.DATE_SLASH
          }
        }

        const deliveryTermsStartAt =
          terms?.startAt != null
            ? formatTimestamp(terms.startAt, getDateFormat())
            : ''
        const deliveryTermsEndAt =
          terms?.endAt != null
            ? formatTimestamp(terms.endAt, getDateFormat())
            : ''
        const deliveryTerms =
          deliveryTermsStartAt === '' || deliveryTermsEndAt === ''
            ? ''
            : `${deliveryTermsStartAt}~${deliveryTermsEndAt}`

        const excludePeriods = (terms?.excludePeriods ?? []).map((e) => {
          const startAt =
            e.startAt != null
              ? formatTimestamp(e.startAt, DateFormat.DATE_SLASH)
              : ''
          const endAt =
            e.endAt != null
              ? formatTimestamp(e.endAt, DateFormat.DATE_SLASH)
              : ''
          return startAt === '' || endAt === '' ? '' : `${startAt}~${endAt}`
        })
        const productType = terms?.productType?.name ?? ''
        const fuelSurchargeType = terms?.fuelSurchargeType?.name

        let dealCount = 0

        if (orderId == null) {
          for (const bidId in response.dataSetForOrder?.deals) {
            const deal = response.dataSetForOrder?.deals[bidId].deals.filter(
              (deal) => deal.isCreditSleeveDeal,
            )

            if (deal?.length > 0) {
              dealCount += deal.length
            }
          }
        }

        const bidCount =
          orderId != null
            ? response.histories[orderId].bids[tenderTermsId]?.bids.length ?? 0
            : dealCount

        return {
          tenderTermsId: terms?.id ?? '',
          position: getPosition(),
          area: terms?.area?.name ?? '',
          excludePeriods,
          deliveryTerms,
          hourType: terms?.hourType?.name ?? '',
          productType,
          fuelSurchargeType,
          priceRestriction: isNotEmptyOrNull(terms?.minUnitPrice)
            ? terms.minUnitPrice
            : undefined,
          maxVolume: isNotEmptyOrNull(terms?.maxVolume)
            ? terms.maxVolume
            : undefined,
          minVolume: isNotEmptyOrNull(terms?.minVolume)
            ? terms.minVolume
            : undefined,
          note: isNotEmptyOrNull(terms?.note) ? terms.note : undefined,
          bidCount,
        }
      },
    },
  )
  return data
}

type Props = {
  tenderId: string
  tenderTermsId: string
  orderId?: string
}

const TenderTermsTableBodyRow: React.FC<Props> = ({
  tenderId,
  tenderTermsId,
  orderId,
}) => {
  const { t: commonT } = useTranslation('common')
  const { t } = useTranslation('trader', {
    keyPrefix: 'history_detail.order_history_section.tender_terms_table',
  })

  const data = useTenderTermsTableData(tenderId, tenderTermsId, orderId)

  if (data == null) {
    return null
  }

  return (
    <FlexTableRow>
      <FlexTableCell<TenderTermsColumnKey> columnKey="position">
        {data.position != null && <PositionLabel position={data.position} />}
      </FlexTableCell>
      <FlexTableCell<TenderTermsColumnKey>
        columnKey="area"
        contents={{ text: data.area, textMaxLines: 4 }}
      />
      <FlexTableCell<TenderTermsColumnKey>
        columnKey="deliveryTerms"
        contents={{ text: data.deliveryTerms, textMaxLines: 4 }}
      />
      <FlexTableCell<TenderTermsColumnKey> columnKey="excludePeriods">
        <Flex flexDirection="column" width="100%">
          {data.excludePeriods.length === 0 ? (
            <TextWithTooltip
              text={commonT('label.no_setting')}
              typography={Typography.textMd}
            />
          ) : (
            data.excludePeriods.map((excludePeriod, index) => {
              return (
                <TextWithTooltip
                  key={`${excludePeriod}-${index}`}
                  text={excludePeriod}
                  typography={Typography.textMd}
                />
              )
            })
          )}
        </Flex>
      </FlexTableCell>
      <FlexTableCell<TenderTermsColumnKey>
        columnKey="hourType"
        contents={{ text: data.hourType, textMaxLines: 4 }}
      />
      <FlexTableCell<TenderTermsColumnKey>
        columnKey="productType"
        contents={{
          text: data.productType,
          textMaxLines: 2,
          subText: data.fuelSurchargeType,
          subTextMaxLines: 2,
        }}
      />
      <FlexTableCell<TenderTermsColumnKey>
        columnKey="priceRestriction"
        contents={{
          text: data.priceRestriction ?? commonT('label.private'),
          textMaxLines: 4,
        }}
      />
      <FlexTableCell<TenderTermsColumnKey>
        columnKey="maxVolume"
        contents={{
          text: data.maxVolume ?? commonT('label.private'),
          textMaxLines: 4,
        }}
      />
      <FlexTableCell<TenderTermsColumnKey>
        columnKey="minVolume"
        contents={{
          text: data.minVolume ?? commonT('label.no_setting'),
          textMaxLines: 4,
        }}
      />
      <FlexTableCell<TenderTermsColumnKey>
        columnKey="note"
        contents={{
          text: data.note ?? commonT('label.no_setting'),
          textMaxLines: 4,
        }}
      />
      <FlexTableCell<TenderTermsColumnKey>
        columnKey="bidCount"
        contents={{
          text: t('bid_count.value', { count: data.bidCount }),
          textMaxLines: 4,
        }}
      />
    </FlexTableRow>
  )
}
export default React.memo(TenderTermsTableBodyRow)
