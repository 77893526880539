import * as React from 'react'
import type { SVGProps } from 'react'
import { memo } from 'react'

const SvgUploadFileIcon: React.FC<SVGProps<SVGSVGElement>> = (
  props: SVGProps<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 32 32"
    {...props}
  >
    <path
      fill="currentColor"
      d="M25.01 7.563c.625.625.99 1.458.99 2.343V26a3.323 3.323 0 0 1-3.333 3.333H9.333C7.458 29.333 6 27.875 6 26V6a3.323 3.323 0 0 1 3.333-3.333h9.427c.886 0 1.72.364 2.344.99l3.906 3.905ZM23.5 26V11h-4.167c-.937 0-1.666-.73-1.666-1.667V5.22H9.333a.823.823 0 0 0-.833.833V26c0 .469.365.833.833.833h13.334c.416 0 .833-.364.833-.833Zm-6.25-11.667v2.97h2.917c.677 0 1.25.572 1.25 1.197 0 .677-.573 1.25-1.25 1.25H17.25v2.969c0 .677-.573 1.198-1.25 1.198-.73 0-1.25-.521-1.25-1.302v-2.917h-2.917c-.729 0-1.25-.52-1.25-1.198 0-.677.521-1.25 1.25-1.25h2.917v-2.917c0-.677.52-1.25 1.25-1.25.677 0 1.25.573 1.25 1.25Z"
    />
  </svg>
)
const Memo = memo(SvgUploadFileIcon)
export default Memo
