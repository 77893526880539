import { PartialMessage } from '@bufbuild/protobuf'
import { ConnectError } from '@connectrpc/connect'
import { createConnectQueryKey, useMutation } from '@connectrpc/connect-query'
import { UseMutationResult, useQueryClient } from '@tanstack/react-query'

import { getEditingOrder } from '@enechain/etender-proto/proto/bff/v1/order_service-OrderService_connectquery'
import { discard } from '@enechain/etender-proto/proto/etender/order/v1/service-Service_connectquery'
import {
  DiscardRequest,
  DiscardResponse,
} from '@enechain/etender-proto/proto/etender/order/v1/service_pb'

type Result = UseMutationResult<
  DiscardResponse,
  ConnectError,
  PartialMessage<DiscardRequest>
>

export function useDiscardEditingOrder(): Result {
  const queryClient = useQueryClient()
  return useMutation(discard, {
    onSuccess: async (_, variables) => {
      await queryClient.invalidateQueries({
        queryKey: createConnectQueryKey(getEditingOrder, {
          orderId: variables.orderId,
        }),
      })
    },
  })
}
