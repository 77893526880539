import * as React from 'react'

import { useTranslation } from 'react-i18next'

import { Button } from '@chakra-ui/react'

import { SemanticColors } from '@enechain/ecloud-designsystem'
import { CollapseIcon, ExpandIcon } from '@enechain/etender-icons'

type Props = {
  onClickExpandAll: () => void
  onClickCollapseAll: () => void
  isAllCollapsed: boolean
}

const AccordionAllButton: React.FC<Props> = ({
  onClickExpandAll,
  onClickCollapseAll,
  isAllCollapsed,
}) => {
  const { t } = useTranslation('common')

  return isAllCollapsed ? (
    <Button
      aria-label="expand-all"
      backgroundColor="transparent"
      leftIcon={
        <ExpandIcon
          width="24px"
          height="24px"
          color={SemanticColors.Object.midEmphasis}
        />
      }
      onClick={onClickExpandAll}
    >
      {t('label.expand_all')}
    </Button>
  ) : (
    <Button
      aria-label="collapse-all"
      backgroundColor="transparent"
      leftIcon={
        <CollapseIcon
          width="24px"
          height="24px"
          color={SemanticColors.Object.midEmphasis}
        />
      }
      onClick={onClickCollapseAll}
    >
      {t('label.collapse_all')}
    </Button>
  )
}

export default React.memo(AccordionAllButton)
