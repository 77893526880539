// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file bff/v1/user_model.proto (package bff.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * *
 * ユーザの種別
 *
 * @generated from enum bff.v1.UserType
 */
export const UserType = /*@__PURE__*/ proto3.makeEnum(
  "bff.v1.UserType",
  [
    {no: 0, name: "USER_TYPE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "USER_TYPE_TRADER", localName: "TRADER"},
    {no: 2, name: "USER_TYPE_BROKER", localName: "BROKER"},
    {no: 3, name: "USER_TYPE_SYSTEM_ADMIN", localName: "SYSTEM_ADMIN"},
  ],
);

/**
 * *
 * 組織の種別
 *
 * @generated from enum bff.v1.OrganizationType
 */
export const OrganizationType = /*@__PURE__*/ proto3.makeEnum(
  "bff.v1.OrganizationType",
  [
    {no: 0, name: "ORGANIZATION_TYPE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "ORGANIZATION_TYPE_TRADER", localName: "TRADER"},
    {no: 2, name: "ORGANIZATION_TYPE_BROKER", localName: "BROKER"},
    {no: 3, name: "ORGANIZATION_TYPE_SYSTEM_ADMIN", localName: "SYSTEM_ADMIN"},
  ],
);

/**
 * *
 * ユーザ
 *
 * @generated from message bff.v1.User
 */
export const User = /*@__PURE__*/ proto3.makeMessageType(
  "bff.v1.User",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "type", kind: "enum", T: proto3.getEnumType(UserType) },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "organization_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "picture_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "mobile_phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ],
);

/**
 * *
 * プロフィール
 *
 * @generated from message bff.v1.Profile
 */
export const Profile = /*@__PURE__*/ proto3.makeMessageType(
  "bff.v1.Profile",
  () => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "picture_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "organization_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "organization_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "is_color_reversed", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "locale", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * *
 * 組織
 *
 * @generated from message bff.v1.Organization
 */
export const Organization = /*@__PURE__*/ proto3.makeMessageType(
  "bff.v1.Organization",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "type", kind: "enum", T: proto3.getEnumType(OrganizationType) },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

