import { PartialMessage } from '@bufbuild/protobuf'
import { ConnectError } from '@connectrpc/connect'
import { createConnectQueryKey, useMutation } from '@connectrpc/connect-query'
import { UseMutationResult, useQueryClient } from '@tanstack/react-query'

import { listByTenderID } from '@enechain/etender-proto/proto/bff/v1/bid_service-BidService_connectquery'
import {
  getEditingOrder,
  listOrders,
} from '@enechain/etender-proto/proto/bff/v1/order_service-OrderService_connectquery'
import { apply } from '@enechain/etender-proto/proto/etender/order/v1/service-Service_connectquery'
import {
  ApplyRequest,
  ApplyResponse,
} from '@enechain/etender-proto/proto/etender/order/v1/service_pb'

type Result = UseMutationResult<
  ApplyResponse,
  ConnectError,
  PartialMessage<ApplyRequest>
>

export function useApplyEditingOrder(tenderId: string): Result {
  const queryClient = useQueryClient()
  return useMutation(apply, {
    onSuccess: async (_, variables) => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: createConnectQueryKey(listOrders),
          type: 'all',
        }),
        queryClient.invalidateQueries({
          queryKey: createConnectQueryKey(listByTenderID, {
            tenderId,
          }),
          type: 'all',
        }),
        queryClient.invalidateQueries({
          queryKey: createConnectQueryKey(getEditingOrder, {
            orderId: variables.orderId,
          }),
          type: 'all',
        }),
      ])
    },
  })
}
