import { useMemo } from 'react'

import { matchPath, useLocation } from 'react-router-dom'

function isActiveRoute(path: string, pathname: string): boolean {
  return matchPath(path, pathname) != null
}

export function useActiveRoute(paths: string | string[] | undefined): boolean {
  const { pathname } = useLocation()

  return useMemo(() => {
    if (paths == null) {
      return false
    }

    if (typeof paths === 'string') {
      return isActiveRoute(paths, pathname)
    }

    return paths.some((path) => {
      return isActiveRoute(path, pathname)
    })
  }, [pathname, paths])
}
