import * as React from 'react'

import { useSuspenseQuery } from '@connectrpc/connect-query'

import {
  FlexTable,
  FlexTableBody,
  FlexTableHeader,
} from '@enechain/ecloud-designsystem'
import { listOrdered } from '@enechain/etender-proto/proto/bff/v1/tender_service-TenderService_connectquery'

import {
  TenderTermsColumnKey,
  tenderTermsColumnStyles,
} from '~/pages/history/components/OrderedTenderSection/TenderTermsTable/TenderTermsColumnStyles'
import TenderTermsTableHeader from '~/pages/history/components/OrderedTenderSection/TenderTermsTable/TenderTermsTableHeader'
import TenderTermsTableRow from '~/pages/history/components/OrderedTenderSection/TenderTermsTable/TenderTermsTableRow'

type TenderTermsTableData = {
  tenderTermsIds: string[]
}

const useTenderTermsTableData = (
  tenderId: string,
  pageSize: number,
  pageToken?: string,
): TenderTermsTableData => {
  const { data } = useSuspenseQuery(
    listOrdered,
    { pageSize, pageToken },
    {
      select: (response): TenderTermsTableData => {
        return {
          tenderTermsIds:
            response?.tenderTermsIdMap[tenderId].tenderTermsIds ?? [],
        }
      },
    },
  )
  return data
}

type Props = {
  tenderId: string
  pageSize: number
  pageToken?: string
}

const TenderTermsTable: React.FC<Props> = ({
  tenderId,
  pageSize,
  pageToken,
}) => {
  const { tenderTermsIds } = useTenderTermsTableData(
    tenderId,
    pageSize,
    pageToken,
  )

  if (tenderTermsIds.length <= 0) {
    return null
  }

  return (
    <FlexTable<TenderTermsColumnKey> columnStyles={tenderTermsColumnStyles}>
      <FlexTableHeader style="sub-table">
        <TenderTermsTableHeader
          pageSize={pageSize}
          pageToken={pageToken}
          tenderId={tenderId}
        />
      </FlexTableHeader>
      <FlexTableBody style="single-line">
        {tenderTermsIds.map((id) => (
          <TenderTermsTableRow
            key={id}
            pageSize={pageSize}
            pageToken={pageToken}
            tenderTermsId={id}
          />
        ))}
      </FlexTableBody>
    </FlexTable>
  )
}

export default React.memo(TenderTermsTable)
