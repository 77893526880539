import { Dispatch, SetStateAction, useState } from 'react'

import { useSuspenseQuery } from '@connectrpc/connect-query'
import { UseQueryResult } from '@tanstack/react-query'
import { useLoaderData } from 'react-router-dom'

import { DataSet } from '@enechain/etender-proto/proto/bff/v1/data_set_model_pb'
import { listForTrader } from '@enechain/etender-proto/proto/bff/v1/tender_service-TenderService_connectquery'
import { ListForTraderResponse } from '@enechain/etender-proto/proto/bff/v1/tender_service_pb'
import { Tender } from '@enechain/etender-proto/proto/etender/tender/v1/model_pb'

import { TenderLoaderData } from '~/pages/tender'

type TenderListResponse<T> = {
  data: T
  setPageToken: Dispatch<SetStateAction<string>>
} & Pick<UseQueryResult, 'refetch'>

export function useTenderList<T>(
  select: (response: ListForTraderResponse) => T,
): TenderListResponse<T> {
  const { pageToken, listForTraderResponse } =
    useLoaderData() as TenderLoaderData
  const [_pageToken, setPageToken] = useState(pageToken)
  const { data, refetch } = useSuspenseQuery(
    listForTrader,
    {
      pageToken: _pageToken,
      pageSize: 20,
    },
    {
      initialData: listForTraderResponse,
      select,
    },
  )

  return {
    data,
    refetch,
    setPageToken,
  }
}

export function useTenderIds(): string[] {
  const { data } = useTenderList<string[]>((response) =>
    response.tenders.map((tender) => tender.id),
  )
  return data
}

type UseTenderByIdResponse<T> = {
  data: T | undefined
} & Pick<UseQueryResult, 'refetch'>

type UseTenderByIdSelectProps = {
  tender: Tender
  dataSet: DataSet | undefined
}

export function useTenderById<T>(
  tenderId: string,
  select: (props: UseTenderByIdSelectProps) => T | undefined,
): UseTenderByIdResponse<T> {
  const { data, refetch } = useTenderList((response) => {
    const tender = response.tenders.find((tender) => tender.id === tenderId)
    if (tender == null) {
      return undefined
    }
    return select({ tender, dataSet: response.dataSet })
  })
  return { data, refetch }
}
