import * as React from 'react'

import { Flex } from '@chakra-ui/react'

import { EtenderLogo } from '@enechain/common-ui'
import {
  SemanticColors,
  useSideMenuCollapsed,
} from '@enechain/ecloud-designsystem'
import { ESquareSymbol } from '@enechain/ecloud-icons'

export const Logo: React.FC = () => {
  const { collapsed } = useSideMenuCollapsed()

  if (collapsed) {
    return <ESquareSymbol width={38} height={48} aria-hidden />
  }
  return (
    <Flex alignItems="center">
      <ESquareSymbol width={38} height={48} aria-hidden />
      <EtenderLogo width={90} fill={SemanticColors.Brand.blue} aria-hidden />
    </Flex>
  )
}
