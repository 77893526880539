// @generated by protoc-gen-connect-query v1.3.1 with parameter "target=js+dts"
// @generated from file bff/v1/order_service.proto (package bff.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { MethodKind } from "@bufbuild/protobuf";
import { GetEditingOrderRequest, GetEditingOrderResponse, ListOrdersRequest, ListOrdersResponse } from "./order_service_pb.js";

/**
 * 自分の注文の一覧を取得する
 *
 * @generated from rpc bff.v1.OrderService.ListOrders
 */
export const listOrders = {
  localName: "listOrders",
  name: "ListOrders",
  kind: MethodKind.Unary,
  I: ListOrdersRequest,
  O: ListOrdersResponse,
  service: {
    typeName: "bff.v1.OrderService"
  }
};

/**
 * 指定した編集中の注文を取得する
 *
 * @generated from rpc bff.v1.OrderService.GetEditingOrder
 */
export const getEditingOrder = {
  localName: "getEditingOrder",
  name: "GetEditingOrder",
  kind: MethodKind.Unary,
  I: GetEditingOrderRequest,
  O: GetEditingOrderResponse,
  service: {
    typeName: "bff.v1.OrderService"
  }
};
