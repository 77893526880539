import * as React from 'react'

import { useSuspenseQuery } from '@connectrpc/connect-query'
import { useTranslation } from 'react-i18next'

import { Flex } from '@chakra-ui/react'

import {
  DateFormat,
  formatTimestamp,
  isNotEmptyOrNull,
} from '@enechain/common-ui'
import PositionLabel from '@enechain/common-ui/src/components/PositionLabel'
import TextWithTooltip from '@enechain/common-ui/src/components/Table/TextWithTooltip'
import {
  AccordionIconButton,
  FlexTableCell,
  FlexTableRow,
  Spacing,
  Typography,
} from '@enechain/ecloud-designsystem'
import { getEditingOrder } from '@enechain/etender-proto/proto/bff/v1/order_service-OrderService_connectquery'
import {
  DeliveryUnit,
  Position,
} from '@enechain/etender-proto/proto/etender/tender_terms/v1/model_pb'

import { OrderInformationColumnKey } from '~/components/order/OrderInformationTable/OrderInformationColumnStyle'

type Props = {
  orderId: string
  tenderTermsId: string
}

type OrderInformationItem = {
  tenderTermsId: string
  position: 'bid' | 'offer' | undefined
  area: string
  excludePeriods: string[]
  deliveryTerms: string
  hourType: string
  productType: string
  fuelSurchargeType?: string
  priceRestriction?: string
  maxVolume?: string
  minVolume?: string
  note: string
}

const useTenderTermsItem = (
  orderId: string,
  tenderTermsId: string,
): OrderInformationItem | undefined => {
  const { data } = useSuspenseQuery(
    getEditingOrder,
    {
      orderId,
    },
    {
      select: (response): OrderInformationItem | undefined => {
        const terms = response.dataSetForOrder?.tenderTerms[tenderTermsId]
        if (terms == null) {
          return undefined
        }
        const position =
          terms.position === Position.BID
            ? 'bid'
            : terms.position === Position.ASK
              ? 'offer'
              : undefined

        const getDateFormat = (): DateFormat => {
          switch (terms?.deliveryUnit) {
            case DeliveryUnit.MONTH:
            case DeliveryUnit.YEAR:
              return DateFormat.YEAR_MONTH_SLASH
            case DeliveryUnit.WEEK_OR_DAY:
            default:
              return DateFormat.DATE_SLASH
          }
        }

        const deliveryTermsStartAt =
          terms?.startAt != null
            ? formatTimestamp(terms.startAt, getDateFormat())
            : ''
        const deliveryTermsEndAt =
          terms?.endAt != null
            ? formatTimestamp(terms.endAt, getDateFormat())
            : ''
        const deliveryTerms =
          deliveryTermsStartAt === '' || deliveryTermsEndAt === ''
            ? ''
            : `${deliveryTermsStartAt}~${deliveryTermsEndAt}`

        const excludePeriods = (terms?.excludePeriods ?? []).map((e) => {
          const startAt =
            e.startAt != null
              ? formatTimestamp(e.startAt, DateFormat.DATE_SLASH)
              : ''
          const endAt =
            e.endAt != null
              ? formatTimestamp(e.endAt, DateFormat.DATE_SLASH)
              : ''
          return startAt === '' || endAt === '' ? '' : `${startAt}~${endAt}`
        })
        const productType = terms?.productType?.name ?? ''
        const fuelSurchargeType = terms?.fuelSurchargeType?.name

        return {
          tenderTermsId: terms?.id ?? '',
          position,
          area: terms?.area?.name ?? '',
          excludePeriods,
          deliveryTerms,
          hourType: terms?.hourType?.name ?? '',
          productType,
          fuelSurchargeType,
          priceRestriction: isNotEmptyOrNull(terms?.minUnitPrice)
            ? terms.minUnitPrice
            : undefined,
          maxVolume: isNotEmptyOrNull(terms?.maxVolume)
            ? terms.maxVolume
            : undefined,
          minVolume: isNotEmptyOrNull(terms?.minVolume)
            ? terms.minVolume
            : undefined,
          note: terms?.note ?? '',
        }
      },
    },
  )
  return data
}

const OrderInformationTableBodyRow: React.FC<Props> = ({
  orderId,
  tenderTermsId,
}) => {
  const { t: commonT } = useTranslation('common')

  const item = useTenderTermsItem(orderId, tenderTermsId)

  if (item == null) {
    return null
  }

  return (
    <FlexTableRow>
      <FlexTableCell<OrderInformationColumnKey> columnKey="accordion">
        <Flex marginStart={Spacing['-2']}>
          <AccordionIconButton />
        </Flex>
      </FlexTableCell>
      <FlexTableCell<OrderInformationColumnKey> columnKey="position">
        {item.position != null && <PositionLabel position={item.position} />}
      </FlexTableCell>
      <FlexTableCell<OrderInformationColumnKey>
        columnKey="area"
        contents={{ text: item.area, textMaxLines: 4 }}
      />
      <FlexTableCell<OrderInformationColumnKey>
        columnKey="deliveryTerms"
        contents={{ text: item.deliveryTerms, textMaxLines: 4 }}
      />
      <FlexTableCell<OrderInformationColumnKey> columnKey="excludePeriods">
        <Flex flexDirection="column" width="100%">
          {item.excludePeriods.length === 0 ? (
            <TextWithTooltip
              text={commonT('label.no_setting')}
              typography={Typography.textMd}
            />
          ) : (
            item.excludePeriods.map((excludePeriod, index) => {
              return (
                <TextWithTooltip
                  key={`${excludePeriod}-${index}`}
                  text={excludePeriod}
                  typography={Typography.textMd}
                />
              )
            })
          )}
        </Flex>
      </FlexTableCell>
      <FlexTableCell<OrderInformationColumnKey>
        columnKey="hourType"
        contents={{ text: item.hourType, textMaxLines: 4 }}
      />
      <FlexTableCell<OrderInformationColumnKey>
        columnKey="productType"
        contents={{
          text: item.productType,
          textMaxLines: 2,
          subText: item.fuelSurchargeType,
          subTextMaxLines: 2,
        }}
      />
      <FlexTableCell<OrderInformationColumnKey>
        columnKey="priceRestriction"
        contents={{
          text: item.priceRestriction ?? commonT('label.private'),
          textMaxLines: 4,
        }}
      />
      <FlexTableCell<OrderInformationColumnKey>
        columnKey="maxVolume"
        contents={{
          text: item.maxVolume ?? commonT('label.private'),
          textMaxLines: 4,
        }}
      />
      <FlexTableCell<OrderInformationColumnKey>
        columnKey="minVolume"
        contents={{
          text: item.minVolume ?? commonT('label.no_setting'),
          textMaxLines: 4,
        }}
      />
      <FlexTableCell<OrderInformationColumnKey>
        columnKey="note"
        contents={{ text: item.note, textMaxLines: 4 }}
      />
    </FlexTableRow>
  )
}
export default React.memo(OrderInformationTableBodyRow)
