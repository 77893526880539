import * as React from 'react'
import { useCallback, useState } from 'react'

import { Interceptor, Transport } from '@connectrpc/connect'
import { TransportProvider } from '@connectrpc/connect-query'
import { createConnectTransport } from '@connectrpc/connect-web'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { useTranslation } from 'react-i18next'

import { useAccessToken } from '../hooks'
import { queryConfig } from '../libs/reactQuery'

type ApiClientProviderProps = {
  baseUrl: string
  children: (transport: Transport, queryClient: QueryClient) => React.ReactNode
}

const ApiClientProvider: React.FC<ApiClientProviderProps> = ({
  baseUrl,
  children,
}) => {
  const { getAccessToken } = useAccessToken()
  const authInterceptor = useCallback<Interceptor>(
    (next) => async (req) => {
      const token = await getAccessToken()
      if (token != null && token.length > 0) {
        req.header.set('Authorization', `Bearer ${token}`)
      }
      return next(req)
    },
    [getAccessToken],
  )

  const { i18n } = useTranslation()
  const acceptLanguageInterceptor = useCallback<Interceptor>(
    (next) => async (req) => {
      const language = i18n.resolvedLanguage === 'ja' ? 'ja' : 'en'
      req.header.set('Accept-Language', language)
      return next(req)
    },
    [i18n],
  )

  const transport = createConnectTransport({
    baseUrl,
    interceptors: [authInterceptor, acceptLanguageInterceptor],
  })

  const [queryClient] = useState(
    () => new QueryClient({ defaultOptions: queryConfig }),
  )

  return (
    <TransportProvider transport={transport}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        {children(transport, queryClient)}
      </QueryClientProvider>
    </TransportProvider>
  )
}

export default ApiClientProvider
