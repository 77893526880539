import * as React from 'react'
import type { SVGProps } from 'react'
import { memo } from 'react'

const SvgReportIcon: React.FC<SVGProps<SVGSVGElement>> = (
  props: SVGProps<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M17.406 6.906c.396.396.594.875.594 1.438V18c-.02.563-.219 1.031-.594 1.406S16.563 19.98 16 20H8c-.563-.02-1.031-.219-1.406-.594S6.02 18.563 6 18V6c.02-.563.219-1.031.594-1.406S7.437 4.02 8 4h5.656c.563 0 1.042.198 1.438.594l2.312 2.312ZM16.5 18h-.031V9H14a.974.974 0 0 1-.719-.281A.974.974 0 0 1 13 8V5.5H8c-.313.042-.48.208-.5.5v12c.02.313.188.48.5.5h8c.313-.02.48-.188.5-.5ZM12 12c.458.042.708.292.75.75v4c-.042.458-.292.708-.75.75-.458-.042-.708-.292-.75-.75v-4c.042-.458.292-.708.75-.75Zm2 4.75v-3c.042-.458.292-.708.75-.75.458.042.708.292.75.75v3c-.042.458-.292.708-.75.75-.458-.042-.708-.292-.75-.75ZM9.25 14c.458.042.708.292.75.75v2c-.042.458-.292.708-.75.75-.458-.042-.708-.292-.75-.75v-2c.042-.458.292-.708.75-.75Z"
    />
  </svg>
)
const Memo = memo(SvgReportIcon)
export default Memo
