// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file etender/deal/v1/service.proto (package etender.deal.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { Deal, MyDeal, RecapAnnex, RecapDeal, TenderDeal } from "./model_pb.js";

/**
 * @generated from message etender.deal.v1.CreateEmptyRequest
 */
export const CreateEmptyRequest = /*@__PURE__*/ proto3.makeMessageType(
  "etender.deal.v1.CreateEmptyRequest",
  () => [
    { no: 1, name: "tender_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message etender.deal.v1.CreateEmptyResponse
 */
export const CreateEmptyResponse = /*@__PURE__*/ proto3.makeMessageType(
  "etender.deal.v1.CreateEmptyResponse",
  () => [
    { no: 1, name: "tender_deal", kind: "message", T: TenderDeal },
  ],
);

/**
 * @generated from message etender.deal.v1.UpdateDraftDealsRequest
 */
export const UpdateDraftDealsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "etender.deal.v1.UpdateDraftDealsRequest",
  () => [
    { no: 1, name: "tender_deal_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "deal", kind: "message", T: Deal, repeated: true },
  ],
);

/**
 * @generated from message etender.deal.v1.UpdateDraftDealsResponse
 */
export const UpdateDraftDealsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "etender.deal.v1.UpdateDraftDealsResponse",
  () => [
    { no: 1, name: "tender_deal", kind: "message", T: TenderDeal },
  ],
);

/**
 * @generated from message etender.deal.v1.GetDraftDealRequest
 */
export const GetDraftDealRequest = /*@__PURE__*/ proto3.makeMessageType(
  "etender.deal.v1.GetDraftDealRequest",
  () => [
    { no: 1, name: "tender_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message etender.deal.v1.GetDraftDealResponse
 */
export const GetDraftDealResponse = /*@__PURE__*/ proto3.makeMessageType(
  "etender.deal.v1.GetDraftDealResponse",
  () => [
    { no: 1, name: "tender_deal", kind: "message", T: TenderDeal },
  ],
);

/**
 * @generated from message etender.deal.v1.PublishRequest
 */
export const PublishRequest = /*@__PURE__*/ proto3.makeMessageType(
  "etender.deal.v1.PublishRequest",
  () => [
    { no: 1, name: "tender_deal_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message etender.deal.v1.PublishResponse
 */
export const PublishResponse = /*@__PURE__*/ proto3.makeMessageType(
  "etender.deal.v1.PublishResponse",
  [],
);

/**
 * @generated from message etender.deal.v1.GetDealFromTenderIdRequest
 */
export const GetDealFromTenderIdRequest = /*@__PURE__*/ proto3.makeMessageType(
  "etender.deal.v1.GetDealFromTenderIdRequest",
  () => [
    { no: 1, name: "tender_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message etender.deal.v1.GetDealFromTenderIdResponse
 */
export const GetDealFromTenderIdResponse = /*@__PURE__*/ proto3.makeMessageType(
  "etender.deal.v1.GetDealFromTenderIdResponse",
  () => [
    { no: 1, name: "tender_deal", kind: "message", T: TenderDeal },
  ],
);

/**
 * @generated from message etender.deal.v1.ListMyDealsRequest
 */
export const ListMyDealsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "etender.deal.v1.ListMyDealsRequest",
  () => [
    { no: 1, name: "tender_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message etender.deal.v1.ListMyDealsResponse
 */
export const ListMyDealsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "etender.deal.v1.ListMyDealsResponse",
  () => [
    { no: 1, name: "my_deals", kind: "message", T: MyDeal, repeated: true },
    { no: 2, name: "published_at", kind: "message", T: Timestamp },
  ],
);

/**
 * @generated from message etender.deal.v1.GetRecapRequest
 */
export const GetRecapRequest = /*@__PURE__*/ proto3.makeMessageType(
  "etender.deal.v1.GetRecapRequest",
  () => [
    { no: 1, name: "deal_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * @generated from message etender.deal.v1.GetRecapResponse
 */
export const GetRecapResponse = /*@__PURE__*/ proto3.makeMessageType(
  "etender.deal.v1.GetRecapResponse",
  () => [
    { no: 1, name: "recap_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "recap_annex", kind: "message", T: RecapAnnex },
    { no: 3, name: "version", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "published", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message etender.deal.v1.DraftRecapRequest
 */
export const DraftRecapRequest = /*@__PURE__*/ proto3.makeMessageType(
  "etender.deal.v1.DraftRecapRequest",
  () => [
    { no: 1, name: "deal_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "recap_annex", kind: "message", T: RecapAnnex },
    { no: 3, name: "version", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message etender.deal.v1.DraftRecapResponse
 */
export const DraftRecapResponse = /*@__PURE__*/ proto3.makeMessageType(
  "etender.deal.v1.DraftRecapResponse",
  [],
);

/**
 * @generated from message etender.deal.v1.PublishRecapRequest
 */
export const PublishRecapRequest = /*@__PURE__*/ proto3.makeMessageType(
  "etender.deal.v1.PublishRecapRequest",
  () => [
    { no: 1, name: "deal_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "recap_deal", kind: "message", T: RecapDeal },
    { no: 3, name: "recap_annex", kind: "message", T: RecapAnnex },
    { no: 4, name: "version", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message etender.deal.v1.PublishRecapResponse
 */
export const PublishRecapResponse = /*@__PURE__*/ proto3.makeMessageType(
  "etender.deal.v1.PublishRecapResponse",
  [],
);

