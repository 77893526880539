import * as React from 'react'

import { useTranslation } from 'react-i18next'

import { Flex, Text } from '@chakra-ui/react'

import { Label } from '@enechain/common-ui'
import {
  SemanticColors,
  Spacing,
  Typography,
} from '@enechain/ecloud-designsystem'

import { useEditingOrder } from '~/pages/order/hooks/useEditingOrder'

type TocomFormData = {
  traderName: string
  clearingBank: string
  clearingAccount: string
}

const useTocomFormData = (orderId: string): TocomFormData | undefined => {
  const { data } = useEditingOrder(orderId, (response) => {
    const traderNameField = response.additionalFields?.tocomTraderName
    const clearingBankField = response.additionalFields?.tocomClearingBank
    const clearingAccountField = response.additionalFields?.tocomClearingAccount

    if (
      traderNameField == null ||
      clearingBankField == null ||
      clearingAccountField == null ||
      !(
        traderNameField.isValid &&
        clearingBankField.isValid &&
        clearingAccountField.isValid
      )
    ) {
      return undefined
    }

    return {
      traderName: traderNameField.value,
      clearingBank: clearingBankField.value,
      clearingAccount: clearingAccountField.value,
    }
  })
  return data
}

type Props = {
  orderId: string
}

const TocomForm: React.FC<Props> = ({ orderId }) => {
  const { t } = useTranslation('trader', {
    keyPrefix: 'order_confirm.additional_fields_section.tocom',
  })

  const formData = useTocomFormData(orderId)

  if (formData == null) {
    return null
  }

  return (
    <Flex flexDirection="column" gap={Spacing['6']}>
      <Flex flexDirection="column" gap={Spacing['2']}>
        <Label required>{t('clearing_bank')}</Label>
        <Text color={SemanticColors.Text.highEmphasis} {...Typography.textMd}>
          {formData.clearingBank}
        </Text>
      </Flex>
    </Flex>
  )
}

export default React.memo(TocomForm)
