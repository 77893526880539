import * as React from 'react'

import { useTranslation } from 'react-i18next'

import { Text } from '@chakra-ui/react'

import {
  BorderRadius,
  SemanticColors,
  Typography,
} from '@enechain/ecloud-designsystem'

export type Props = {
  position: 'bid' | 'offer' | undefined
}

const PositionLabel: React.FC<Props> = ({ position }) => {
  const { t } = useTranslation('common')

  const text =
    position === 'bid' ? t('label.bid_short') : t('label.offer_short')

  if (position === undefined) {
    return null
  }

  return (
    <Text
      align="center"
      height="20px"
      color={SemanticColors.Text.highEmphasisInverse}
      borderRadius={BorderRadius.radiiBase}
      backgroundColor={
        position === 'bid'
          ? SemanticColors.Surface.bid
          : SemanticColors.Surface.offer
      }
      paddingX={1}
      {...Typography.headerSm}
    >
      {text}
    </Text>
  )
}

export default React.memo(PositionLabel)
