import * as React from 'react'
import type { SVGProps } from 'react'
import { memo } from 'react'

const SvgCalendarCheckIcon: React.FC<SVGProps<SVGSVGElement>> = (
  props: SVGProps<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 13 15"
    {...props}
  >
    <path
      fill="#000"
      fillOpacity={0.74}
      d="M5.929 11.429c-.286.268-.58.268-.884 0L3.331 9.715c-.25-.285-.25-.58 0-.883.304-.25.598-.25.884 0L5.5 10.09l2.544-2.544c.304-.25.598-.25.884 0 .268.304.268.598 0 .884l-3 2.999ZM3.572.504c.393.035.607.25.643.642v1.071h3.856v-1.07c.036-.393.25-.608.643-.643.392.035.607.25.642.642v1.071h1.071a1.73 1.73 0 0 1 1.205.51c.322.32.491.722.51 1.204V12.5a1.731 1.731 0 0 1-.51 1.205c-.32.321-.723.49-1.205.509H1.86a1.731 1.731 0 0 1-1.205-.509 1.73 1.73 0 0 1-.51-1.205V3.931a1.73 1.73 0 0 1 .51-1.205 1.73 1.73 0 0 1 1.205-.509h1.07v-1.07c.036-.393.25-.608.643-.643Zm7.284 5.141H1.43V12.5c.018.268.16.41.429.428h8.568c.268-.017.411-.16.429-.428V5.645Z"
    />
  </svg>
)
const Memo = memo(SvgCalendarCheckIcon)
export default Memo
