import * as React from 'react'

import { useTranslation } from 'react-i18next'

import { Flex, Text } from '@chakra-ui/react'

import { SemanticColors, Typography } from '@enechain/ecloud-designsystem'
import { DataIcon } from '@enechain/etender-icons'

type Props = {
  description?: string
  subDescription?: string
}

const EmptyLayout: React.FC<Props> = ({ description, subDescription }) => {
  const { t } = useTranslation('common')

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      width="100%"
      height="100%"
    >
      <Flex alignItems="center" flexDirection="column" gap={2}>
        <DataIcon
          width="64px"
          height="64px"
          color={SemanticColors.Object.lowEmphasis}
        />
        <Text
          textColor={SemanticColors.Text.midEmphasis}
          {...Typography.headerMd}
        >
          {description ?? t('label.empty_data')}
        </Text>
        {subDescription != null && (
          <Text
            textColor={SemanticColors.Text.lowEmphasis}
            {...Typography.headerSm}
          >
            {subDescription}
          </Text>
        )}
      </Flex>
    </Flex>
  )
}

export default EmptyLayout
