import * as React from 'react'

import { useSuspenseQuery } from '@connectrpc/connect-query'
import { useTranslation } from 'react-i18next'

import { Accordion, AccordionItem, Flex, Text } from '@chakra-ui/react'

import { AccordionAllButton } from '@enechain/common-ui'
import {
  SemanticColors,
  Spacing,
  Typography,
  useAccordion,
} from '@enechain/ecloud-designsystem'
import { listByTenderID } from '@enechain/etender-proto/proto/bff/v1/bid_service-BidService_connectquery'

import DealInformation from '~/pages/history-detail/components/OrderHistorySection/DealInformation'
import OrderInformation from '~/pages/history-detail/components/OrderHistorySection/OrderInformation'

type OrderInformationSectionData = {
  orderIds: string[]
}

const useOrderHistorySectionData = (
  tenderId: string,
): OrderInformationSectionData => {
  const { data } = useSuspenseQuery(
    listByTenderID,
    {
      tenderId,
    },
    {
      select: (response): OrderInformationSectionData => {
        return {
          orderIds: response.orderIds,
        }
      },
    },
  )
  return data
}

type Props = {
  tenderId: string
}

const OrderHistorySection: React.FC<Props> = ({ tenderId }) => {
  const { t } = useTranslation('trader', {
    keyPrefix: 'history_detail.order_history_section',
  })

  const { orderIds } = useOrderHistorySectionData(tenderId)

  const accordionKeys = [...orderIds, 'deal']

  const { onCollapseAll, onExpandAll, index, onChange, isAllCollapsed } =
    useAccordion(
      accordionKeys,
      accordionKeys.map((_, index) => index),
    )

  return (
    <Flex
      flexDirection="column"
      gap={Spacing['4']}
      width="100%"
      height="100%"
      paddingBottom={Spacing['16']}
    >
      <Flex
        alignItems="center"
        justifyContent="space-between"
        flexDirection="row"
      >
        <Text {...Typography.headerLg}>{t('title')}</Text>
        <AccordionAllButton
          isAllCollapsed={isAllCollapsed}
          onClickCollapseAll={onCollapseAll}
          onClickExpandAll={onExpandAll}
        />
      </Flex>
      <Accordion
        borderColor={SemanticColors.Border.midEmphasis}
        allowMultiple
        index={index}
        onChange={onChange}
      >
        <Flex flexDirection="column" gap={Spacing['10']}>
          <DealInformation tenderId={tenderId} />
          {orderIds.map((orderId) => (
            <AccordionItem
              key={orderId}
              flexDirection="column"
              gap={Spacing['4']}
              display="flex"
              borderWidth={0}
              _last={{ borderBottomWidth: 0 }}
            >
              <OrderInformation orderId={orderId} tenderId={tenderId} />
            </AccordionItem>
          ))}
        </Flex>
      </Accordion>
    </Flex>
  )
}

export default React.memo(OrderHistorySection)
