import { Code, ConnectError, Transport } from '@connectrpc/connect'
import { createQueryOptions } from '@connectrpc/connect-query'
import { QueryClient } from '@tanstack/react-query'
import { LoaderFunction, defer } from 'react-router-dom'

import { getEditingOrder } from '@enechain/etender-proto/proto/bff/v1/order_service-OrderService_connectquery'
import { verifyBiddable } from '@enechain/etender-proto/proto/etender/tender/v1/service-Service_connectquery'

export type OrderLoaderData = {
  tenderId: string
  orderId: string
  isEditing: boolean
}

export type OrderLoaderResponse = {
  data: OrderLoaderData
}

export function orderLoader(
  transport: Transport,
  queryClient: QueryClient,
): LoaderFunction {
  return ({ params }) => {
    async function load(): Promise<OrderLoaderData> {
      const tenderId = params.tenderId
      if (tenderId == null) {
        throw new Response('tenderId is required', { status: 400 })
      }

      const orderId = params.orderId
      if (orderId == null) {
        throw new Response('orderId is required', { status: 400 })
      }

      try {
        const [_, editingOrderResponse] = await Promise.all([
          queryClient.ensureQueryData(
            createQueryOptions(
              verifyBiddable,
              {
                tenderId: tenderId,
              },
              {
                transport,
              },
            ),
          ),
          queryClient.ensureQueryData(
            createQueryOptions(getEditingOrder, { orderId }, { transport }),
          ),
        ])

        return {
          tenderId,
          orderId,
          isEditing: editingOrderResponse.editingOrder?.isEditing ?? false,
        }
      } catch (error) {
        if (
          error instanceof ConnectError &&
          error.code === Code.FailedPrecondition
        ) {
          throw new Response('tender is not biddable', { status: 404 })
        }

        throw error
      }
    }

    return defer({
      data: load(),
    })
  }
}
