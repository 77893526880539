const ignoreMessages = [
  'Login required',
  'Multifactor authentication required',
  'Unauthorized user',
]

export function isIgnoreAuth0Message(message: string | undefined): boolean {
  return ignoreMessages.some((ignoreMessage) => {
    const targetMessage = typeof message === 'string' ? message : ''
    return targetMessage.includes(ignoreMessage)
  })
}
