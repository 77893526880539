// @generated by protoc-gen-connect-query v1.3.1 with parameter "target=js+dts"
// @generated from file bff/v1/tender_service.proto (package bff.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { MethodKind } from "@bufbuild/protobuf";
import { GetForOperatorRequest, GetForOperatorResponse, GetForTraderRequest, GetForTraderResponse, ListForOperatorRequest, ListForOperatorResponse, ListForTraderRequest, ListForTraderResponse, ListOrderedRequest, ListOrderedResponse, UpdateOperatorsRequest, UpdateOperatorsResponse } from "./tender_service_pb.js";

/**
 * トレーダー画面の入札案件を取得する
 *
 * @generated from rpc bff.v1.TenderService.GetForTrader
 */
export const getForTrader = {
  localName: "getForTrader",
  name: "GetForTrader",
  kind: MethodKind.Unary,
  I: GetForTraderRequest,
  O: GetForTraderResponse,
  service: {
    typeName: "bff.v1.TenderService"
  }
};

/**
 * オペレーター画面の入札案件を取得する
 *
 * @generated from rpc bff.v1.TenderService.GetForOperator
 */
export const getForOperator = {
  localName: "getForOperator",
  name: "GetForOperator",
  kind: MethodKind.Unary,
  I: GetForOperatorRequest,
  O: GetForOperatorResponse,
  service: {
    typeName: "bff.v1.TenderService"
  }
};

/**
 * 入札案件のオペレーターを更新する
 *
 * @generated from rpc bff.v1.TenderService.UpdateOperators
 */
export const updateOperators = {
  localName: "updateOperators",
  name: "UpdateOperators",
  kind: MethodKind.Unary,
  I: UpdateOperatorsRequest,
  O: UpdateOperatorsResponse,
  service: {
    typeName: "bff.v1.TenderService"
  }
};

/**
 * トレーダー画面の入札案件一覧を取得する
 *
 * @generated from rpc bff.v1.TenderService.ListForTrader
 */
export const listForTrader = {
  localName: "listForTrader",
  name: "ListForTrader",
  kind: MethodKind.Unary,
  I: ListForTraderRequest,
  O: ListForTraderResponse,
  service: {
    typeName: "bff.v1.TenderService"
  }
};

/**
 * オペレーター画面の入札案件一覧を取得する
 *
 * @generated from rpc bff.v1.TenderService.ListForOperator
 */
export const listForOperator = {
  localName: "listForOperator",
  name: "ListForOperator",
  kind: MethodKind.Unary,
  I: ListForOperatorRequest,
  O: ListForOperatorResponse,
  service: {
    typeName: "bff.v1.TenderService"
  }
};

/**
 * 注文済みの入札案件一覧を取得する
 *
 * @generated from rpc bff.v1.TenderService.ListOrdered
 */
export const listOrdered = {
  localName: "listOrdered",
  name: "ListOrdered",
  kind: MethodKind.Unary,
  I: ListOrderedRequest,
  O: ListOrderedResponse,
  service: {
    typeName: "bff.v1.TenderService"
  }
};
