import * as React from 'react'
import type { SVGProps } from 'react'
import { memo } from 'react'

const SvgTradingIcon: React.FC<SVGProps<SVGSVGElement>> = (
  props: SVGProps<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M19.504 15.385H9.294V13.23c0-1.136-1.51-1.656-2.313-.817l-2.647 2.768a1.167 1.167 0 0 0 0 1.636l2.647 2.768c.802.839 2.313.32 2.313-.817v-2.154h10.21c.274 0 .496-.206.496-.461v-.308c0-.255-.222-.461-.496-.461ZM7.97 18.769 5.324 16l2.647-2.769v5.538ZM4.496 8.615h10.21v2.154c0 1.136 1.51 1.656 2.313.817l2.647-2.768a1.167 1.167 0 0 0 0-1.636l-2.647-2.768c-.802-.839-2.313-.32-2.313.817v2.154H4.496c-.274 0-.496.206-.496.461v.308c0 .255.222.461.496.461ZM16.03 5.231 18.677 8l-2.648 2.769V5.23Z"
    />
  </svg>
)
const Memo = memo(SvgTradingIcon)
export default Memo
