import * as React from 'react'

import {
  FlexTable,
  FlexTableBody,
  FlexTableHeader,
} from '@enechain/ecloud-designsystem'

import {
  TenderTermsColumnKey,
  tenderTermsColumnStyles,
} from '~/pages/tender/components/TenderTermsTable/TenderTermsColumnStyles'
import TenderTermsTableHeader from '~/pages/tender/components/TenderTermsTable/TenderTermsTableHeader'
import TenderTermsTableRow from '~/pages/tender/components/TenderTermsTable/TenderTermsTableRow'
import { useTenderTermsListByTenderId } from '~/pages/tender/hooks/useTenderTermsList'

type Props = {
  tenderId: string
}

const TenderTermsTable: React.FC<Props> = ({ tenderId }) => {
  const tenderTermsIds = useTenderTermsListByTenderId(tenderId, (terms) => {
    return terms.id
  })

  if (tenderTermsIds.length < 1) {
    return null
  }

  return (
    <FlexTable<TenderTermsColumnKey> columnStyles={tenderTermsColumnStyles}>
      <FlexTableHeader style="sub-table">
        <TenderTermsTableHeader tenderId={tenderId} />
      </FlexTableHeader>
      <FlexTableBody style="single-line">
        {tenderTermsIds.map((id) => (
          <TenderTermsTableRow key={id} tenderTermsId={id} />
        ))}
      </FlexTableBody>
    </FlexTable>
  )
}

export default React.memo(TenderTermsTable)
