// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file etender/tender/v1/service.proto (package etender.tender.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { AccessControl, Commodity, EditableTender, Operators, OwnerInfo, Properties, Schedule, Sleevers, Tender, TenderStatus } from "./model_pb.js";

/**
 * 参加可能な入札案件の一覧を取得するリクエスト
 *
 * @generated from message etender.tender.v1.ListRequest
 */
export const ListRequest = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender.v1.ListRequest",
  () => [
    { no: 1, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * 参加可能な入札案件の一覧を取得するレスポンス
 *
 * @generated from message etender.tender.v1.ListResponse
 */
export const ListResponse = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender.v1.ListResponse",
  () => [
    { no: 1, name: "tender", kind: "message", T: Tender, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "total_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * 複数の入札案件を取得するリクエスト
 *
 * @generated from message etender.tender.v1.BatchListRequest
 */
export const BatchListRequest = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender.v1.BatchListRequest",
  () => [
    { no: 1, name: "ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * 複数の入札案件を取得するレスポンス
 *
 * @generated from message etender.tender.v1.BatchListResponse
 */
export const BatchListResponse = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender.v1.BatchListResponse",
  () => [
    { no: 1, name: "tenders", kind: "message", T: Tender, repeated: true },
  ],
);

/**
 * 注文済みの入札案件の一覧を取得するリクエスト
 *
 * @generated from message etender.tender.v1.ListOrderedRequest
 */
export const ListOrderedRequest = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender.v1.ListOrderedRequest",
  () => [
    { no: 1, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ],
);

/**
 * 注文済みの入札案件の一覧を取得するレスポンス
 *
 * @generated from message etender.tender.v1.ListOrderedResponse
 */
export const ListOrderedResponse = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender.v1.ListOrderedResponse",
  () => [
    { no: 1, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "total_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "tenders", kind: "message", T: Tender, repeated: true },
    { no: 4, name: "last_applied_at_map", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: Timestamp} },
  ],
);

/**
 * 編集可能な入札案件の一覧を取得するリクエスト
 *
 * @generated from message etender.tender.v1.ListEditableRequest
 */
export const ListEditableRequest = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender.v1.ListEditableRequest",
  () => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(TenderStatus) },
    { no: 2, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * 編集可能な入札案件の一覧を取得するレスポンス
 *
 * @generated from message etender.tender.v1.ListEditableResponse
 */
export const ListEditableResponse = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender.v1.ListEditableResponse",
  () => [
    { no: 1, name: "tender", kind: "message", T: EditableTender, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "total_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * 入札案件を取得するリクエスト
 *
 * @generated from message etender.tender.v1.GetRequest
 */
export const GetRequest = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender.v1.GetRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * 入札案件を取得するレスポンス
 *
 * @generated from message etender.tender.v1.GetResponse
 */
export const GetResponse = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender.v1.GetResponse",
  () => [
    { no: 1, name: "tender", kind: "message", T: Tender },
  ],
);

/**
 * 編集可能な入札案件を取得するリクエスト
 *
 * @generated from message etender.tender.v1.GetEditableRequest
 */
export const GetEditableRequest = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender.v1.GetEditableRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * 編集可能な入札案件を取得するレスポンス
 *
 * @generated from message etender.tender.v1.GetEditableResponse
 */
export const GetEditableResponse = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender.v1.GetEditableResponse",
  () => [
    { no: 1, name: "tender", kind: "message", T: EditableTender },
  ],
);

/**
 * 参加可能な入札案件の添付ファイルの署名付きURLを取得するリクエスト
 *
 * @generated from message etender.tender.v1.GetAttachmentURLRequest
 */
export const GetAttachmentURLRequest = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender.v1.GetAttachmentURLRequest",
  () => [
    { no: 1, name: "tender_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "attachment_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * 参加可能な入札案件の添付ファイルの署名付きURLを取得するレスポンス
 *
 * @generated from message etender.tender.v1.GetAttachmentURLResponse
 */
export const GetAttachmentURLResponse = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender.v1.GetAttachmentURLResponse",
  () => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * 編集可能な入札案件の添付ファイルの署名付きURLを取得するリクエスト
 *
 * @generated from message etender.tender.v1.GetEditableAttachmentURLRequest
 */
export const GetEditableAttachmentURLRequest = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender.v1.GetEditableAttachmentURLRequest",
  () => [
    { no: 1, name: "tender_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "attachment_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * 編集可能な入札案件の添付ファイルの署名付きURLを取得するレスポンス
 *
 * @generated from message etender.tender.v1.GetEditableAttachmentURLResponse
 */
export const GetEditableAttachmentURLResponse = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender.v1.GetEditableAttachmentURLResponse",
  () => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * 応札可能かどうかを確認するリクエスト
 *
 * @generated from message etender.tender.v1.VerifyBiddableRequest
 */
export const VerifyBiddableRequest = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender.v1.VerifyBiddableRequest",
  () => [
    { no: 1, name: "tender_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * 応札可能かどうかを確認するレスポンス
 *
 * @generated from message etender.tender.v1.VerifyBiddableResponse
 */
export const VerifyBiddableResponse = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender.v1.VerifyBiddableResponse",
  [],
);

/**
 * 空の入札案件を作成するリクエスト
 *
 * @generated from message etender.tender.v1.CreateEmptyRequest
 */
export const CreateEmptyRequest = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender.v1.CreateEmptyRequest",
  () => [
    { no: 1, name: "commodity", kind: "enum", T: proto3.getEnumType(Commodity) },
  ],
);

/**
 * 空の入札案件を作成するレスポンス
 *
 * @generated from message etender.tender.v1.CreateEmptyResponse
 */
export const CreateEmptyResponse = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender.v1.CreateEmptyResponse",
  () => [
    { no: 1, name: "tender", kind: "message", T: EditableTender },
  ],
);

/**
 * 添付ファイルをアップロードするリクエスト
 *
 * @generated from message etender.tender.v1.UploadAttachmentRequest
 */
export const UploadAttachmentRequest = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender.v1.UploadAttachmentRequest",
  () => [
    { no: 1, name: "tender_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "chunk", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ],
);

/**
 * 添付ファイルをアップロードするレスポンス
 *
 * @generated from message etender.tender.v1.UploadAttachmentResponse
 */
export const UploadAttachmentResponse = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender.v1.UploadAttachmentResponse",
  [],
);

/**
 * 入札案件のプロパティを更新するリクエスト
 *
 * @generated from message etender.tender.v1.UpdatePropertiesRequest
 */
export const UpdatePropertiesRequest = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender.v1.UpdatePropertiesRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "properties", kind: "message", T: Properties },
  ],
);

/**
 * 入札案件のプロパティを更新するレスポンス
 *
 * @generated from message etender.tender.v1.UpdatePropertiesResponse
 */
export const UpdatePropertiesResponse = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender.v1.UpdatePropertiesResponse",
  () => [
    { no: 1, name: "properties", kind: "message", T: Properties },
  ],
);

/**
 * 入札案件の開催主の情報を更新するリクエスト
 *
 * @generated from message etender.tender.v1.UpdateOwnerInfoRequest
 */
export const UpdateOwnerInfoRequest = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender.v1.UpdateOwnerInfoRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "owner_info", kind: "message", T: OwnerInfo },
  ],
);

/**
 * 入札案件の開催主の情報を更新するレスポンス
 *
 * @generated from message etender.tender.v1.UpdateOwnerInfoResponse
 */
export const UpdateOwnerInfoResponse = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender.v1.UpdateOwnerInfoResponse",
  () => [
    { no: 1, name: "owner_info", kind: "message", T: OwnerInfo },
  ],
);

/**
 * 入札案件のスケジュールを更新するリクエスト
 *
 * @generated from message etender.tender.v1.UpdateScheduleRequest
 */
export const UpdateScheduleRequest = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender.v1.UpdateScheduleRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "schedule", kind: "message", T: Schedule },
  ],
);

/**
 * 入札案件のスケジュールを更新するレスポンス
 *
 * @generated from message etender.tender.v1.UpdateScheduleResponse
 */
export const UpdateScheduleResponse = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender.v1.UpdateScheduleResponse",
  () => [
    { no: 1, name: "schedule", kind: "message", T: Schedule },
  ],
);

/**
 * 入札案件のアクセス制御を更新するリクエスト
 *
 * @generated from message etender.tender.v1.UpdateAccessControlRequest
 */
export const UpdateAccessControlRequest = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender.v1.UpdateAccessControlRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "access_control", kind: "message", T: AccessControl },
  ],
);

/**
 * 入札案件のアクセス制御を更新するレスポンス
 *
 * @generated from message etender.tender.v1.UpdateAccessControlResponse
 */
export const UpdateAccessControlResponse = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender.v1.UpdateAccessControlResponse",
  () => [
    { no: 1, name: "access_control", kind: "message", T: AccessControl },
  ],
);

/**
 * 入札案件のオペレーターを更新するリクエスト
 *
 * @generated from message etender.tender.v1.UpdateOperatorsRequest
 */
export const UpdateOperatorsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender.v1.UpdateOperatorsRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "operators", kind: "message", T: Operators },
  ],
);

/**
 * 入札案件のオペレーターを更新するレスポンス
 *
 * @generated from message etender.tender.v1.UpdateOperatorsResponse
 */
export const UpdateOperatorsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender.v1.UpdateOperatorsResponse",
  () => [
    { no: 1, name: "operators", kind: "message", T: Operators },
  ],
);

/**
 * @generated from message etender.tender.v1.UpdateEnabledRequest
 */
export const UpdateEnabledRequest = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender.v1.UpdateEnabledRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * @generated from message etender.tender.v1.UpdateEnabledResponse
 */
export const UpdateEnabledResponse = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender.v1.UpdateEnabledResponse",
  () => [
    { no: 1, name: "enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * 入札案件のスリーバーを更新するリクエスト
 *
 * @generated from message etender.tender.v1.UpdateSleeversRequest
 */
export const UpdateSleeversRequest = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender.v1.UpdateSleeversRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "sleevers", kind: "message", T: Sleevers },
  ],
);

/**
 * 入札案件のスリーバーを更新するレスポンス
 *
 * @generated from message etender.tender.v1.UpdateSleeversResponse
 */
export const UpdateSleeversResponse = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender.v1.UpdateSleeversResponse",
  () => [
    { no: 1, name: "sleevers", kind: "message", T: Sleevers },
  ],
);

/**
 * 入札案件を削除するリクエスト
 *
 * @generated from message etender.tender.v1.DeleteRequest
 */
export const DeleteRequest = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender.v1.DeleteRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * 入札案件を削除するレスポンス
 *
 * @generated from message etender.tender.v1.DeleteResponse
 */
export const DeleteResponse = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender.v1.DeleteResponse",
  [],
);

/**
 * 添付ファイルを削除するリクエスト
 *
 * @generated from message etender.tender.v1.DeleteAttachmentRequest
 */
export const DeleteAttachmentRequest = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender.v1.DeleteAttachmentRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * 添付ファイルを削除するレスポンス
 *
 * @generated from message etender.tender.v1.DeleteAttachmentResponse
 */
export const DeleteAttachmentResponse = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender.v1.DeleteAttachmentResponse",
  [],
);

