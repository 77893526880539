// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file etender/tender_terms/v1/model.proto (package etender.tender_terms.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * *
 * 入札条件のバリデーションエラーが発生したフィールド
 *
 * @generated from enum etender.tender_terms.v1.ValidationErrorField
 */
export const ValidationErrorField = /*@__PURE__*/ proto3.makeEnum(
  "etender.tender_terms.v1.ValidationErrorField",
  [
    {no: 0, name: "VALIDATION_ERROR_FIELD_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "VALIDATION_ERROR_FIELD_POSITION", localName: "POSITION"},
    {no: 2, name: "VALIDATION_ERROR_FIELD_DELIVERY_UNIT", localName: "DELIVERY_UNIT"},
    {no: 3, name: "VALIDATION_ERROR_FIELD_PRODUCT_TYPE", localName: "PRODUCT_TYPE"},
    {no: 4, name: "VALIDATION_ERROR_FIELD_FUEL_SURCHARGE_TYPE", localName: "FUEL_SURCHARGE_TYPE"},
    {no: 5, name: "VALIDATION_ERROR_FIELD_AREA", localName: "AREA"},
    {no: 6, name: "VALIDATION_ERROR_FIELD_HOUR_TYPE", localName: "HOUR_TYPE"},
    {no: 7, name: "VALIDATION_ERROR_FIELD_START_AT", localName: "START_AT"},
    {no: 8, name: "VALIDATION_ERROR_FIELD_END_AT", localName: "END_AT"},
    {no: 9, name: "VALIDATION_ERROR_FIELD_EXCLUDE_PERIODS", localName: "EXCLUDE_PERIODS"},
    {no: 10, name: "VALIDATION_ERROR_FIELD_MAX_VOLUME", localName: "MAX_VOLUME"},
    {no: 11, name: "VALIDATION_ERROR_FIELD_MIN_VOLUME", localName: "MIN_VOLUME"},
    {no: 12, name: "VALIDATION_ERROR_FIELD_MIN_UNIT_PRICE", localName: "MIN_UNIT_PRICE"},
  ],
);

/**
 * *
 * 入札条件のバリデーションエラーの種類
 *
 * @generated from enum etender.tender_terms.v1.ValidationErrorType
 */
export const ValidationErrorType = /*@__PURE__*/ proto3.makeEnum(
  "etender.tender_terms.v1.ValidationErrorType",
  [
    {no: 0, name: "VALIDATION_ERROR_TYPE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "VALIDATION_ERROR_TYPE_REQUIRED", localName: "REQUIRED"},
    {no: 2, name: "VALIDATION_ERROR_TYPE_UNSELECTABLE", localName: "UNSELECTABLE"},
    {no: 3, name: "VALIDATION_ERROR_TYPE_INVALID_ORDER", localName: "INVALID_ORDER"},
    {no: 4, name: "VALIDATION_ERROR_TYPE_INVALID_FORMAT", localName: "INVALID_FORMAT"},
    {no: 5, name: "VALIDATION_ERROR_TYPE_OUT_OF_RANGE", localName: "OUT_OF_RANGE"},
  ],
);

/**
 * *
 * 売買区分
 *
 * @generated from enum etender.tender_terms.v1.Position
 */
export const Position = /*@__PURE__*/ proto3.makeEnum(
  "etender.tender_terms.v1.Position",
  [
    {no: 0, name: "POSITION_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "POSITION_ASK", localName: "ASK"},
    {no: 2, name: "POSITION_BID", localName: "BID"},
  ],
);

/**
 * *
 * 最低契約期間
 *
 * @generated from enum etender.tender_terms.v1.DeliveryUnit
 */
export const DeliveryUnit = /*@__PURE__*/ proto3.makeEnum(
  "etender.tender_terms.v1.DeliveryUnit",
  [
    {no: 0, name: "DELIVERY_UNIT_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "DELIVERY_UNIT_MONTH", localName: "MONTH"},
    {no: 2, name: "DELIVERY_UNIT_WEEK_OR_DAY", localName: "WEEK_OR_DAY"},
    {no: 3, name: "DELIVERY_UNIT_YEAR", localName: "YEAR"},
  ],
);

/**
 * *
 * 商品区分ID
 *
 * @generated from enum etender.tender_terms.v1.ProductTypeId
 */
export const ProductTypeId = /*@__PURE__*/ proto3.makeEnum(
  "etender.tender_terms.v1.ProductTypeId",
  [
    {no: 0, name: "PRODUCT_TYPE_ID_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "PRODUCT_TYPE_ID_BG_NO_FCA", localName: "BG_NO_FCA"},
    {no: 2, name: "PRODUCT_TYPE_ID_BG_WITH_FCA", localName: "BG_WITH_FCA"},
    {no: 3, name: "PRODUCT_TYPE_ID_BG_ECLEAR", localName: "BG_ECLEAR"},
    {no: 4, name: "PRODUCT_TYPE_ID_CFD_NO_FCA", localName: "CFD_NO_FCA"},
    {no: 5, name: "PRODUCT_TYPE_ID_CFD_WITH_FCA", localName: "CFD_WITH_FCA"},
    {no: 6, name: "PRODUCT_TYPE_ID_SWAP_BILATERAL", localName: "SWAP_BILATERAL"},
    {no: 7, name: "PRODUCT_TYPE_ID_SWAP_TOCOM", localName: "SWAP_TOCOM"},
    {no: 8, name: "PRODUCT_TYPE_ID_SWAP_EEX", localName: "SWAP_EEX"},
  ],
);

/**
 * *
 * 入札条件
 *
 * @generated from message etender.tender_terms.v1.TenderTerms
 */
export const TenderTerms = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender_terms.v1.TenderTerms",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "tender_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "position", kind: "enum", T: proto3.getEnumType(Position) },
    { no: 4, name: "delivery_unit", kind: "enum", T: proto3.getEnumType(DeliveryUnit) },
    { no: 5, name: "product_type", kind: "message", T: ProductType },
    { no: 6, name: "fuel_surcharge_type", kind: "message", T: FuelSurchargeType },
    { no: 7, name: "area", kind: "message", T: Area },
    { no: 8, name: "hour_type", kind: "message", T: HourType },
    { no: 9, name: "start_at", kind: "message", T: Timestamp },
    { no: 10, name: "end_at", kind: "message", T: Timestamp },
    { no: 11, name: "has_exclude_period", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 12, name: "exclude_periods", kind: "message", T: ExcludePeriod, repeated: true },
    { no: 13, name: "max_volume", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 14, name: "min_volume", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "min_unit_price", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 16, name: "note", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "summary", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * *
 * 編集可能な入札条件
 *
 * @generated from message etender.tender_terms.v1.EditableTenderTerms
 */
export const EditableTenderTerms = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender_terms.v1.EditableTenderTerms",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "tender_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "position", kind: "enum", T: proto3.getEnumType(Position) },
    { no: 4, name: "delivery_unit", kind: "enum", T: proto3.getEnumType(DeliveryUnit) },
    { no: 5, name: "product_type", kind: "message", T: ProductType },
    { no: 6, name: "fuel_surcharge_type", kind: "message", T: FuelSurchargeType },
    { no: 7, name: "area", kind: "message", T: Area },
    { no: 8, name: "hour_type", kind: "message", T: HourType },
    { no: 9, name: "start_at", kind: "message", T: Timestamp },
    { no: 10, name: "end_at", kind: "message", T: Timestamp },
    { no: 11, name: "has_exclude_period", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 12, name: "exclude_periods", kind: "message", T: ExcludePeriod, repeated: true },
    { no: 13, name: "max_volume", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "is_public_max_volume", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 15, name: "min_volume", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "min_unit_price", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "is_public_min_unit_price", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 18, name: "note", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 19, name: "validation_result", kind: "message", T: ValidationResult },
  ],
);

/**
 * *
 * 入札条件のバリデーション結果
 *
 * @generated from message etender.tender_terms.v1.ValidationResult
 */
export const ValidationResult = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender_terms.v1.ValidationResult",
  () => [
    { no: 1, name: "is_valid", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "errors", kind: "message", T: ValidationError, repeated: true },
  ],
);

/**
 * *
 * 入札条件のバリデーションエラー
 *
 * @generated from message etender.tender_terms.v1.ValidationError
 */
export const ValidationError = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender_terms.v1.ValidationError",
  () => [
    { no: 1, name: "field", kind: "enum", T: proto3.getEnumType(ValidationErrorField) },
    { no: 2, name: "type", kind: "enum", T: proto3.getEnumType(ValidationErrorType) },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ],
);

/**
 * *
 * 入札条件の設定可能な入力項目
 *
 * @generated from message etender.tender_terms.v1.SelectableInputs
 */
export const SelectableInputs = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender_terms.v1.SelectableInputs",
  () => [
    { no: 1, name: "positions", kind: "enum", T: proto3.getEnumType(Position), repeated: true },
    { no: 2, name: "delivery_units", kind: "enum", T: proto3.getEnumType(DeliveryUnit), repeated: true },
    { no: 3, name: "product_types", kind: "message", T: ProductType, repeated: true },
    { no: 4, name: "fuel_surcharge_types", kind: "message", T: FuelSurchargeType, repeated: true },
    { no: 5, name: "areas", kind: "message", T: Area, repeated: true },
    { no: 6, name: "hour_types", kind: "message", T: HourType, repeated: true },
  ],
);

/**
 * *
 * 商品区分
 *
 * @generated from message etender.tender_terms.v1.ProductType
 */
export const ProductType = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender_terms.v1.ProductType",
  () => [
    { no: 1, name: "id", kind: "enum", T: proto3.getEnumType(ProductTypeId) },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "has_fuel_surcharge", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * *
 * 燃調区分
 *
 * @generated from message etender.tender_terms.v1.FuelSurchargeType
 */
export const FuelSurchargeType = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender_terms.v1.FuelSurchargeType",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * *
 * エリア
 *
 * @generated from message etender.tender_terms.v1.Area
 */
export const Area = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender_terms.v1.Area",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * *
 * 時間帯区分
 *
 * @generated from message etender.tender_terms.v1.HourType
 */
export const HourType = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender_terms.v1.HourType",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * *
 * 除外期間
 *
 * @generated from message etender.tender_terms.v1.ExcludePeriod
 */
export const ExcludePeriod = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender_terms.v1.ExcludePeriod",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "start_at", kind: "message", T: Timestamp },
    { no: 3, name: "end_at", kind: "message", T: Timestamp },
    { no: 4, name: "is_valid", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

