import * as React from 'react'

import { useTranslation } from 'react-i18next'

import { FormControl, IconButton } from '@chakra-ui/react'

import { DebouncedInput, getShortenUUID, useToast } from '@enechain/common-ui'
import {
  BorderRadius,
  FlexTableCell,
  FlexTableRow,
  SemanticColors,
  Term,
} from '@enechain/ecloud-designsystem'
import { CloseIcon } from '@enechain/etender-icons'
import {
  ValidationErrorField,
  ValidationErrorType,
} from '@enechain/etender-proto/proto/etender/bid/v1/model_pb'

import { BidColumnKey } from '~/pages/order/components/OrderSection/BidTable/BidColumnStyle'
import { useDeleteBid } from '~/pages/order/hooks/useDeleteBid'
import { useEditBid } from '~/pages/order/hooks/useEditBid'
import { useEditingOrder } from '~/pages/order/hooks/useEditingOrder'

type BidTableBodyData = {
  bidId: {
    shortenId: string
    id: string
  }
  volume: {
    isValid: boolean
    value: string
    errorType: ValidationErrorType | undefined
  }
  unitPrice: {
    isValid: boolean
    value: string
    errorType: ValidationErrorType | undefined
  }
}

const useBidTableBodyData = (
  orderId: string,
  bidId: string,
): BidTableBodyData | undefined => {
  const { data } = useEditingOrder(orderId, (response) => {
    const bid = response.editingOrder?.bids?.find((bid) => bid.id === bidId)
    if (bid == null) {
      return undefined
    }

    const bidIdData = {
      shortenId: getShortenUUID(bid.id),
      id: bid.id ?? '',
    }
    const volume = bid.volume ?? ''
    const unitPrice = bid.unitPrice ?? ''

    if (bid.validationResult?.isValid === true) {
      return {
        bidId: bidIdData,
        volume: {
          isValid: true,
          value: volume,
          errorType: undefined,
        },
        unitPrice: {
          isValid: true,
          value: unitPrice,
          errorType: undefined,
        },
      }
    }

    const errorMap = bid.validationResult?.errors.reduce(
      (acc, error) => {
        acc[error.field] = error.type
        return acc
      },
      {} as Record<ValidationErrorField, ValidationErrorType>,
    )

    const volumeError =
      errorMap != null ? errorMap[ValidationErrorField.VOLUME] : undefined
    const unitPriceError =
      errorMap != null ? errorMap[ValidationErrorField.UNIT_PRICE] : undefined

    return {
      bidId: bidIdData,
      volume: {
        isValid: false,
        value: volume,
        errorType: volumeError,
      },
      unitPrice: {
        isValid: false,
        value: unitPrice,
        errorType: unitPriceError,
      },
    }
  })

  if (data == null) {
    return undefined
  }

  return {
    ...data,
  }
}

type Props = {
  tenderTermsId: string
  orderId: string
  bidId: string
}

const BidTableBodyRow: React.FC<Props> = ({
  orderId,
  bidId,
  tenderTermsId,
}) => {
  const { t } = useTranslation('trader', {
    keyPrefix: 'order.bid_input',
  })

  const data = useBidTableBodyData(orderId, bidId)
  const editMutation = useEditBid()
  const deleteMutation = useDeleteBid()

  const { showToast } = useToast({
    toastId: 'pages/order/components/BidTable/BidTableBodyRow',
  })

  const editBid = (volume: string, unitPrice: string): void => {
    editMutation.mutate(
      {
        orderId,
        tenderTermsId,
        bidId,
        volume,
        unitPrice,
      },
      {
        onError: () => {
          showToast({
            status: 'error',
            toastMessage: t('failed_to_edit'),
          })
        },
      },
    )
  }

  const onChangeUnitPrice = (unitPrice: string): void => {
    if (data == null) {
      return
    }
    editBid(data.volume.value, unitPrice)
  }

  const onChangeVolume = (volume: string): void => {
    if (data == null) {
      return
    }
    editBid(volume, data.unitPrice.value)
  }

  const onClickDelete = (): void => {
    deleteMutation.mutate({
      bidId,
      orderId,
    })
  }

  const getValidationErrorMessage = (
    errorType: ValidationErrorType,
  ): string => {
    switch (errorType) {
      case ValidationErrorType.INVALID_FORMAT:
        return t('validation_error_type.invalid_format')
      case ValidationErrorType.OUT_OF_RANGE:
        return t('validation_error_type.out_of_range')
      case ValidationErrorType.REQUIRED:
        return t('validation_error_type.required')
      case ValidationErrorType.DUPLICATED:
        return t('validation_error_type.duplicated')
      default:
        return t('validation_error_type.error')
    }
  }

  const unitPriceErrorMessage = (): string | undefined => {
    if (data?.unitPrice.errorType == null) {
      return undefined
    }

    return getValidationErrorMessage(data.unitPrice.errorType)
  }

  const volumeErrorMessage = (): string | undefined => {
    if (data?.volume.errorType == null) {
      return undefined
    }

    return getValidationErrorMessage(data.volume.errorType)
  }

  if (data == null) {
    return null
  }

  return (
    <FlexTableRow>
      <FlexTableCell<BidColumnKey> columnKey="accordionSpace" />
      <FlexTableCell<BidColumnKey>
        columnKey="bidId"
        contents={{ text: data.bidId.shortenId, fullText: data.bidId.id }}
      />
      <FlexTableCell<BidColumnKey> columnKey="unitPrice">
        <FormControl isInvalid={!data.unitPrice.isValid}>
          <Term>
            <DebouncedInput
              width="100%"
              error={unitPriceErrorMessage()}
              initialValue={data.unitPrice.value}
              milliseconds={500}
              onChange={onChangeUnitPrice}
              placeholder={t('unit_price.placeholder')}
              step="0.01"
              type="number"
            />
          </Term>
        </FormControl>
      </FlexTableCell>
      <FlexTableCell<BidColumnKey> columnKey="volume">
        <FormControl isInvalid={!data.volume.isValid}>
          <Term>
            <DebouncedInput
              width="100%"
              error={volumeErrorMessage()}
              initialValue={data.volume.value}
              milliseconds={500}
              onChange={onChangeVolume}
              placeholder={t('volume.placeholder')}
              step="1"
              type="number"
            />
          </Term>
        </FormControl>
      </FlexTableCell>
      <FlexTableCell<BidColumnKey> columnKey="deleteBid">
        <IconButton
          borderRadius={BorderRadius.radiiFull}
          aria-label="delete-bid"
          backgroundColor={SemanticColors.Surface.neutralMid}
          icon={<CloseIcon width="20px" height="20px" />}
          isRound
          onClick={onClickDelete}
          size="xs"
        />
      </FlexTableCell>
    </FlexTableRow>
  )
}

export default React.memo(BidTableBodyRow)
