import * as React from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { useTranslation } from 'react-i18next'

import { Flex, Text } from '@chakra-ui/react'

import { SecondaryButton } from '@enechain/ecloud-designsystem'

const PermissionDeniedErrorPage: React.FC = () => {
  const { t } = useTranslation()
  const { logout } = useAuth0()

  const onClickSignOut = async (): Promise<void> => {
    await logout({
      logoutParams: { returnTo: window.location.origin },
    })
  }

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      gap={4}
      paddingX={4}
      paddingY={16}
    >
      <Text>{t('common:label.please_permission_denied')}</Text>
      <SecondaryButton onClick={onClickSignOut}>
        {t('common:label.sign_out')}
      </SecondaryButton>
    </Flex>
  )
}

export default PermissionDeniedErrorPage
