import * as React from 'react'
import type { SVGProps } from 'react'
import { memo } from 'react'

const SvgHistoryIcon: React.FC<SVGProps<SVGSVGElement>> = (
  props: SVGProps<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M19.75 12c-.031-4.281-3.5-7.75-7.75-7.75-2.156 0-4.125.906-5.531 2.313L5.062 5.155c-.218-.218-.625-.062-.625.282v3.937c0 .219.157.375.375.375h4c.344 0 .5-.406.282-.625l-1.531-1.5A6.156 6.156 0 0 1 12 5.75c3.438 0 6.25 2.813 6.25 6.25A6.248 6.248 0 0 1 12 18.25a6.352 6.352 0 0 1-4.188-1.563.38.38 0 0 0-.5 0l-.53.532a.396.396 0 0 0 0 .562C8.155 19 10 19.781 12 19.75c4.25 0 7.75-3.469 7.75-7.75Zm-5.25 2.813.469-.594c.094-.188.062-.406-.094-.531l-2.125-1.563v-4.5a.403.403 0 0 0-.375-.375h-.75a.38.38 0 0 0-.375.375v5.281l2.75 2c.156.125.406.094.5-.094Z"
    />
  </svg>
)
const Memo = memo(SvgHistoryIcon)
export default Memo
