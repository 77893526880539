import * as React from 'react'

import { PartialMessage } from '@bufbuild/protobuf'
import { useTranslation } from 'react-i18next'

import { Flex, FormControl } from '@chakra-ui/react'

import { DebouncedInput, useToast } from '@enechain/common-ui'
import { Label, Spacing, Term } from '@enechain/ecloud-designsystem'
import { OrderAdditionalField } from '@enechain/etender-proto/proto/bff/v1/order_model_pb'
import { Contents } from '@enechain/etender-proto/proto/etender/order/v1/model_pb'

import { useEditOrderContents } from '~/pages/order/hooks/useEditOrderContents'
import { useEditingOrder } from '~/pages/order/hooks/useEditingOrder'
import { useOrderValidationErrorMessage } from '~/pages/order/hooks/useOrderValidationErrorMessage'

type TocomFormData = {
  contents: Contents
  traderName: OrderAdditionalField
  clearingBank: OrderAdditionalField
  clearingAccount: OrderAdditionalField
}

const useTocomFormData = (orderId: string): TocomFormData | undefined => {
  const { data } = useEditingOrder(orderId, (response) => {
    const contents = response.editingOrder?.contents
    const traderNameField = response.additionalFields?.tocomTraderName
    const clearingBankField = response.additionalFields?.tocomClearingBank
    const clearingAccountField = response.additionalFields?.tocomClearingAccount

    if (
      contents == null ||
      traderNameField == null ||
      clearingBankField == null ||
      clearingAccountField == null
    ) {
      return undefined
    }

    return {
      contents,
      traderName: traderNameField,
      clearingBank: clearingBankField,
      clearingAccount: clearingAccountField,
    }
  })
  return data
}

type Props = {
  orderId: string
}

const TocomForm: React.FC<Props> = ({ orderId }) => {
  const { t } = useTranslation('trader', {
    keyPrefix: 'order.additional_fields_section.tocom',
  })

  const formData = useTocomFormData(orderId)
  const mutation = useEditOrderContents()

  const { showToast } = useToast({
    toastId: 'pages/order/components/SleeveSection/TocomForm',
  })

  const clearingBankErrorMessage = useOrderValidationErrorMessage(
    formData?.clearingBank,
  )

  const editContents = (contents: PartialMessage<Contents>): void => {
    mutation.mutate(
      {
        orderId,
        ...contents,
      },
      {
        onError: () => {
          showToast({
            status: 'error',
            toastMessage: t('failed_to_edit'),
          })
        },
      },
    )
  }

  const onChangeClearingBank = (clearingBank: string): void => {
    if (formData == null) {
      return
    }
    editContents({
      ...formData.contents,
      tocomClearingBank: clearingBank,
    })
  }

  if (formData == null) {
    return null
  }

  return (
    <Flex flexDirection="column" gap={Spacing['6']}>
      <FormControl>
        <Term>
          <Label required>{t('tocom_clearing_bank.label')}</Label>
          <DebouncedInput
            width="50%"
            error={clearingBankErrorMessage}
            initialValue={formData.clearingBank.value}
            milliseconds={500}
            onChange={onChangeClearingBank}
            placeholder={t('tocom_clearing_bank.placeholder')}
          />
        </Term>
      </FormControl>
    </Flex>
  )
}

export default React.memo(TocomForm)
