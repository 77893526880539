// @generated by protoc-gen-connect-query v1.3.1 with parameter "target=js+dts"
// @generated from file bff/v1/user_service.proto (package bff.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { MethodKind } from "@bufbuild/protobuf";
import { GetMyProfileRequest, GetMyProfileResponse, ListUsersRequest, ListUsersResponse, SearchOrganizationsRequest, SearchOrganizationsResponse } from "./user_service_pb.js";

/**
 * ユーザーの一覧を取得する
 *
 * @generated from rpc bff.v1.UserService.ListUsers
 */
export const listUsers = {
  localName: "listUsers",
  name: "ListUsers",
  kind: MethodKind.Unary,
  I: ListUsersRequest,
  O: ListUsersResponse,
  service: {
    typeName: "bff.v1.UserService"
  }
};

/**
 * 組織の一覧を取得する
 *
 * @generated from rpc bff.v1.UserService.SearchOrganizations
 */
export const searchOrganizations = {
  localName: "searchOrganizations",
  name: "SearchOrganizations",
  kind: MethodKind.Unary,
  I: SearchOrganizationsRequest,
  O: SearchOrganizationsResponse,
  service: {
    typeName: "bff.v1.UserService"
  }
};

/**
 * 自身のプロフィール情報を取得する
 *
 * @generated from rpc bff.v1.UserService.GetMyProfile
 */
export const getMyProfile = {
  localName: "getMyProfile",
  name: "GetMyProfile",
  kind: MethodKind.Unary,
  I: GetMyProfileRequest,
  O: GetMyProfileResponse,
  service: {
    typeName: "bff.v1.UserService"
  }
};
