// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file bff/v1/order_service.proto (package bff.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { DataSet, DataSetForOrder } from "./data_set_model_pb.js";
import { EditingOrder, OrderWithBids } from "@enechain/etender-proto/proto/etender/order/v1/model_pb.js";
import { OrderAdditionalFields } from "./order_model_pb.js";

/**
 * 自分の注文の一覧を取得するリクエスト
 *
 * @generated from message bff.v1.ListOrdersRequest
 */
export const ListOrdersRequest = /*@__PURE__*/ proto3.makeMessageType(
  "bff.v1.ListOrdersRequest",
  () => [
    { no: 1, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ],
);

/**
 * 自分の注文の一覧を取得するレスポンス
 *
 * @generated from message bff.v1.ListOrdersResponse
 */
export const ListOrdersResponse = /*@__PURE__*/ proto3.makeMessageType(
  "bff.v1.ListOrdersResponse",
  () => [
    { no: 1, name: "data_set", kind: "message", T: DataSet },
    { no: 2, name: "data_set_for_order", kind: "message", T: DataSetForOrder },
    { no: 3, name: "orders", kind: "message", T: OrderWithBids, repeated: true },
    { no: 4, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "total_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * 指定した編集中の注文を取得するリクエスト
 *
 * @generated from message bff.v1.GetEditingOrderRequest
 */
export const GetEditingOrderRequest = /*@__PURE__*/ proto3.makeMessageType(
  "bff.v1.GetEditingOrderRequest",
  () => [
    { no: 1, name: "order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * 指定した編集中の注文を取得するレスポンス
 *
 * @generated from message bff.v1.GetEditingOrderResponse
 */
export const GetEditingOrderResponse = /*@__PURE__*/ proto3.makeMessageType(
  "bff.v1.GetEditingOrderResponse",
  () => [
    { no: 2, name: "data_set_for_order", kind: "message", T: DataSetForOrder },
    { no: 3, name: "editing_order", kind: "message", T: EditingOrder },
    { no: 4, name: "tender_terms_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "additional_fields", kind: "message", T: OrderAdditionalFields },
  ],
);

