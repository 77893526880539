import * as React from 'react'
import { useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import { SemanticColors, Tag } from '@enechain/ecloud-designsystem'
import { SleeveType } from '@enechain/etender-proto/proto/etender/order/v1/model_pb'

type Props = {
  sleeveType: SleeveType
}

const OrderSleeveTypeTag: React.FC<Props> = ({ sleeveType }) => {
  const { t } = useTranslation('trader', {
    keyPrefix: 'order_sleeve_type',
  })

  const tag = useMemo(() => {
    switch (sleeveType) {
      case SleeveType.NONE:
        return {
          label: t('none'),
          color: SemanticColors.Text.midEmphasis,
          borderColor: SemanticColors.Border.neutralMid,
        }
      case SleeveType.BILATERAL:
        return {
          label: t('bilateral'),
          color: SemanticColors.Text.infoMid,
          borderColor: SemanticColors.Border.infoMid,
        }
      case SleeveType.EEX:
        return {
          label: t('eex'),
          color: SemanticColors.Text.infoMid,
          borderColor: SemanticColors.Border.infoMid,
        }
      case SleeveType.TOCOM:
        return {
          label: t('tocom'),
          color: SemanticColors.Text.infoMid,
          borderColor: SemanticColors.Border.infoMid,
        }
      default:
        return undefined
    }
  }, [sleeveType, t])

  if (tag == null) {
    return null
  }

  return (
    <Tag
      height="28px"
      color={tag.color}
      fontWeight="bold"
      borderWidth="1px"
      borderColor={tag.borderColor}
      backgroundColor={SemanticColors.Surface.primary}
    >
      {tag.label}
    </Tag>
  )
}

export default React.memo(OrderSleeveTypeTag)
