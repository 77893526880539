// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file bff/v1/user_service.proto (package bff.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { OrganizationType, Profile, UserType } from "./user_model_pb.js";
import { DataSet } from "./data_set_model_pb.js";

/**
 * ユーザーの一覧を取得するリクエスト
 *
 * @generated from message bff.v1.ListUsersRequest
 */
export const ListUsersRequest = /*@__PURE__*/ proto3.makeMessageType(
  "bff.v1.ListUsersRequest",
  () => [
    { no: 1, name: "organization_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "user_type", kind: "enum", T: proto3.getEnumType(UserType) },
    { no: 3, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * ユーザーの一覧を取得するレスポンス
 *
 * @generated from message bff.v1.ListUsersResponse
 */
export const ListUsersResponse = /*@__PURE__*/ proto3.makeMessageType(
  "bff.v1.ListUsersResponse",
  () => [
    { no: 1, name: "user_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "data_set", kind: "message", T: DataSet },
    { no: 3, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "total_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * 組織を検索するリクエスト
 *
 * @generated from message bff.v1.SearchOrganizationsRequest
 */
export const SearchOrganizationsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "bff.v1.SearchOrganizationsRequest",
  () => [
    { no: 1, name: "query", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "type", kind: "enum", T: proto3.getEnumType(OrganizationType) },
    { no: 3, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * 組織を検索するレスポンス
 *
 * @generated from message bff.v1.SearchOrganizationsResponse
 */
export const SearchOrganizationsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "bff.v1.SearchOrganizationsResponse",
  () => [
    { no: 1, name: "organization_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "data_set", kind: "message", T: DataSet },
    { no: 3, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "total_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * @generated from message bff.v1.GetMyProfileRequest
 */
export const GetMyProfileRequest = /*@__PURE__*/ proto3.makeMessageType(
  "bff.v1.GetMyProfileRequest",
  [],
);

/**
 * @generated from message bff.v1.GetMyProfileResponse
 */
export const GetMyProfileResponse = /*@__PURE__*/ proto3.makeMessageType(
  "bff.v1.GetMyProfileResponse",
  () => [
    { no: 1, name: "profile", kind: "message", T: Profile },
    { no: 2, name: "organization_image", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ],
);

