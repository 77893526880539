import * as React from 'react'
import type { SVGProps } from 'react'
import { memo } from 'react'

const SvgDownloadIcon: React.FC<SVGProps<SVGSVGElement>> = (
  props: SVGProps<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M8.882 14.128c-.3-.343-.3-.697 0-1.061.364-.3.718-.3 1.06 0l1.286 1.254v-4.308c.043-.472.3-.729.772-.772.472.043.729.3.772.772v4.308l1.253-1.254c.365-.3.718-.3 1.061 0 .322.364.322.718 0 1.06L12.514 16.7c-.342.321-.696.321-1.06 0l-2.572-2.572Zm-2.54 5.4c-1.307-.042-2.4-.492-3.28-1.35-.857-.878-1.307-1.971-1.35-3.279.022-1.028.311-1.928.869-2.7a4.428 4.428 0 0 1 2.218-1.672c.043-1.521.568-2.797 1.575-3.825C7.403 5.694 8.678 5.169 10.2 5.126c.814 0 1.575.172 2.282.515a5.11 5.11 0 0 1 1.8 1.35 3.908 3.908 0 0 1 1.576-.322c1.093.022 2.004.397 2.732 1.126.73.728 1.104 1.64 1.126 2.732 0 .215-.011.418-.033.611a4.52 4.52 0 0 1 1.865 1.607c.472.708.718 1.511.74 2.412-.022 1.2-.418 2.196-1.19 2.99-.772.814-1.747 1.275-2.925 1.382H6.341ZM10.2 6.67c-1.093.022-2.004.397-2.733 1.126-.729.728-1.104 1.64-1.125 2.732v1.093l-1.061.386c-.6.214-1.082.579-1.447 1.093-.364.514-.557 1.114-.578 1.8.021.88.321 1.608.9 2.187.578.578 1.307.878 2.186.9h11.734c.772-.065 1.404-.365 1.897-.9.493-.515.75-1.158.771-1.93 0-.578-.16-1.103-.482-1.575-.3-.45-.707-.793-1.221-1.028l-1.093-.483.192-1.157c.022-.129.032-.257.032-.386-.02-.664-.246-1.21-.675-1.64-.428-.428-.975-.653-1.64-.675-.342 0-.653.065-.931.193l-1.061.483-.74-.868c-.75-.858-1.736-1.308-2.957-1.35h.032Z"
    />
  </svg>
)
const Memo = memo(SvgDownloadIcon)
export default Memo
