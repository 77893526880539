import * as React from 'react'
import type { SVGProps } from 'react'
import { memo } from 'react'

const SvgChatIcon: React.FC<SVGProps<SVGSVGElement>> = (
  props: SVGProps<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M12 5c1.48.02 2.823.313 4.031.875 1.188.583 2.136 1.365 2.844 2.344a5.64 5.64 0 0 1 1.094 3.281 5.639 5.639 0 0 1-1.094 3.281c-.708.98-1.656 1.76-2.844 2.344-1.208.563-2.552.854-4.031.875a9.335 9.335 0 0 1-2.906-.438c-.48.334-1.084.657-1.813.97-.729.29-1.573.447-2.531.468-.313-.02-.542-.177-.688-.469-.125-.291-.072-.562.157-.812.02-.021.198-.25.531-.688.333-.416.635-.948.906-1.593C4.594 14.332 4.042 13.02 4 11.5a5.639 5.639 0 0 1 1.094-3.281c.708-.98 1.666-1.76 2.875-2.344C9.156 5.312 10.5 5.021 12 5Zm0 11.5c1.833-.042 3.365-.531 4.594-1.469 1.229-.937 1.864-2.114 1.906-3.531-.042-1.417-.677-2.594-1.906-3.531C15.364 7.03 13.834 6.542 12 6.5c-1.833.042-3.365.531-4.594 1.469-1.229.937-1.864 2.114-1.906 3.531.02.667.156 1.24.406 1.719.271.5.552.896.844 1.187l.625.688-.313.875c-.187.458-.395.885-.625 1.281.75-.27 1.344-.573 1.782-.906l.625-.438.719.219c.791.25 1.604.375 2.437.375Z"
    />
  </svg>
)
const Memo = memo(SvgChatIcon)
export default Memo
