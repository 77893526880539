import * as React from 'react'

import { Box } from '@chakra-ui/react'

import { SemanticColors } from '@enechain/ecloud-designsystem'

import EmptyBidRow from '~/components/order/EmptyBidRow'
import { useBidHistoryTableData } from '~/pages/history-detail/components/OrderHistorySection/HistoryTable/BidHistoryBodyBox/hooks/useBidHistoryTableData'
import BidHistoryTableBodyRow from '~/pages/history-detail/components/OrderHistorySection/HistoryTable/BidHistoryTableBodyRow'

type Props = {
  tenderId: string
  tenderTermsId: string
  orderId: string
}

const BidHistoryBodyBox: React.FC<Props> = ({
  tenderId,
  tenderTermsId,
  orderId,
}) => {
  const { bidIds } = useBidHistoryTableData(tenderId, orderId, tenderTermsId)

  if (bidIds.length === 0) {
    return <EmptyBidRow />
  }

  return bidIds.map((bidId) => (
    <Box
      key={bidId}
      borderColor={SemanticColors.Border.lowEmphasis}
      borderBottomWidth="1px"
      _last={{ borderBottomWidth: 0 }}
    >
      <BidHistoryTableBodyRow
        bidId={bidId}
        orderId={orderId}
        tenderId={tenderId}
        tenderTermsId={tenderTermsId}
      />
    </Box>
  ))
}

export default React.memo(BidHistoryBodyBox)
