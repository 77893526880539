import * as React from 'react'

import { Input, InputProps } from '@enechain/ecloud-designsystem'

import { UseDebouncedInputProps, useDebouncedInput } from '../../hooks'

type Props = Omit<InputProps, 'value' | 'onChange'> & UseDebouncedInputProps

const DebouncedInput: React.FC<Props> = ({
  initialValue,
  onChange,
  milliseconds = 500,
  ...rest
}) => {
  const debouncedInput = useDebouncedInput<HTMLInputElement>({
    initialValue,
    onChange,
    milliseconds,
  })

  return <Input {...rest} {...debouncedInput} />
}

export default React.memo(DebouncedInput)
