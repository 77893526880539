import * as React from 'react'

import { useTranslation } from 'react-i18next'

import { Flex } from '@chakra-ui/react'

import { Spacing } from '@enechain/ecloud-designsystem'

import EclearForm from '~/pages/order/components/AdditionalFieldsSection/EclearForm'
import FormContainer from '~/pages/order/components/AdditionalFieldsSection/FormContainer'
import TocomForm from '~/pages/order/components/AdditionalFieldsSection/TocomForm'
import { useEditingOrder } from '~/pages/order/hooks/useEditingOrder'

type AdditionalFieldsSectionData = {
  isShowEclearForm: boolean
  isShowEexForm: boolean
  isShowTocomForm: boolean
}

const useAdditionalFieldsSectionData = (
  orderId: string,
): AdditionalFieldsSectionData | undefined => {
  const { data } = useEditingOrder(orderId, (response) => {
    if (response.additionalFields?.requirements == null) {
      return undefined
    }
    const requirements = response.additionalFields.requirements
    return {
      isShowEclearForm: requirements.eclear,
      isShowEexForm: requirements.eex,
      isShowTocomForm: requirements.tocom,
    }
  })
  return data
}

type Props = {
  orderId: string
}

const AdditionalFieldsSection: React.FC<Props> = ({ orderId }) => {
  const { t } = useTranslation('trader', {
    keyPrefix: 'order.additional_fields_section',
  })

  const data = useAdditionalFieldsSectionData(orderId)

  if (
    data == null ||
    (!data.isShowEclearForm && !data.isShowEexForm && !data.isShowTocomForm)
  ) {
    return null
  }

  return (
    <Flex flexDirection="column" gap={Spacing['4']}>
      {data.isShowEclearForm && (
        <FormContainer
          description={t('eclear.description')}
          label={t('eclear.label')}
        >
          <EclearForm orderId={orderId} />
        </FormContainer>
      )}
      {data.isShowTocomForm && (
        <FormContainer label={t('tocom.label')}>
          <TocomForm orderId={orderId} />
        </FormContainer>
      )}
    </Flex>
  )
}

export default React.memo(AdditionalFieldsSection)
