import * as React from 'react'
import { Suspense, useEffect } from 'react'

import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react'
import { datadogRum } from '@datadog/browser-rum'
import { Outlet } from 'react-router-dom'

import { FullLoadingView } from '@enechain/common-ui/src/components/full-loading-view'
import UnauthorizedErrorPage from '@enechain/common-ui/src/layout/ErrorLayout/UnauthorizedErrorPage'
import { SideMenuLayout } from '@enechain/ecloud-designsystem'

import { useSetPreviousPageUrl } from '~/hooks/usePreviousPageUrl'
import { BottomMenu } from '~/layouts/MainLayout/BottomMenu'
import { Logo } from '~/layouts/MainLayout/Logo'
import { TopMenu } from '~/layouts/MainLayout/TopMenu'

type Props = {
  pictureUrl?: string
  children: React.ReactNode
}

const DrawerLayout: React.FC<Props> = ({ children }) => {
  const { isAuthenticated, isLoading, user } = useAuth0()

  useSetPreviousPageUrl()

  useEffect(() => {
    if (isAuthenticated && user?.sub != null) {
      // eAuth移行でorganizationIdを取れるようになったら一緒に送る
      datadogRum.setUser({
        id: user.sub,
      })
    } else {
      datadogRum.setUser({})
    }
  }, [isAuthenticated, user])

  if (isLoading) {
    return <FullLoadingView />
  }

  if (!isAuthenticated) {
    return <UnauthorizedErrorPage />
  }

  return (
    <SideMenuLayout bottomMenu={BottomMenu} logo={Logo} topMenu={TopMenu}>
      {children}
    </SideMenuLayout>
  )
}

const LoadingLayout: React.FC = () => {
  return (
    <DrawerLayout>
      <FullLoadingView />
    </DrawerLayout>
  )
}

const MainLayout: React.FC = () => {
  return (
    <DrawerLayout>
      <Suspense fallback={<FullLoadingView />}>
        <Outlet />
      </Suspense>
    </DrawerLayout>
  )
}

const ProtectedComponent: (component: React.ComponentType) => React.FC = (
  component: React.ComponentType,
) => {
  return withAuthenticationRequired(component, {
    onRedirecting: () => <LoadingLayout />,
    loginOptions: {
      appState: {
        returnTo: window.location.href,
      },
    },
  })
}

export default ProtectedComponent(MainLayout)
