import { useCallback } from 'react'

import { useAuth0 } from '@auth0/auth0-react'

export function useAccessToken(): { getAccessToken: () => Promise<string> } {
  const { getAccessTokenSilently } = useAuth0()

  const getAccessToken = useCallback(async (): Promise<string> => {
    return getAccessTokenSilently().catch(() => '')
  }, [getAccessTokenSilently])

  return {
    getAccessToken,
  }
}
