import * as React from 'react'

import { useSuspenseQuery } from '@connectrpc/connect-query'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Flex } from '@chakra-ui/react'

import { useToast } from '@enechain/common-ui'
import {
  PrimaryButton,
  Spacing,
  TertiaryButton,
  TextButton,
} from '@enechain/ecloud-designsystem'
import { getEditingOrder } from '@enechain/etender-proto/proto/bff/v1/order_service-OrderService_connectquery'
import { Status } from '@enechain/etender-proto/proto/etender/order/v1/model_pb'

import { useApplyEditingOrder } from '~/pages/order-confirm/hooks/useApplyEditingOrder'
import { useDiscardEditingOrder } from '~/pages/order-confirm/hooks/useDiscardEditingOrder'
import { routePaths } from '~/routes/routePaths'

type OrderConfirmFooterDataData = {
  actionType: 'order' | 'edit'
}

const useOrderConfirmFooterData = (
  orderId: string,
): OrderConfirmFooterDataData => {
  const { data } = useSuspenseQuery(
    getEditingOrder,
    { orderId },
    {
      select: (response): OrderConfirmFooterDataData => {
        return {
          actionType:
            response.editingOrder?.status === Status.DRAFT ? 'order' : 'edit',
        }
      },
    },
  )
  return data
}

type Props = {
  tenderId: string
  orderId: string
  onOpenOrderCompleteModal: () => void
  onOpenOrderEditModal: () => void
}

const OrderConfirmFooter: React.FC<Props> = ({
  tenderId,
  orderId,
  onOpenOrderCompleteModal,
  onOpenOrderEditModal,
}) => {
  const { t: commonT } = useTranslation('common')
  const { t } = useTranslation('trader', {
    keyPrefix: 'order_confirm',
  })

  const { actionType } = useOrderConfirmFooterData(orderId)

  const { showToast } = useToast({
    toastId: 'pages/order-confirm/Footer',
  })

  const navigate = useNavigate()
  const discardMutation = useDiscardEditingOrder()
  const applyMutation = useApplyEditingOrder(tenderId)

  const onClickCancel = (): void => {
    discardMutation.mutate(
      { orderId },
      {
        onSuccess: () => {
          switch (actionType) {
            case 'order': {
              navigate(routePaths.tender())
              break
            }
            case 'edit': {
              navigate(
                routePaths.historyDetail({
                  params: { tenderId },
                }),
              )
              break
            }
          }
        },
        onError: () => {
          showToast({
            status: 'error',
            toastMessage: t('failed_to_cancel'),
          })
        },
      },
    )
  }

  const onClickBack = (): void => {
    navigate(
      routePaths.order({
        params: { tenderId, orderId },
      }),
    )
  }

  const onClickOrder = (): void => {
    applyMutation.mutate(
      { orderId },
      {
        onSuccess: () => {
          switch (actionType) {
            case 'order': {
              onOpenOrderCompleteModal()
              return
            }
            case 'edit': {
              onOpenOrderEditModal()
              return
            }
          }
        },
        onError: () => {
          showToast({
            status: 'error',
            toastMessage: t('failed_to_apply'),
          })
        },
      },
    )
  }

  return (
    <Flex
      alignItems="center"
      justifyContent="flex-end"
      gap={Spacing['8']}
      minHeight="72px"
      boxShadow="base"
      paddingX={Spacing['10']}
    >
      <TextButton onClick={onClickCancel}>
        {commonT('action.cancel')}
      </TextButton>
      <Flex gap={Spacing['2']}>
        <TertiaryButton
          isDisabled={discardMutation.isPending}
          isLoading={discardMutation.isPending}
          onClick={onClickBack}
        >
          {commonT('action.back')}
        </TertiaryButton>
        <PrimaryButton
          isDisabled={applyMutation.isPending}
          isLoading={applyMutation.isPending}
          onClick={onClickOrder}
        >
          {t('proceed_order')}
        </PrimaryButton>
      </Flex>
    </Flex>
  )
}

export default React.memo(OrderConfirmFooter)
