import { useSuspenseQuery } from '@connectrpc/connect-query'

import { listByTenderID } from '@enechain/etender-proto/proto/bff/v1/bid_service-BidService_connectquery'

type BidHistoryTableData = {
  bidIds: string[]
}

export function useBidHistoryTableData(
  tenderId: string,
  orderId: string,
  tenderTermsId: string,
): BidHistoryTableData {
  const { data } = useSuspenseQuery(
    listByTenderID,
    {
      tenderId,
    },
    {
      select: (response): BidHistoryTableData => {
        const history = response.histories[orderId]
        const bidIds =
          history?.bids[tenderTermsId]?.bids.map((bid) => bid.id) ?? []
        return {
          bidIds,
        }
      },
    },
  )

  return data
}
