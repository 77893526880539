import * as React from 'react'
import { useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Flex, FormControl, Text, UseDisclosureReturn } from '@chakra-ui/react'

import { useToast } from '@enechain/common-ui'
import {
  Label,
  PrimaryModal,
  RadioButton,
  SemanticColors,
  Spacing,
  Term,
  Typography,
} from '@enechain/ecloud-designsystem'
import { SleeveType } from '@enechain/etender-proto/proto/etender/order/v1/model_pb'
import { CreateEmptyResponse } from '@enechain/etender-proto/proto/etender/order/v1/service_pb'

import { useCreateEmptyOrder } from '~/pages/tender/hooks/useCreateEmptyOrder'
import { routePaths } from '~/routes/routePaths'

type Props = {
  disclosure: UseDisclosureReturn
  tenderId: string
  withSleeverAvailable: boolean
}

// directは「応札締切日時」までbidできる
// with-sleeverは「スリーブ利用時の締切日時」までbidできる
type BiddingMethod = 'direct' | 'with-sleever'

const isBiddingMethod = (value: string): value is BiddingMethod => {
  return ['direct', 'with-sleever'].includes(value)
}

const convertSleeveType = (value: string): SleeveType | undefined => {
  switch (value) {
    case SleeveType.NONE.toString():
      return SleeveType.NONE
    case SleeveType.EEX.toString():
      return SleeveType.EEX
    case SleeveType.TOCOM.toString():
      return SleeveType.TOCOM
    case SleeveType.BILATERAL.toString():
      return SleeveType.BILATERAL
    default:
      return undefined
  }
}

const CreateOrderModal: React.FC<Props> = ({
  disclosure,
  tenderId,
  withSleeverAvailable,
}) => {
  const { t } = useTranslation('trader', {
    keyPrefix: 'tender.list.create_order_modal',
  })

  const navigate = useNavigate()
  const { showToast } = useToast({
    toastId: 'pages/tender/components/CreateOrderModal',
  })

  const [biddingMethod, setBiddingMethod] = useState<
    BiddingMethod | undefined
  >()
  const [sleeveType, setSleeveType] = useState<SleeveType | undefined>()

  const createEmptyMutation = useCreateEmptyOrder()

  const onChangeBiddingMethod = (value: string): void => {
    if (!isBiddingMethod(value)) {
      return
    }
    setBiddingMethod(value)
  }

  const onChangeSleeveType = (value: string): void => {
    const convertedSleeveType = convertSleeveType(value)
    if (convertedSleeveType == null) {
      return
    }
    setSleeveType(convertedSleeveType)
  }

  const onClickCreateEmptyOrder = (): void => {
    const requestSleeveType =
      biddingMethod === 'with-sleever' ? sleeveType : SleeveType.NONE

    createEmptyMutation.mutate(
      {
        tenderId,
        sleeveType: requestSleeveType,
      },
      {
        onSuccess: ({ editingOrder }: CreateEmptyResponse): void => {
          if (editingOrder == null) {
            return
          }

          navigate(
            routePaths.order({
              params: {
                tenderId: editingOrder.tenderId,
                orderId: editingOrder.id,
              },
            }),
          )
        },
        onError: (): void => {
          showToast({
            status: 'error',
            toastMessage: t('failed_to_create_order'),
          })
        },
      },
    )
  }

  const isDisabledCreateEmptyOrder =
    biddingMethod == null ||
    (biddingMethod === 'with-sleever' && sleeveType == null) ||
    createEmptyMutation.isPending

  return (
    <PrimaryModal
      actionText={t('order')}
      bodyChildren={
        <Flex flexDirection="column" gap={Spacing['6']}>
          <FormControl>
            <Term>
              <Flex flexDirection="column" gap={Spacing['4']}>
                <Label required>{t('bidding_method_label')}</Label>
                <RadioButton
                  direction="column"
                  onChange={onChangeBiddingMethod}
                  radios={[
                    {
                      label: t('bidding_method_direct'),
                      value: 'direct',
                    },
                    {
                      label: t('bidding_method_with_sleever'),
                      value: 'with-sleever',
                      isDisabled: !withSleeverAvailable,
                    },
                  ]}
                  value={biddingMethod}
                />
              </Flex>
            </Term>
          </FormControl>
          {biddingMethod === 'with-sleever' && (
            <FormControl>
              <Term>
                <Flex flexDirection="column" gap={Spacing['2']}>
                  <Flex flexDirection="column" gap={Spacing['4']}>
                    <Label required>{t('sleeve_type_label')}</Label>
                    <RadioButton
                      onChange={onChangeSleeveType}
                      radios={[
                        {
                          label: t('sleeve_type_eex'),
                          value: SleeveType.EEX.toString(),
                          isDisabled: !withSleeverAvailable,
                        },
                        {
                          label: t('sleeve_type_tocom'),
                          value: SleeveType.TOCOM.toString(),
                          isDisabled: !withSleeverAvailable,
                        },
                        {
                          label: t('sleeve_type_bilateral'),
                          value: SleeveType.BILATERAL.toString(),
                          isDisabled: !withSleeverAvailable,
                        },
                      ]}
                      value={sleeveType?.toString()}
                    />
                  </Flex>
                  <Text
                    {...Typography.textSm}
                    color={SemanticColors.Text.lowEmphasis}
                  >
                    {t('description')}
                  </Text>
                </Flex>
              </Term>
            </FormControl>
          )}
        </Flex>
      }
      disclosure={disclosure}
      isDisabled={isDisabledCreateEmptyOrder}
      isLoading={createEmptyMutation.isPending}
      onClick={onClickCreateEmptyOrder}
      title={t('title')}
    />
  )
}

export default React.memo(CreateOrderModal)
