// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file etender/order/v1/service.proto (package etender.order.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { EditingOrder, OrderWithBids, SleeveType } from "./model_pb.js";

/**
 * 自分の注文の一覧を取得するリクエスト
 *
 * @generated from message etender.order.v1.ListRequest
 */
export const ListRequest = /*@__PURE__*/ proto3.makeMessageType(
  "etender.order.v1.ListRequest",
  () => [
    { no: 1, name: "page_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "page_token", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ],
);

/**
 * 自分の注文の一覧を取得するレスポンス
 *
 * @generated from message etender.order.v1.ListResponse
 */
export const ListResponse = /*@__PURE__*/ proto3.makeMessageType(
  "etender.order.v1.ListResponse",
  () => [
    { no: 1, name: "orders", kind: "message", T: OrderWithBids, repeated: true },
    { no: 2, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "total_size", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ],
);

/**
 * 指定した入札案件に入った自分の注文の一覧を取得するリクエスト
 *
 * @generated from message etender.order.v1.ListByTenderIDRequest
 */
export const ListByTenderIDRequest = /*@__PURE__*/ proto3.makeMessageType(
  "etender.order.v1.ListByTenderIDRequest",
  () => [
    { no: 1, name: "tender_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * 指定した入札案件に入った自分の注文の一覧を取得するレスポンス
 *
 * @generated from message etender.order.v1.ListByTenderIDResponse
 */
export const ListByTenderIDResponse = /*@__PURE__*/ proto3.makeMessageType(
  "etender.order.v1.ListByTenderIDResponse",
  () => [
    { no: 1, name: "orders", kind: "message", T: OrderWithBids, repeated: true },
  ],
);

/**
 * 指定した入札案件に入ったすべての注文を取得するリクエスト
 *
 * @generated from message etender.order.v1.ListAllByTenderIDRequest
 */
export const ListAllByTenderIDRequest = /*@__PURE__*/ proto3.makeMessageType(
  "etender.order.v1.ListAllByTenderIDRequest",
  () => [
    { no: 1, name: "tender_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * 指定した入札案件に入ったすべての注文を取得するレスポンス
 *
 * @generated from message etender.order.v1.ListAllByTenderIDResponse
 */
export const ListAllByTenderIDResponse = /*@__PURE__*/ proto3.makeMessageType(
  "etender.order.v1.ListAllByTenderIDResponse",
  () => [
    { no: 1, name: "orders", kind: "message", T: OrderWithBids, repeated: true },
  ],
);

/**
 * 指定した編集中の注文を取得するリクエスト
 *
 * @generated from message etender.order.v1.GetEditingRequest
 */
export const GetEditingRequest = /*@__PURE__*/ proto3.makeMessageType(
  "etender.order.v1.GetEditingRequest",
  () => [
    { no: 1, name: "order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * 指定した編集中の注文を取得するレスポンス
 *
 * @generated from message etender.order.v1.GetEditingResponse
 */
export const GetEditingResponse = /*@__PURE__*/ proto3.makeMessageType(
  "etender.order.v1.GetEditingResponse",
  () => [
    { no: 1, name: "editing_order", kind: "message", T: EditingOrder },
  ],
);

/**
 * 空の注文を下書き状態で作成するリクエスト
 *
 * @generated from message etender.order.v1.CreateEmptyRequest
 */
export const CreateEmptyRequest = /*@__PURE__*/ proto3.makeMessageType(
  "etender.order.v1.CreateEmptyRequest",
  () => [
    { no: 1, name: "tender_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "sleeve_type", kind: "enum", T: proto3.getEnumType(SleeveType) },
  ],
);

/**
 * 空の注文を下書き状態で作成するレスポンス
 *
 * @generated from message etender.order.v1.CreateEmptyResponse
 */
export const CreateEmptyResponse = /*@__PURE__*/ proto3.makeMessageType(
  "etender.order.v1.CreateEmptyResponse",
  () => [
    { no: 1, name: "editing_order", kind: "message", T: EditingOrder },
  ],
);

/**
 * 空の注文を下書き状態で代理作成するリクエスト
 *
 * @generated from message etender.order.v1.CreateEmptyByAgentRequest
 */
export const CreateEmptyByAgentRequest = /*@__PURE__*/ proto3.makeMessageType(
  "etender.order.v1.CreateEmptyByAgentRequest",
  () => [
    { no: 1, name: "tender_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "principal_user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "principal_organization_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "sleeve_type", kind: "enum", T: proto3.getEnumType(SleeveType) },
  ],
);

/**
 * 空の注文を下書き状態で代理作成するレスポンス
 *
 * @generated from message etender.order.v1.CreateEmptyByAgentResponse
 */
export const CreateEmptyByAgentResponse = /*@__PURE__*/ proto3.makeMessageType(
  "etender.order.v1.CreateEmptyByAgentResponse",
  () => [
    { no: 1, name: "editing_order", kind: "message", T: EditingOrder },
  ],
);

/**
 * 注文内容を編集するリクエスト
 *
 * @generated from message etender.order.v1.EditContentsRequest
 */
export const EditContentsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "etender.order.v1.EditContentsRequest",
  () => [
    { no: 1, name: "order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "sleeve_type", kind: "enum", T: proto3.getEnumType(SleeveType) },
    { no: 3, name: "eex_trader_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "eex_clearing_bank", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "eex_clearing_account", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "tocom_trader_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "tocom_clearing_bank", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 8, name: "tocom_clearing_account", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 9, name: "eclear_trader_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 10, name: "eclear_keyword", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ],
);

/**
 * 注文内容を編集するレスポンス
 *
 * @generated from message etender.order.v1.EditContentsResponse
 */
export const EditContentsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "etender.order.v1.EditContentsResponse",
  () => [
    { no: 1, name: "editing_order", kind: "message", T: EditingOrder },
  ],
);

/**
 * 注文を申し込むリクエスト
 *
 * @generated from message etender.order.v1.ApplyRequest
 */
export const ApplyRequest = /*@__PURE__*/ proto3.makeMessageType(
  "etender.order.v1.ApplyRequest",
  () => [
    { no: 1, name: "order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * 注文を申し込むレスポンス
 *
 * @generated from message etender.order.v1.ApplyResponse
 */
export const ApplyResponse = /*@__PURE__*/ proto3.makeMessageType(
  "etender.order.v1.ApplyResponse",
  [],
);

/**
 * 注文を取り下げるリクエスト
 *
 * @generated from message etender.order.v1.DisableRequest
 */
export const DisableRequest = /*@__PURE__*/ proto3.makeMessageType(
  "etender.order.v1.DisableRequest",
  () => [
    { no: 1, name: "order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * 注文を取り下げるレスポンス
 *
 * @generated from message etender.order.v1.DisableResponse
 */
export const DisableResponse = /*@__PURE__*/ proto3.makeMessageType(
  "etender.order.v1.DisableResponse",
  [],
);

/**
 * 編集中の注文の変更差分を破棄するリクエスト
 *
 * @generated from message etender.order.v1.DiscardRequest
 */
export const DiscardRequest = /*@__PURE__*/ proto3.makeMessageType(
  "etender.order.v1.DiscardRequest",
  () => [
    { no: 1, name: "order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * 編集中の注文の変更差分を破棄するレスポンス
 *
 * @generated from message etender.order.v1.DiscardResponse
 */
export const DiscardResponse = /*@__PURE__*/ proto3.makeMessageType(
  "etender.order.v1.DiscardResponse",
  [],
);

/**
 * 注文を削除するリクエスト
 *
 * @generated from message etender.order.v1.DeleteRequest
 */
export const DeleteRequest = /*@__PURE__*/ proto3.makeMessageType(
  "etender.order.v1.DeleteRequest",
  () => [
    { no: 1, name: "order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * 注文を削除するレスポンス
 *
 * @generated from message etender.order.v1.DeleteResponse
 */
export const DeleteResponse = /*@__PURE__*/ proto3.makeMessageType(
  "etender.order.v1.DeleteResponse",
  [],
);

