import * as React from 'react'
import type { SVGProps } from 'react'
import { memo } from 'react'

const SvgHandshakeIcon: React.FC<SVGProps<SVGSVGElement>> = (
  props: SVGProps<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 21 20"
    {...props}
  >
    <path
      fill="#024C13"
      d="m15.647 6.404 3.588.027v6.855l-1.74-.027a.802.802 0 0 1-.59-.24.834.834 0 0 1-.24-.616V7.717h-1.473a.642.642 0 0 1-.322-.215c-.017-.071-.267-.33-.75-.776-.481-.446-1.133-.759-1.954-.937-.286-.036-.544.053-.777.267L9.088 8.413a.438.438 0 0 0-.134.321.47.47 0 0 0 .134.348c.214.179.437.179.67 0l1.445-1.446c.303-.25.607-.25.91 0 .25.304.25.607 0 .91l-.24.215 3.24 2.624c.303.268.473.59.508.964.036.375-.071.723-.321 1.044l-.723.964c-.214.25-.473.41-.777.482-.285.072-.58.054-.883-.053a1.533 1.533 0 0 1-.884.428 1.584 1.584 0 0 1-1.151-.348l-.027-.027a2.498 2.498 0 0 1-1.018.375h-.214c-.518 0-.982-.17-1.392-.509l-2.062-1.847H4.534a.848.848 0 0 1-.722.428H2.098V6.431h2.57v5.141H6.65l2.41 2.17c.178.142.402.205.67.187a.942.942 0 0 0 .615-.348l.08-.08.402-.375.884.723c.071.053.134.071.187.053.036 0 .098-.036.188-.107l.696-.723.589.482c.071.054.134.045.188-.027l.75-.964c.053-.071.044-.143-.028-.214l-3.32-2.678-.295.295c-.357.339-.776.509-1.258.509-.464 0-.884-.161-1.259-.482-.339-.34-.508-.75-.508-1.232.018-.5.196-.92.535-1.259l1.553-1.58a.91.91 0 0 0-.562-.133c-.821.178-1.473.49-1.955.937-.482.446-.732.705-.75.776a.61.61 0 0 1-.508.241.66.66 0 0 1-.375-.107c-.304-.268-.348-.571-.134-.91.018-.036.17-.214.455-.536.286-.303.687-.625 1.205-.964.518-.339 1.143-.571 1.875-.696a2.283 2.283 0 0 1 1.686.455 2.225 2.225 0 0 1 1.688-.482c.839.16 1.535.447 2.088.857.553.393.955.741 1.205 1.044ZM3.383 12.43c.268-.018.41-.16.429-.428-.018-.268-.161-.41-.429-.428-.268.017-.41.16-.428.428.017.268.16.41.428.428Zm14.567 0c.268-.018.41-.16.428-.428-.018-.268-.16-.41-.428-.428-.268.017-.41.16-.428.428.017.268.16.41.428.428Z"
    />
  </svg>
)
const Memo = memo(SvgHandshakeIcon)
export default Memo
