import * as React from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { useTranslation } from 'react-i18next'

import {
  SideMenuLayoutAppSwitcherMenu,
  SideMenuLayoutAppSwitcherMenuItem,
  SideMenuLayoutMenuItem,
  SideMenuLayoutPopOverMenuItem,
  SideMenuLayoutUserMenu,
} from '@enechain/ecloud-designsystem'
import {
  ECloudSymbol,
  ECompassSymbol,
  EScanSymbol,
  ESquareSymbol,
  EnecAppswitcherRegular,
  FaCircleQuestionRegular,
  JcexSymbol,
} from '@enechain/ecloud-icons'

import { eCloudRoutePaths } from '~/routes/routePaths'

export const BottomMenu: React.FC = () => {
  const { logout, user } = useAuth0()

  const { t } = useTranslation()

  return (
    <>
      <SideMenuLayoutMenuItem
        IconLeft={FaCircleQuestionRegular}
        label={t('menu.help_center')}
        onClick={(): void =>
          void window.open('https://support.enechain.co.jp', '_blank')
        }
      />
      <SideMenuLayoutAppSwitcherMenu
        Icon={EnecAppswitcherRegular}
        label={t('menu.switch_service')}
        popOverMenu={
          <>
            <SideMenuLayoutAppSwitcherMenuItem
              Icon={ECloudSymbol}
              description={t('menu.app_switcher.ecloud.description')}
              label={t('menu.app_switcher.ecloud.title')}
              onClick={(): void =>
                void window.open(import.meta.env.VITE_ECLOUD_URL, '_blank')
              }
            />
            <SideMenuLayoutAppSwitcherMenuItem
              Icon={ESquareSymbol} // TODO: 空にする
              description={t('menu.app_switcher.esquare.description')}
              label={t('menu.app_switcher.esquare.title')}
              onClick={(): void =>
                void window.open(import.meta.env.VITE_ESQUARE_URL, '_blank')
              }
            />
            <SideMenuLayoutAppSwitcherMenuItem
              Icon={ECompassSymbol}
              description={t('menu.app_switcher.ecompass.description')}
              label={t('menu.app_switcher.ecompass.title')}
              onClick={(): void =>
                void window.open(import.meta.env.VITE_ECOMPASS_URL, '_blank')
              }
            />
            <SideMenuLayoutAppSwitcherMenuItem
              Icon={EScanSymbol}
              description={t('menu.app_switcher.escan.description')}
              label={t('menu.app_switcher.escan.title')}
              onClick={(): void =>
                void window.open(import.meta.env.VITE_ESCAN_URL, '_blank')
              }
            />
            <SideMenuLayoutAppSwitcherMenuItem
              Icon={JcexSymbol}
              description={t('menu.app_switcher.jcex.description')}
              label={t('menu.app_switcher.jcex.title')}
              onClick={(): void =>
                void window.open(import.meta.env.VITE_JCEX_URL, '_blank')
              }
            />
          </>
        }
      />
      <SideMenuLayoutUserMenu
        popOverMenu={
          <>
            <SideMenuLayoutPopOverMenuItem
              label={t('menu.profile')}
              onClick={(): void =>
                void window.open(eCloudRoutePaths.profile(), '_blank')
              }
            />
            <SideMenuLayoutPopOverMenuItem
              label={t('menu.logout')}
              onClick={(): void =>
                void logout({
                  logoutParams: { returnTo: window.location.origin },
                })
              }
            />
          </>
        }
        userName={user?.name ?? ''}
      />
    </>
  )
}
