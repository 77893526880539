import * as React from 'react'

import { useSuspenseQuery } from '@connectrpc/connect-query'
import { useTranslation } from 'react-i18next'
import { Await, useLoaderData } from 'react-router-dom'

import { Flex } from '@chakra-ui/react'

import { EmptyLayout, ErrorLayout } from '@enechain/common-ui'
import {
  ListPager,
  SideMenuLayoutBreadcrumb,
  SideMenuLayoutContentLayout,
  Spacing,
} from '@enechain/ecloud-designsystem'
import { listOrdered } from '@enechain/etender-proto/proto/bff/v1/tender_service-TenderService_connectquery'

import OrderedTenderSection from '~/pages/history/components/OrderedTenderSection'
import { DefaultOrderHistoryPageSize } from '~/pages/history/constants'
import { useHistoryPageToken } from '~/pages/history/hooks/useHistoryPageToken'
import { HistoryData, HistoryUrlParams } from '~/pages/history/loader'

type HistoryPageData = {
  pageSize: number
  pageCount: number
  hasOrderedTender: boolean
}

const useHistoryPage = (pageToken: string): HistoryPageData => {
  const pageSize = DefaultOrderHistoryPageSize
  const { data } = useSuspenseQuery(
    listOrdered,
    {
      pageSize,
      pageToken,
    },
    {
      select: (response) => {
        return {
          pageCount: Math.ceil(
            response.totalSize / DefaultOrderHistoryPageSize,
          ),
          hasOrderedTender: response.tenderIds.length > 0,
        }
      },
    },
  )
  return {
    ...data,
    pageSize,
  }
}

const Layout: React.FC<{ urlParams: HistoryUrlParams }> = ({ urlParams }) => {
  const { t } = useTranslation('trader', {
    keyPrefix: 'history',
  })

  const { pageNumber, pageToken } = urlParams

  const { pageSize, pageCount, hasOrderedTender } = useHistoryPage(pageToken)
  const [_, setPageToken] = useHistoryPageToken()

  const onChangePage = (pageNumber: number): void => {
    setPageToken(pageNumber)
  }

  return (
    <SideMenuLayoutContentLayout
      header={
        <SideMenuLayoutBreadcrumb
          items={[{ label: t('page_title'), bold: true }]}
        />
      }
    >
      {!hasOrderedTender ? (
        <Flex height="100%">
          <EmptyLayout description={t('empty')} />
        </Flex>
      ) : (
        <Flex flexDirection="column" height="100%" padding={Spacing['6']}>
          <Flex flex={1}>
            <OrderedTenderSection pageSize={pageSize} pageToken={pageToken} />
          </Flex>
          {pageCount > 1 && (
            <Flex justifyContent="center" padding={Spacing['4']}>
              <ListPager
                current={pageNumber}
                goto={onChangePage}
                pages={pageCount}
              />
            </Flex>
          )}
        </Flex>
      )}
    </SideMenuLayoutContentLayout>
  )
}

const HistoryPage: React.FC = () => {
  const { urlParams } = useLoaderData() as HistoryData

  return (
    <Await errorElement={<ErrorLayout />} resolve={urlParams}>
      {(urlParams: HistoryUrlParams): JSX.Element => (
        <Layout urlParams={urlParams} />
      )}
    </Await>
  )
}

export default HistoryPage
