import i18next, { Resource } from 'i18next'
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import { DEFAULT_LANGUAGE } from './constants/language'
import enCommon from './locales/en/common.json'
import enOperator from './locales/en/operator.json'
import enTrader from './locales/en/trader.json'
import jaCommon from './locales/ja/common.json'
import jaOperator from './locales/ja/operator.json'
import jaTrader from './locales/ja/trader.json'

export type NameSpace = 'common' | 'operator' | 'trader'

const resources: Resource = {
  en: {
    common: enCommon,
    operator: enOperator,
    trader: enTrader,
  },
  ja: {
    common: jaCommon,
    operator: jaOperator,
    trader: jaTrader,
  },
}

type I18nProps = {
  nameSpace: NameSpace
  cookieDomain: string
  debug?: boolean
}

export function i18n({
  nameSpace,
  cookieDomain,
  debug = false,
}: I18nProps): typeof i18next {
  const detector = new I18nextBrowserLanguageDetector(null, {
    order: ['cookie', 'localStorage', 'navigator'],
    caches: ['cookie', 'localStorage'],
    cookieDomain,
  })

  void i18next
    .use(detector)
    .use(initReactI18next)
    .init({
      resources,
      fallbackLng: DEFAULT_LANGUAGE,
      ns: ['common', nameSpace],
      defaultNS: nameSpace,
      debug: debug,
      interpolation: {
        escapeValue: false,
      },
    })
  return i18next
}
