import {
  Link as ReactRouterLink,
  LinkProps as ReactRouterLinkProps,
} from 'react-router-dom'

import { LinkProps } from '@chakra-ui/react'

export type Navigate = {
  isExternalLink?: boolean
  path: string
}

type ExternalLinkProps = Pick<LinkProps, 'as' | 'rel' | 'href'>

type InternalLinkProps = {
  as: LinkProps['as']
  to: ReactRouterLinkProps['to']
}

export function convertToLinkProps({
  isExternalLink = false,
  path,
}: Navigate): ExternalLinkProps | InternalLinkProps {
  if (isExternalLink) {
    return {
      as: 'a',
      rel: 'noopener',
      href: path,
    }
  }

  return {
    as: ReactRouterLink,
    to: path,
  }
}

export function navigateExternalLink(path: string): void {
  const link = document.createElement('a')
  link.href = path
  link.rel = 'noopener'
  link.click()
}
