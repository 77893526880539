import { atom, useRecoilState } from 'recoil'

import { ToastId } from '@chakra-ui/react'

import { useToast as useCommonUiToast } from '../components/Toast/useToast'

const currentToastIds = atom<ToastId[]>({
  key: 'common-ui/currentToastIds',
  default: [],
})

type Props = {
  toastId: ToastId
}
type UseToastReturnType = ReturnType<typeof useCommonUiToast>

export function useToast(props: Props): UseToastReturnType {
  const [toasts, setToasts] = useRecoilState(currentToastIds)
  return useCommonUiToast({
    toastId: props.toastId,
    status: 'success',
    toastMessage: '',
    toasts,
    add: (toastId: ToastId): void => {
      setToasts((prev) => [...prev, toastId])
    },
    remove: (toastId: ToastId): void => {
      setToasts((prev) => prev.filter((t) => t !== toastId))
    },
  })
}
