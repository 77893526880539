import { Timestamp } from '@bufbuild/protobuf'
import { formatInTimeZone } from 'date-fns-tz'

import { DateFormat } from '../constants/date'

export function toTimestamp(date: Date | undefined): Timestamp | undefined {
  return date != null ? Timestamp.fromDate(date) : undefined
}

export function formatTimestamp(
  timestamp: Timestamp | undefined,
  dateFormat: DateFormat,
): string {
  return timestamp != null
    ? formatInTimeZone(timestamp.toDate(), 'Asia/Tokyo', dateFormat)
    : ''
}
