import { PartialMessage } from '@bufbuild/protobuf'
import { ConnectError } from '@connectrpc/connect'
import { useMutation } from '@connectrpc/connect-query'
import { UseMutationResult } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { downloadFile, useToast } from '@enechain/common-ui'
import { getAttachmentURL } from '@enechain/etender-proto/proto/etender/tender/v1/service-Service_connectquery'
import {
  GetAttachmentURLRequest,
  GetAttachmentURLResponse,
} from '@enechain/etender-proto/proto/etender/tender/v1/service_pb'

export type TenderAttachment = {
  id: string
  name: string
}

type Result = UseMutationResult<
  GetAttachmentURLResponse,
  ConnectError,
  PartialMessage<GetAttachmentURLRequest>
>

export function useDownloadAttachmentFile(fileName: string): Result {
  const { t } = useTranslation('trader', { keyPrefix: 'tender.list' })

  const { showToast } = useToast({
    toastId: 'pages/tender/hooks/useDownloadAttachmentFile',
  })

  return useMutation(getAttachmentURL, {
    onSuccess: async (data, _) => {
      const response = await fetch(data.url)
      const blob = await response.blob()
      downloadFile(fileName, blob)
    },
    onError: () => {
      showToast({
        status: 'error',
        toastMessage: t('error.failed_to_download_attachment'),
      })
    },
  })
}
