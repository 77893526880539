import * as React from 'react'

import {
  Button,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
} from '@chakra-ui/react'

import {
  BorderRadius,
  SemanticColors,
  Typography,
} from '@enechain/ecloud-designsystem'

import { Navigate, convertToLinkProps } from '../../utils/navigate'

type Props = {
  navigates: Array<{ title: string; navigate: Navigate }>
  children: React.ReactNode
}

const SubMenuPopover: React.FC<Props> = ({ navigates, children }) => {
  return (
    <Popover placement="right-start" trigger="hover">
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent
        maxWidth="280px"
        borderColor={SemanticColors.Border.midEmphasis}
        backgroundColor={SemanticColors.Surface.primary}
      >
        <PopoverBody flexDirection="column" gap={1} display="flex" paddingX={0}>
          {navigates.map(({ title, navigate }, index) => {
            return (
              <Button
                key={`${title}-${index}`}
                justifyContent="flex-start"
                width="100%"
                height="28px"
                textAlign="start"
                borderRadius={BorderRadius.none}
                _hover={{
                  backgroundColor: SemanticColors.Hover.lowEmphasis,
                  textDecoration: 'none',
                }}
                whiteSpace="unset"
                overflowWrap="break-word"
                backgroundColor="transparent"
                {...convertToLinkProps(navigate)}
                {...Typography.textSm}
              >
                {title}
              </Button>
            )
          })}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}
export default SubMenuPopover
