import * as React from 'react'

import { ChakraProps, useAccordionItemState } from '@chakra-ui/react'

import { SemanticColors } from '@enechain/ecloud-designsystem'
import {
  ChevronDownIcon,
  ChevronRightIcon,
  ChevronUpIcon,
} from '@enechain/etender-icons'

export type AccordionIconType = 'up' | 'down' | 'right'

const generateAccordionIcon = (
  accordionIconType: AccordionIconType,
): React.ElementType => {
  switch (accordionIconType) {
    case 'down':
      return ChevronDownIcon
    case 'right':
      return ChevronRightIcon
    case 'up':
      return ChevronUpIcon
    // TODO leftを後ほど追加する
  }
}

export type AccordionIconProps = {
  size: ChakraProps['width']
  openIcon?: AccordionIconType
  closeIcon?: AccordionIconType
}

const AccordionIcon: React.FC<AccordionIconProps> = ({
  size,
  openIcon = 'down',
  closeIcon = 'right',
}) => {
  const { isOpen } = useAccordionItemState()

  const IconComponent = isOpen
    ? generateAccordionIcon(openIcon)
    : generateAccordionIcon(closeIcon)

  return (
    <IconComponent
      width={size}
      height={size}
      color={SemanticColors.Object.midEmphasis}
    />
  )
}

export default React.memo(AccordionIcon)
