import * as React from 'react'
import { SVGProps, forwardRef } from 'react'

import { ButtonProps, IconButton, IconButtonProps } from '@chakra-ui/react'

import { SemanticColors } from '@enechain/ecloud-designsystem'

import { useActiveRoute } from '../../hooks/useActiveRoute'
import { Navigate, convertToLinkProps } from '../../utils/navigate'
import SubMenuPopover from './SubMenuPopover'

type SmallMenuItemProps = {
  navigate: Navigate
  subMenus?: Array<{
    title: string
    navigate: Navigate
  }>
  Icon: React.ComponentType<SVGProps<SVGSVGElement>>
  activePaths?: string | string[]
} & Pick<IconButtonProps, 'aria-label'>

const SmallMenuItem: React.FC<SmallMenuItemProps> = ({
  Icon,
  navigate,
  subMenus,
  activePaths,
  ...rest
}) => {
  const isActive = useActiveRoute(activePaths)

  // TODO: 解消する方法を探る
  // eslint-disable-next-line react/no-unstable-nested-components
  const Item = forwardRef<HTMLElement, ButtonProps>(function Item(props, ref) {
    return (
      <IconButton
        ref={ref}
        width="24px"
        _hover={{ backgroundColor: isActive ? '#e7e6f5' : '#efefef' }} // eSquareに合わせているのでカラーコード指定を許容する
        backgroundColor={isActive ? '#e7e6f5' : 'transparent'} // eSquareに合わせているのでカラーコード指定を許容する
        icon={
          <Icon
            width="24px"
            height="24px"
            color={isActive ? SemanticColors.Object.accentPrimary : '#8c8c8c'} // eSquareに合わせているのでカラーコード指定を許容する
          />
        }
        size="sm"
        {...convertToLinkProps(navigate)}
        {...rest}
        {...props}
      />
    )
  })

  if (subMenus == null || subMenus.length === 0) {
    return <Item />
  }

  return (
    <SubMenuPopover navigates={subMenus}>
      <Item />
    </SubMenuPopover>
  )
}

export default React.memo(SmallMenuItem)
