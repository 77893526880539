import * as React from 'react'
import type { SVGProps } from 'react'
import { memo } from 'react'

const SvgEditIcon: React.FC<SVGProps<SVGSVGElement>> = (
  props: SVGProps<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M15.809 4.48c.477-.455 1.03-.683 1.66-.683.63 0 1.182.228 1.66.683l.39.391c.456.478.684 1.03.684 1.66 0 .63-.228 1.183-.683 1.66l-6.413 6.413a2.544 2.544 0 0 1-1.01.619l-3.255.911c-.303.087-.564.011-.78-.228-.196-.195-.261-.445-.196-.748l.944-3.256c.108-.39.304-.727.586-1.009l6.413-6.413Zm2.18 1.107c-.347-.304-.705-.304-1.074 0l-.944.944L17.47 8.03l.944-.977c.304-.347.304-.705 0-1.074l-.423-.39Zm-7.682 6.771-.553 1.888 1.888-.553a.656.656 0 0 0 .358-.196l4.362-4.362-1.497-1.497L10.503 12a.656.656 0 0 0-.196.358Zm-.13-6.608c.478.043.738.304.781.781-.043.478-.303.738-.78.782H6.53c-.369 0-.672.13-.911.39-.26.239-.39.543-.39.912v8.854c0 .369.13.672.39.911.239.26.542.39.911.39h8.854c.37 0 .673-.13.912-.39.26-.238.39-.542.39-.911v-3.646c.044-.478.304-.738.782-.781.477.043.738.303.781.78v3.647c-.022.803-.304 1.475-.846 2.018-.543.543-1.216.825-2.019.846H6.531c-.803-.021-1.475-.303-2.018-.846-.543-.543-.825-1.215-.846-2.018V8.615c.021-.803.303-1.476.846-2.019.543-.542 1.215-.824 2.018-.846h3.646Z"
    />
  </svg>
)
const Memo = memo(SvgEditIcon)
export default Memo
