import type { SVGProps } from 'react'
import { memo } from 'react'

const SvgEtenderLogo = (props?: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 120 23"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.575 5.5C3.88 5.5 0 9.04 0 14.074s3.88 8.575 8.78 8.575c4.287 0 6.738-2.238 7.962-5.034h-3.88c-.612.747-1.836 1.862-4.082 1.862-2.654 0-4.794-1.862-5-3.915h13.167s.205-.747.205-1.49c0-5.035-3.88-8.575-8.575-8.575l-.002.003ZM3.78 12.768c.407-2.425 2.345-4.1 4.794-4.1 2.858 0 4.694 1.678 5.104 4.1H3.78ZM42.337 5.5c-4.694 0-8.575 3.54-8.575 8.574s3.88 8.575 8.78 8.575c4.287 0 6.738-2.238 7.962-5.034h-3.88c-.612.747-1.836 1.862-4.082 1.862-2.653 0-4.794-1.862-4.999-3.915h13.166s.205-.747.205-1.49c0-5.035-3.88-8.575-8.574-8.575l-.003.003Zm-4.794 7.268c.407-2.425 2.346-4.1 4.794-4.1 2.858 0 4.697 1.678 5.104 4.1h-9.898ZM100.851 5.5c-4.695 0-8.575 3.54-8.575 8.574s3.88 8.575 8.78 8.575c4.287 0 6.738-2.238 7.962-5.034h-3.88c-.612.747-1.836 1.862-4.082 1.862-2.653 0-4.794-1.862-5-3.915h13.167s.205-.747.205-1.49c0-5.035-3.88-8.575-8.574-8.575l-.003.003Zm-4.794 7.268c.407-2.425 2.346-4.1 4.794-4.1 2.858 0 4.697 1.678 5.104 4.1h-9.898ZM61.568 5.506c-4.492 0-7.582 1.783-7.582 1.783v15.173h3.356V9.612s1.927-.797 3.977-.797c2.715 0 4.138 1.657 4.138 4.026v9.618h3.356V12.77c0-4.475-2.878-7.268-7.245-7.268v.003ZM85.681 7.096c-1.414-1.02-3.2-1.6-5.218-1.6-4.785 0-8.575 3.541-8.575 8.575 0 5.034 3.79 8.575 8.575 8.575 2.152 0 4.1-.72 5.593-1.968l.387 1.78h2.591V0h-3.356v7.096h.003Zm-5.218 12.381c-2.592 0-5.034-2.237-5.034-5.406 0-3.168 2.442-5.406 5.034-5.406 2.591 0 4.984 2.238 4.984 5.406 0 3.169-2.393 5.406-4.984 5.406ZM112.667 7.289V22.46h3.356V9.611s1.927-.796 3.977-.796v-3.31c-4.358.065-7.333 1.78-7.333 1.78v.004ZM34.04 3.19V0H16.846v3.19h6.92V22.46h3.356V3.19h6.92Z"
      fill={props?.fill === undefined ? '#fff' : props?.fill}
    />
  </svg>
)
const Memo = memo(SvgEtenderLogo)
export default Memo
