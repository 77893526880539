// @generated by protoc-gen-connect-query v1.3.1 with parameter "target=js+dts"
// @generated from file etender/deal/v1/service.proto (package etender.deal.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { MethodKind } from "@bufbuild/protobuf";
import { CreateEmptyRequest, CreateEmptyResponse, DraftRecapRequest, DraftRecapResponse, GetDealFromTenderIdRequest, GetDealFromTenderIdResponse, GetDraftDealRequest, GetDraftDealResponse, GetRecapRequest, GetRecapResponse, ListMyDealsRequest, ListMyDealsResponse, PublishRecapRequest, PublishRecapResponse, PublishRequest, PublishResponse, UpdateDraftDealsRequest, UpdateDraftDealsResponse } from "./service_pb.js";

/**
 * 空の下書き状態の約定を作成する
 *
 * @generated from rpc etender.deal.v1.Service.CreateEmpty
 */
export const createEmpty = {
  localName: "createEmpty",
  name: "CreateEmpty",
  kind: MethodKind.Unary,
  I: CreateEmptyRequest,
  O: CreateEmptyResponse,
  service: {
    typeName: "etender.deal.v1.Service"
  }
};

/**
 * 下書き状態の約定を更新する
 *
 * @generated from rpc etender.deal.v1.Service.UpdateDraftDeals
 */
export const updateDraftDeals = {
  localName: "updateDraftDeals",
  name: "UpdateDraftDeals",
  kind: MethodKind.Unary,
  I: UpdateDraftDealsRequest,
  O: UpdateDraftDealsResponse,
  service: {
    typeName: "etender.deal.v1.Service"
  }
};

/**
 * 下書き状態の約定を取得する
 *
 * @generated from rpc etender.deal.v1.Service.GetDraftDeal
 */
export const getDraftDeal = {
  localName: "getDraftDeal",
  name: "GetDraftDeal",
  kind: MethodKind.Unary,
  I: GetDraftDealRequest,
  O: GetDraftDealResponse,
  service: {
    typeName: "etender.deal.v1.Service"
  }
};

/**
 * 下書き状態の約定を公開する
 *
 * @generated from rpc etender.deal.v1.Service.Publish
 */
export const publish = {
  localName: "publish",
  name: "Publish",
  kind: MethodKind.Unary,
  I: PublishRequest,
  O: PublishResponse,
  service: {
    typeName: "etender.deal.v1.Service"
  }
};

/**
 * Tenderに紐付く公開されている約定を取得する
 *
 * @generated from rpc etender.deal.v1.Service.GetDealFromTenderId
 */
export const getDealFromTenderId = {
  localName: "getDealFromTenderId",
  name: "GetDealFromTenderId",
  kind: MethodKind.Unary,
  I: GetDealFromTenderIdRequest,
  O: GetDealFromTenderIdResponse,
  service: {
    typeName: "etender.deal.v1.Service"
  }
};

/**
 * 自分の約定を一覧する
 *
 * @generated from rpc etender.deal.v1.Service.ListMyDeals
 */
export const listMyDeals = {
  localName: "listMyDeals",
  name: "ListMyDeals",
  kind: MethodKind.Unary,
  I: ListMyDealsRequest,
  O: ListMyDealsResponse,
  service: {
    typeName: "etender.deal.v1.Service"
  }
};

/**
 * 約定のRecap関連情報を取得する
 *
 * @generated from rpc etender.deal.v1.Service.GetRecap
 */
export const getRecap = {
  localName: "getRecap",
  name: "GetRecap",
  kind: MethodKind.Unary,
  I: GetRecapRequest,
  O: GetRecapResponse,
  service: {
    typeName: "etender.deal.v1.Service"
  }
};

/**
 * 約定のRecapに記載する追加情報を保存する
 *
 * @generated from rpc etender.deal.v1.Service.DraftRecap
 */
export const draftRecap = {
  localName: "draftRecap",
  name: "DraftRecap",
  kind: MethodKind.Unary,
  I: DraftRecapRequest,
  O: DraftRecapResponse,
  service: {
    typeName: "etender.deal.v1.Service"
  }
};

/**
 * 約定のRecapに記載する追加情報を保存する
 *
 * @generated from rpc etender.deal.v1.Service.PublishRecap
 */
export const publishRecap = {
  localName: "publishRecap",
  name: "PublishRecap",
  kind: MethodKind.Unary,
  I: PublishRecapRequest,
  O: PublishRecapResponse,
  service: {
    typeName: "etender.deal.v1.Service"
  }
};
