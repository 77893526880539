import * as React from 'react'
import { useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import { SemanticColors, Tag } from '@enechain/ecloud-designsystem'

type Props = {
  type: 'sleever' | 'owner'
}

const DealTypeTag: React.FC<Props> = ({ type }) => {
  const { t } = useTranslation('trader', {
    keyPrefix: 'deal_type',
  })

  const tag = useMemo(() => {
    switch (type) {
      case 'sleever':
        return {
          label: t('sleever'),
          color: SemanticColors.Text.midEmphasis,
          borderColor: SemanticColors.Border.neutralMid,
        }
      case 'owner':
        return {
          label: t('owner'),
          color: SemanticColors.Text.midEmphasis,
          borderColor: SemanticColors.Border.neutralMid,
        }
      default:
        return undefined
    }
  }, [type, t])

  if (tag == null) {
    return null
  }

  return (
    <Tag
      height="28px"
      color={tag.color}
      fontWeight="bold"
      borderWidth="1px"
      borderColor={tag.borderColor}
      backgroundColor={SemanticColors.Surface.primary}
    >
      {tag.label}
    </Tag>
  )
}

export default React.memo(DealTypeTag)
