import * as React from 'react'

import { PartialMessage } from '@bufbuild/protobuf'
import { useTranslation } from 'react-i18next'

import { Flex, FormControl } from '@chakra-ui/react'

import { DebouncedInput, useToast } from '@enechain/common-ui'
import { Label, Spacing, Term } from '@enechain/ecloud-designsystem'
import { OrderAdditionalField } from '@enechain/etender-proto/proto/bff/v1/order_model_pb'
import { Contents } from '@enechain/etender-proto/proto/etender/order/v1/model_pb'

import { useEditOrderContents } from '~/pages/order/hooks/useEditOrderContents'
import { useEditingOrder } from '~/pages/order/hooks/useEditingOrder'
import { useOrderValidationErrorMessage } from '~/pages/order/hooks/useOrderValidationErrorMessage'

type EclearFormData = {
  contents: Contents
  traderId: OrderAdditionalField
  keyword: OrderAdditionalField
}

const useEclearFormData = (orderId: string): EclearFormData | undefined => {
  const { data } = useEditingOrder(orderId, (response) => {
    const contents = response.editingOrder?.contents
    const additionalFields = response.additionalFields
    const traderIdField = additionalFields?.eclearTraderId
    const keywordField = additionalFields?.eclearKeyword

    if (contents == null || traderIdField == null || keywordField == null) {
      return undefined
    }

    return {
      contents,
      traderId: traderIdField,
      keyword: keywordField,
    }
  })
  return data
}

type Props = {
  orderId: string
}

const EclearForm: React.FC<Props> = ({ orderId }) => {
  const { t } = useTranslation('trader', {
    keyPrefix: 'order.additional_fields_section.eclear',
  })

  const formData = useEclearFormData(orderId)
  const mutation = useEditOrderContents()

  const { showToast } = useToast({
    toastId: 'pages/order/components/SleeveSection/EclearForm',
  })

  const traderIdErrorMessage = useOrderValidationErrorMessage(
    formData?.traderId,
  )
  const keywordErrorMessage = useOrderValidationErrorMessage(formData?.keyword)

  const editContents = (contents: PartialMessage<Contents>): void => {
    mutation.mutate(
      {
        orderId,
        ...contents,
      },
      {
        onError: () => {
          showToast({
            status: 'error',
            toastMessage: t('failed_to_edit'),
          })
        },
      },
    )
  }

  const onChangeTraderId = (traderId: string): void => {
    if (formData == null) {
      return
    }

    editContents({
      ...formData.contents,
      eclearTraderId: traderId,
    })
  }

  const onChangeKeyword = (keyword: string): void => {
    if (formData == null) {
      return
    }
    editContents({
      ...formData.contents,
      eclearKeyword: keyword,
    })
  }

  if (formData == null) {
    return null
  }

  return (
    <Flex flexDirection="column" gap={Spacing['6']}>
      <FormControl isInvalid={!formData.traderId.isValid}>
        <Term>
          <Label required>{t('eclear_trader_id.label')}</Label>
          <DebouncedInput
            width="50%"
            error={traderIdErrorMessage}
            initialValue={formData.traderId.value}
            milliseconds={500}
            onChange={onChangeTraderId}
            placeholder={t('eclear_trader_id.placeholder')}
          />
        </Term>
      </FormControl>
      <FormControl isInvalid={!formData.traderId.isValid}>
        <Term>
          <Label required>{t('eclear_keyword.label')}</Label>
          <DebouncedInput
            width="50%"
            error={keywordErrorMessage}
            initialValue={formData.keyword.value}
            milliseconds={500}
            onChange={onChangeKeyword}
            placeholder={t('eclear_keyword.placeholder')}
          />
        </Term>
      </FormControl>
    </Flex>
  )
}

export default React.memo(EclearForm)
