import * as React from 'react'

import { useTranslation } from 'react-i18next'

import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  UseDisclosureReturn,
} from '@chakra-ui/react'

import { useToast } from '@enechain/common-ui'
import {
  PrimaryButton,
  SemanticColors,
  Spacing,
  TertiaryButton,
  Typography,
} from '@enechain/ecloud-designsystem'

import { useDiscardEditingOrder } from '~/pages/order/hooks/useDiscardEditingOrder'

type Props = {
  orderId: string
  disclosure: UseDisclosureReturn
  onDiscarded?: () => void
}

const EditingOrderModal: React.FC<Props> = ({
  orderId,
  disclosure,
  onDiscarded,
}) => {
  const { t } = useTranslation('trader', {
    keyPrefix: 'order',
  })

  const { showToast } = useToast({
    toastId: 'pages/order/EditingOrderModal',
  })

  const mutation = useDiscardEditingOrder()

  const onClickRestart = (): void => {
    disclosure.onClose()
  }

  const onClickDiscard = (): void => {
    mutation.mutate(
      { orderId },
      {
        onSuccess: (): void => {
          onDiscarded?.()
          disclosure.onClose()
        },
        onError: (): void => {
          showToast({
            status: 'error',
            toastMessage: t('failed_to_cancel'),
          })
        },
      },
    )
  }

  return (
    // MEMO: Design System v0.8.49まで上げないとClose Buttonを非表示にできなかったのでchakra-uiのModalをそのまま使っている
    <Modal
      closeOnEsc={false}
      closeOnOverlayClick={false}
      isOpen={disclosure.isOpen}
      onClose={disclosure.onClose}
      size="lg"
    >
      <ModalOverlay />
      <ModalContent backgroundColor={SemanticColors.Surface.primary}>
        <ModalHeader>{t('editing_order_modal.title')}</ModalHeader>
        <ModalBody>
          <Text {...Typography.textSm}>
            {t('editing_order_modal.description')}
          </Text>
        </ModalBody>
        <ModalFooter gap={Spacing['2']}>
          <TertiaryButton onClick={onClickDiscard}>
            {t('editing_order_modal.discard')}
          </TertiaryButton>
          <PrimaryButton onClick={onClickRestart}>
            {t('editing_order_modal.resume')}
          </PrimaryButton>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default React.memo(EditingOrderModal)
