import * as React from 'react'

import {
  CalendarCheckIcon,
  HandshakeIcon,
  NegativeHandshakeIcon,
} from '@enechain/etender-icons'

type Props = {
  status:
    | 'bidding_period'
    | 'in_processing'
    | 'filled'
    | 'partially_filled'
    | 'unfilled'
}
const DealStatusIcon: React.FC<Props> = ({ status }) => {
  const iconStyle = { width: '15px', height: '15px' }
  switch (status) {
    case 'bidding_period':
      return <CalendarCheckIcon {...iconStyle} />
    case 'in_processing':
      return <CalendarCheckIcon {...iconStyle} />
    case 'filled':
      return <HandshakeIcon {...iconStyle} />
    case 'partially_filled':
      return <HandshakeIcon {...iconStyle} />
    case 'unfilled':
      return <NegativeHandshakeIcon {...iconStyle} />
  }
}

export default React.memo(DealStatusIcon)
