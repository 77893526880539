import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'

import { isIgnoreAuth0Message } from '@enechain/common-ui'

if (
  import.meta.env.VITE_DD_APPLICATION_ID != null &&
  import.meta.env.VITE_DD_CLIENT_TOKEN != null &&
  import.meta.env.VITE_DD_ENV != null &&
  import.meta.env.VITE_DD_ENV !== 'etender-frontend-trader-local'
) {
  datadogRum.init({
    applicationId: import.meta.env.VITE_DD_APPLICATION_ID,
    clientToken: import.meta.env.VITE_DD_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'etender-frontend-trader',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    env: import.meta.env.VITE_DD_ENV,
    defaultPrivacyLevel: [
      'etender-frontend-trader-dev',
      'etender-frontend-trader-stg',
    ].includes(import.meta.env.VITE_DD_ENV)
      ? 'allow'
      : 'mask-user-input',
    allowedTracingUrls: [/https:\/\/.*\.enechain\.com/],
    beforeSend: (event) => {
      const errorMessage =
        event.type === 'error' ? event.error.message : undefined
      return !isIgnoreAuth0Message(errorMessage)
    },
  })
  datadogRum.startSessionReplayRecording()

  datadogLogs.init({
    clientToken: import.meta.env.VITE_DD_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'etender-frontend-trader',
    forwardErrorsToLogs: true,
    forwardConsoleLogs: ['error', 'warn'],
    sessionSampleRate: 100,
    env: import.meta.env.VITE_DD_ENV,
    beforeSend: (log) => {
      const errorMessage =
        log.error != null ? String(log.error.message) : undefined
      return !(
        isIgnoreAuth0Message(log.message) || isIgnoreAuth0Message(errorMessage)
      )
    },
  })
}
