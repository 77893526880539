import * as React from 'react'

import { SingleValue } from 'chakra-react-select'

import Select, { SelectOption, SelectProps } from './index'

export type SingleSelectProps = {
  selectedOption: SelectOption | undefined
  onOptionSelected?: (option: SelectOption) => void
  onOptionRemoved?: () => void
} & SelectProps<false>

const SingleSelect: React.FC<SingleSelectProps> = ({
  selectedOption,
  onOptionSelected,
  onOptionRemoved,
  ...rest
}) => {
  const onChange = (inputValue: SingleValue<SelectOption>): void => {
    if (inputValue != null) {
      if (onOptionSelected != null) {
        onOptionSelected(inputValue)
      }
    } else {
      if (onOptionRemoved != null) {
        onOptionRemoved()
      }
    }
  }

  return (
    <Select<false>
      isMulti={false}
      onChange={onChange}
      value={selectedOption ?? null}
      {...rest}
    />
  )
}

export default React.memo(SingleSelect)
