// @generated by protoc-gen-connect-query v1.3.1 with parameter "target=js+dts"
// @generated from file etender/bid/v1/service.proto (package etender.bid.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { MethodKind } from "@bufbuild/protobuf";
import { CreateEmptyRequest, CreateEmptyResponse, DeleteRequest, DeleteResponse, EditRequest, EditResponse } from "./service_pb.js";

/**
 * 空の応札を作成する
 *
 * @generated from rpc etender.bid.v1.Service.CreateEmpty
 */
export const createEmpty = {
  localName: "createEmpty",
  name: "CreateEmpty",
  kind: MethodKind.Unary,
  I: CreateEmptyRequest,
  O: CreateEmptyResponse,
  service: {
    typeName: "etender.bid.v1.Service"
  }
};

/**
 * 応札を編集する
 *
 * @generated from rpc etender.bid.v1.Service.Edit
 */
export const edit = {
  localName: "edit",
  name: "Edit",
  kind: MethodKind.Unary,
  I: EditRequest,
  O: EditResponse,
  service: {
    typeName: "etender.bid.v1.Service"
  }
};

/**
 * 応札を削除する
 *
 * @generated from rpc etender.bid.v1.Service.Delete
 */
export const delete$ = {
  localName: "delete",
  name: "Delete",
  kind: MethodKind.Unary,
  I: DeleteRequest,
  O: DeleteResponse,
  service: {
    typeName: "etender.bid.v1.Service"
  }
};
