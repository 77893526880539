import { PartialMessage } from '@bufbuild/protobuf'
import { createConnectQueryKey, useMutation } from '@connectrpc/connect-query'
import { UseMutationResult, useQueryClient } from '@tanstack/react-query'

import { getEditingOrder } from '@enechain/etender-proto/proto/bff/v1/order_service-OrderService_connectquery'
import { createEmpty } from '@enechain/etender-proto/proto/etender/bid/v1/service-Service_connectquery'
import {
  CreateEmptyRequest,
  CreateEmptyResponse,
} from '@enechain/etender-proto/proto/etender/bid/v1/service_pb'

type Result = UseMutationResult<
  CreateEmptyResponse,
  Error,
  PartialMessage<CreateEmptyRequest>
>

export function useAddBid(): Result {
  const queryClient = useQueryClient()
  return useMutation(createEmpty, {
    onSuccess: async (_, variables) => {
      await queryClient.invalidateQueries({
        queryKey: createConnectQueryKey(getEditingOrder, {
          orderId: variables.orderId,
        }),
      })
    },
  })
}
