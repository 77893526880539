import { Transport } from '@connectrpc/connect'
import { QueryClient } from '@tanstack/react-query'
import { Navigate, createBrowserRouter } from 'react-router-dom'

import { ErrorLayout, NotFoundLayout } from '@enechain/common-ui'

import MainLayout from '~/layouts/MainLayout'
import HistoryPage from '~/pages/history'
import HistoryDetailPage from '~/pages/history-detail'
import { historyDetailLoader } from '~/pages/history-detail/loader'
import { historyLoader } from '~/pages/history/loader'
import OrderPage from '~/pages/order'
import OrderConfirmPage from '~/pages/order-confirm'
import { orderConfirmLoader } from '~/pages/order-confirm/loader'
import { orderLoader } from '~/pages/order/loader'
import TenderPage, { tenderLoader } from '~/pages/tender'

import { routePaths } from './routePaths'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const router = (transport: Transport, queryClient: QueryClient) =>
  createBrowserRouter([
    {
      element: <MainLayout />,
      errorElement: <ErrorLayout />,
      children: [
        {
          path: routePaths.root(),
          element: <Navigate to={routePaths.tender()} />,
        },
        {
          path: routePaths.tender(),
          loader: tenderLoader(transport, queryClient),
          errorElement: <ErrorLayout />,
          element: <TenderPage />,
        },
        {
          path: routePaths.order(),
          loader: orderLoader(transport, queryClient),
          errorElement: <ErrorLayout />,
          element: <OrderPage />,
        },
        {
          path: routePaths.orderConfirm(),
          loader: orderConfirmLoader(transport, queryClient),
          errorElement: <ErrorLayout />,
          element: <OrderConfirmPage />,
        },
        {
          path: routePaths.history(),
          loader: historyLoader(),
          errorElement: <ErrorLayout />,
          element: <HistoryPage />,
        },
        {
          path: routePaths.historyDetail(),
          loader: historyDetailLoader(transport, queryClient),
          errorElement: <ErrorLayout />,
          element: <HistoryDetailPage />,
        },
        {
          path: '*',
          element: <NotFoundLayout />,
        },
      ],
    },
  ])
