// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file etender/order/v1/model.proto (package etender.order.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { Bid, EditingBid } from "../../bid/v1/model_pb.js";

/**
 * スリーブ種別
 *
 * @generated from enum etender.order.v1.SleeveType
 */
export const SleeveType = /*@__PURE__*/ proto3.makeEnum(
  "etender.order.v1.SleeveType",
  [
    {no: 0, name: "SLEEVE_TYPE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "SLEEVE_TYPE_NONE", localName: "NONE"},
    {no: 2, name: "SLEEVE_TYPE_EEX", localName: "EEX"},
    {no: 3, name: "SLEEVE_TYPE_TOCOM", localName: "TOCOM"},
    {no: 4, name: "SLEEVE_TYPE_BILATERAL", localName: "BILATERAL"},
  ],
);

/**
 * *
 * 注文のバリデーションエラーが発生したフィールド
 *
 * @generated from enum etender.order.v1.ValidationErrorField
 */
export const ValidationErrorField = /*@__PURE__*/ proto3.makeEnum(
  "etender.order.v1.ValidationErrorField",
  [
    {no: 0, name: "VALIDATION_ERROR_FIELD_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "VALIDATION_ERROR_FIELD_EEX_TRADER_NAME", localName: "EEX_TRADER_NAME"},
    {no: 2, name: "VALIDATION_ERROR_FIELD_EEX_CLEARING_BANK", localName: "EEX_CLEARING_BANK"},
    {no: 3, name: "VALIDATION_ERROR_FIELD_EEX_CLEARING_ACCOUNT", localName: "EEX_CLEARING_ACCOUNT"},
    {no: 4, name: "VALIDATION_ERROR_FIELD_TOCOM_TRADER_NAME", localName: "TOCOM_TRADER_NAME"},
    {no: 5, name: "VALIDATION_ERROR_FIELD_TOCOM_CLEARING_BANK", localName: "TOCOM_CLEARING_BANK"},
    {no: 6, name: "VALIDATION_ERROR_FIELD_TOCOM_CLEARING_ACCOUNT", localName: "TOCOM_CLEARING_ACCOUNT"},
    {no: 7, name: "VALIDATION_ERROR_FIELD_ECLEAR_TRADER_ID", localName: "ECLEAR_TRADER_ID"},
    {no: 8, name: "VALIDATION_ERROR_FIELD_ECLEAR_KEYWORD", localName: "ECLEAR_KEYWORD"},
  ],
);

/**
 * *
 * 注文のバリデーションエラーの種類
 *
 * @generated from enum etender.order.v1.ValidationErrorType
 */
export const ValidationErrorType = /*@__PURE__*/ proto3.makeEnum(
  "etender.order.v1.ValidationErrorType",
  [
    {no: 0, name: "VALIDATION_ERROR_TYPE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "VALIDATION_ERROR_TYPE_REQUIRED", localName: "REQUIRED"},
    {no: 2, name: "VALIDATION_ERROR_TYPE_INVALID_FORMAT", localName: "INVALID_FORMAT"},
  ],
);

/**
 * 注文のステータス
 *
 * @generated from enum etender.order.v1.Status
 */
export const Status = /*@__PURE__*/ proto3.makeEnum(
  "etender.order.v1.Status",
  [
    {no: 0, name: "STATUS_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "STATUS_DRAFT", localName: "DRAFT"},
    {no: 2, name: "STATUS_ENABLED", localName: "ENABLED"},
    {no: 3, name: "STATUS_DISABLED", localName: "DISABLED"},
    {no: 4, name: "STATUS_PROCESSING", localName: "PROCESSING"},
    {no: 5, name: "STATUS_PROCESSED", localName: "PROCESSED"},
  ],
);

/**
 * 注文不可の理由種別
 *
 * @generated from enum etender.order.v1.SubmitRejectReasonType
 */
export const SubmitRejectReasonType = /*@__PURE__*/ proto3.makeEnum(
  "etender.order.v1.SubmitRejectReasonType",
  [
    {no: 0, name: "SUBMIT_REJECT_REASON_TYPE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "SUBMIT_REJECT_REASON_TYPE_REQUIRED", localName: "REQUIRED"},
    {no: 2, name: "SUBMIT_REJECT_REASON_TYPE_INVALID_FORMAT", localName: "INVALID_FORMAT"},
    {no: 3, name: "SUBMIT_REJECT_REASON_TYPE_UNAVAILABLE", localName: "UNAVAILABLE"},
    {no: 4, name: "SUBMIT_REJECT_REASON_TYPE_UNMODIFIABLE", localName: "UNMODIFIABLE"},
    {no: 5, name: "SUBMIT_REJECT_REASON_TYPE_TOO_FEW_ROWS", localName: "TOO_FEW_ROWS"},
    {no: 6, name: "SUBMIT_REJECT_REASON_TYPE_TOO_MANY_ROWS", localName: "TOO_MANY_ROWS"},
  ],
);

/**
 * 注文
 *
 * @generated from message etender.order.v1.Order
 */
export const Order = /*@__PURE__*/ proto3.makeMessageType(
  "etender.order.v1.Order",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created_at", kind: "message", T: Timestamp },
    { no: 3, name: "updated_at", kind: "message", T: Timestamp },
    { no: 5, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "organization_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "tender_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "status", kind: "enum", T: proto3.getEnumType(Status) },
    { no: 9, name: "contents", kind: "message", T: Contents },
    { no: 10, name: "applied_at", kind: "message", T: Timestamp, opt: true },
  ],
);

/**
 * 注文と応札
 *
 * @generated from message etender.order.v1.OrderWithBids
 */
export const OrderWithBids = /*@__PURE__*/ proto3.makeMessageType(
  "etender.order.v1.OrderWithBids",
  () => [
    { no: 1, name: "order", kind: "message", T: Order },
    { no: 2, name: "bids", kind: "message", T: Bid, repeated: true },
  ],
);

/**
 * 編集中の注文
 *
 * @generated from message etender.order.v1.EditingOrder
 */
export const EditingOrder = /*@__PURE__*/ proto3.makeMessageType(
  "etender.order.v1.EditingOrder",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created_at", kind: "message", T: Timestamp },
    { no: 3, name: "updated_at", kind: "message", T: Timestamp },
    { no: 5, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "organization_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "tender_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "bids", kind: "message", T: EditingBid, repeated: true },
    { no: 9, name: "status", kind: "enum", T: proto3.getEnumType(Status) },
    { no: 10, name: "contents", kind: "message", T: Contents },
    { no: 11, name: "is_editing", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 12, name: "validation_result", kind: "message", T: ValidationResult },
  ],
);

/**
 * 注文の内容
 *
 * @generated from message etender.order.v1.Contents
 */
export const Contents = /*@__PURE__*/ proto3.makeMessageType(
  "etender.order.v1.Contents",
  () => [
    { no: 1, name: "sleeve_type", kind: "enum", T: proto3.getEnumType(SleeveType) },
    { no: 2, name: "eex_trader_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "eex_clearing_bank", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "eex_clearing_account", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "tocom_trader_name", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "tocom_clearing_bank", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 7, name: "tocom_clearing_account", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 8, name: "eclear_trader_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 9, name: "eclear_keyword", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ],
);

/**
 * *
 * 注文のバリデーション結果
 *
 * @generated from message etender.order.v1.ValidationResult
 */
export const ValidationResult = /*@__PURE__*/ proto3.makeMessageType(
  "etender.order.v1.ValidationResult",
  () => [
    { no: 1, name: "is_valid", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "errors", kind: "message", T: ValidationError, repeated: true },
  ],
);

/**
 * *
 * 注文のバリデーションエラー
 *
 * @generated from message etender.order.v1.ValidationError
 */
export const ValidationError = /*@__PURE__*/ proto3.makeMessageType(
  "etender.order.v1.ValidationError",
  () => [
    { no: 1, name: "field", kind: "enum", T: proto3.getEnumType(ValidationErrorField) },
    { no: 2, name: "type", kind: "enum", T: proto3.getEnumType(ValidationErrorType) },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ],
);

/**
 * 注文票の検証結果
 *
 * @generated from message etender.order.v1.SubmitValidationResult
 */
export const SubmitValidationResult = /*@__PURE__*/ proto3.makeMessageType(
  "etender.order.v1.SubmitValidationResult",
  () => [
    { no: 1, name: "acceptable_bids", kind: "message", T: Bid, repeated: true },
    { no: 2, name: "reject_reasons", kind: "message", T: SubmitValidationResult_RejectReason, repeated: true },
  ],
);

/**
 * 注文不可の理由
 *
 * @generated from message etender.order.v1.SubmitValidationResult.RejectReason
 */
export const SubmitValidationResult_RejectReason = /*@__PURE__*/ proto3.makeMessageType(
  "etender.order.v1.SubmitValidationResult.RejectReason",
  () => [
    { no: 1, name: "no", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "type", kind: "enum", T: proto3.getEnumType(SubmitRejectReasonType) },
    { no: 3, name: "column", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
  {localName: "SubmitValidationResult_RejectReason"},
);

