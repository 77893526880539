import * as React from 'react'

import { getShortenUUID } from '@enechain/common-ui'
import { FlexTableCell, FlexTableRow } from '@enechain/ecloud-designsystem'

import { BidConfirmColumnKey } from '~/pages/order-confirm/components/OrderConfirmSection/BidConfirmTable/BidConfirmColumnStyle'
import { useEditingOrder } from '~/pages/order/hooks/useEditingOrder'

type BidConfirmTableBodyData = {
  bidId: {
    shortenId: string
    id: string
  }
  volume: string
  unitPrice: string
}

const useBidConfirmTableBodyData = (
  orderId: string,
  bidId: string,
): BidConfirmTableBodyData | undefined => {
  const { data } = useEditingOrder(orderId, (response) => {
    const bid = response.editingOrder?.bids?.find((bid) => bid.id === bidId)
    if (bid == null) {
      return undefined
    }

    return {
      bidId: {
        shortenId: getShortenUUID(bid.id),
        id: bid.id ?? '',
      },
      volume: bid.volume ?? '',
      unitPrice: bid.unitPrice ?? '',
    }
  })

  if (data == null) {
    return undefined
  }

  return data
}

type Props = {
  tenderTermsId: string
  orderId: string
  bidId: string
}

const BidConfirmTableBodyRow: React.FC<Props> = ({ orderId, bidId }) => {
  const data = useBidConfirmTableBodyData(orderId, bidId)

  if (data == null) {
    return null
  }

  return (
    <FlexTableRow>
      <FlexTableCell<BidConfirmColumnKey> columnKey="accordionSpace" />
      <FlexTableCell<BidConfirmColumnKey>
        columnKey="bidId"
        contents={{ text: data.bidId.shortenId, fullText: data.bidId.id }}
      />
      <FlexTableCell<BidConfirmColumnKey>
        columnKey="unitPrice"
        contents={{ text: data.unitPrice }}
      />
      <FlexTableCell<BidConfirmColumnKey>
        columnKey="volume"
        contents={{ text: data.volume }}
      />
    </FlexTableRow>
  )
}

export default React.memo(BidConfirmTableBodyRow)
