import * as React from 'react'

import { useTranslation } from 'react-i18next'

import { Flex, useAccordionItemState } from '@chakra-ui/react'

import { useToast } from '@enechain/common-ui'
import { SemanticColors, TertiaryButton } from '@enechain/ecloud-designsystem'
import { PlusIcon } from '@enechain/etender-icons'

import { useAddBid } from '~/pages/order/hooks/useAddBid'

type Props = {
  orderId: string
  tenderTermsId: string
}

const BidAddSection: React.FC<Props> = ({ orderId, tenderTermsId }) => {
  const { t } = useTranslation('trader', {
    keyPrefix: 'order.bid_add_section',
  })

  const { onOpen } = useAccordionItemState()

  const addMutation = useAddBid()

  const { showToast } = useToast({
    toastId: 'pages/order/components/BidAddSection',
  })

  const onClickAdd = (): void => {
    addMutation.mutate(
      { orderId, tenderTermsId },
      {
        onSuccess: () => {
          onOpen()
        },
        onError: () => {
          showToast({
            status: 'error',
            toastMessage: t('failed_to_add'),
          })
        },
      },
    )
  }

  return (
    <Flex justifyContent="center" flexDirection="row" width="100%">
      <TertiaryButton
        height="40px"
        leftIcon={
          <PlusIcon
            width="24px"
            height="24px"
            color={SemanticColors.Object.highEmphasis}
          />
        }
        onClick={onClickAdd}
      >
        {t('add_bid')}
      </TertiaryButton>
    </Flex>
  )
}

export default React.memo(BidAddSection)
