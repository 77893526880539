export type TenderColumnKey =
  | 'accordion'
  | 'tenderId'
  | 'ownerOrganization'
  | 'name'
  | 'openAt'
  | 'closeAt'
  | 'ultimateBuyerCloseAt'
  | 'awardAnnounceAt'
  | 'attachments'
  | 'action'

export const tenderColumnStyles = {
  accordion: {
    alignment: 'left',
    width: {
      type: 'fixed',
      width: '36px',
    },
  },
  tenderId: {
    alignment: 'left',
    width: {
      type: 'ratio',
      ratio: 0.8,
    },
  },
  ownerOrganization: {
    alignment: 'left',
    width: {
      type: 'ratio',
      ratio: 1,
    },
  },
  name: {
    alignment: 'left',
    width: {
      type: 'ratio',
      ratio: 1,
    },
  },
  openAt: {
    alignment: 'left',
    width: {
      type: 'ratio',
      ratio: 1,
    },
  },
  closeAt: {
    alignment: 'left',
    width: {
      type: 'ratio',
      ratio: 1,
    },
  },
  ultimateBuyerCloseAt: {
    alignment: 'left',
    width: {
      type: 'ratio',
      ratio: 1,
    },
  },
  awardAnnounceAt: {
    alignment: 'left',
    width: {
      type: 'ratio',
      ratio: 1,
    },
  },
  attachments: {
    alignment: 'left',
    width: {
      type: 'ratio',
      ratio: 1,
    },
  },
  action: {
    alignment: 'left',
    width: {
      type: 'fixed',
      width: '96px',
    },
  },
} as const
