import * as React from 'react'

import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

import { Stack, Text } from '@chakra-ui/react'

import { Link, SemanticColors, Typography } from '@enechain/ecloud-designsystem'

const NotFoundLayout = (): React.ReactElement => {
  const { t } = useTranslation()

  return (
    <Stack padding={4} spacing={2}>
      <Text {...Typography.headerLg}>{t('common:label.not_found')}</Text>
      <Link as={RouterLink} color={SemanticColors.Text.link} to="/">
        {t('common:action.back_home')}
      </Link>
    </Stack>
  )
}

export default NotFoundLayout
