import * as React from 'react'

import { useTranslation } from 'react-i18next'

import { Flex, Text } from '@chakra-ui/react'

import { Badge } from '@enechain/ecloud-designsystem'
import { Status } from '@enechain/ecloud-designsystem/dist/src/components/Badge/constants'

import DealStatusIcon from './DealStatusIcon'

export type Props = {
  status:
    | 'bidding_period'
    | 'in_processing'
    | 'filled'
    | 'partially_filled'
    | 'unfilled'
}

const DealStatusLabel: React.FC<Props> = ({ status }) => {
  const { t } = useTranslation('common', {
    keyPrefix: 'deal_status',
  })

  const getBackgroundColor = (): Status => {
    switch (status) {
      case 'bidding_period':
        return 'neutral'
      case 'in_processing':
        return 'neutral'
      case 'filled':
        return 'positive'
      case 'partially_filled':
        return 'positive'
      case 'unfilled':
        return 'negative'
    }
  }

  return (
    <Badge status={getBackgroundColor()}>
      <Flex alignItems="center">
        <DealStatusIcon status={status} />
        <Text marginLeft={1} fontSize={14}>
          {t(`label.${status}`)}
        </Text>
      </Flex>
    </Badge>
  )
}

export default React.memo(DealStatusLabel)
