import * as React from 'react'

import { Divider as ChakraDivider } from '@chakra-ui/react'

import { PrimitiveColors } from '@enechain/ecloud-designsystem'

const Divider: React.FC = () => {
  return <ChakraDivider opacity={1} borderColor={PrimitiveColors.gray['400']} />
}

export default Divider
