import * as React from 'react'

import { useQuery } from '@connectrpc/connect-query'
import { useTranslation } from 'react-i18next'
import { matchPath, useLocation, useNavigate } from 'react-router-dom'

import { imageToBase64 } from '@enechain/common-ui'
import {
  SideMenuLayoutMenuItem,
  SideMenuLayoutOrganization,
} from '@enechain/ecloud-designsystem'
import {
  FaClockRotateLeftRegular,
  FaRightLeftSolid,
} from '@enechain/ecloud-icons'
import { getMyProfile } from '@enechain/etender-proto/proto/bff/v1/user_service-UserService_connectquery'

import { routePaths } from '~/routes/routePaths'

export const TopMenu: React.FC = () => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { data: userProfile } = useQuery(getMyProfile)

  return (
    <>
      <SideMenuLayoutOrganization
        name={userProfile?.profile?.name ?? ''}
        src={
          userProfile?.organizationImage != null
            ? imageToBase64(userProfile?.organizationImage)
            : undefined
        }
      />
      <SideMenuLayoutMenuItem
        IconLeft={FaRightLeftSolid}
        isActive={
          matchPath(routePaths.tender(), pathname) != null ||
          matchPath(routePaths.order(), pathname) != null
        }
        label={t('menu.order')}
        onClick={(): void => {
          void navigate(routePaths.tender())
        }}
      />
      <SideMenuLayoutMenuItem
        IconLeft={FaClockRotateLeftRegular}
        isActive={matchPath(routePaths.history(), pathname) != null}
        label={t('menu.history')}
        onClick={(): void => {
          void navigate(routePaths.history())
        }}
      />
    </>
  )
}
