import { useState } from 'react'

import { useDebounce } from 'react-use'

import { SemanticColors } from '@enechain/ecloud-designsystem'

import {
  GroupBase,
  Select as ReactSelect,
  Props as ReactSelectProps,
} from 'chakra-react-select'

export type SelectOption = {
  label: string
  value: string
}

export type SelectGroup = GroupBase<SelectOption>

export type SelectProps<IsMulti extends boolean> = {
  debounce?: number
  onInputChange?: (newValue: string) => void
} & Omit<
  ReactSelectProps<SelectOption, IsMulti, SelectGroup>,
  'menuPlacement' | 'onInputChange'
>

const Select = <IsMulti extends boolean>({
  debounce = 500,
  onInputChange,
  ...rest
}: SelectProps<IsMulti>): JSX.Element => {
  const [input, setInput] = useState('')
  useDebounce(
    () => {
      if (onInputChange != null) {
        onInputChange(input)
      }
    },
    debounce,
    [input],
  )

  const _onInputChange = (newValue: string): void => {
    setInput(newValue)
  }

  return (
    <ReactSelect<SelectOption, IsMulti, SelectGroup>
      chakraStyles={{
        container: (provided) => ({
          ...provided,
          borderColor: SemanticColors.Border.midEmphasis,
        }),
        control: (provided) => ({
          ...provided,
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          paddingX: '12px',
          backgroundColor: 'transparent',
        }),
        indicatorSeparator: (provided) => ({
          ...provided,
          borderColor: SemanticColors.Border.midEmphasis,
        }),
        placeholder: (provided) => ({
          ...provided,
          textColor: SemanticColors.Text.lowEmphasis,
        }),
        valueContainer: (provided) => ({
          ...provided,
          paddingInlineStart: '12px',
          paddingInlineEnd: '12px',
        }),
      }}
      menuPlacement="auto"
      onInputChange={_onInputChange}
      selectedOptionStyle="check"
      {...rest}
    />
  )
}

export default Select
