import * as React from 'react'
import type { SVGProps } from 'react'
import { memo } from 'react'

const SvgClipIcon: React.FC<SVGProps<SVGSVGElement>> = (
  props: SVGProps<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#000"
      fillOpacity={0.62}
      d="M14.043 4.958a1.723 1.723 0 0 0-1.258-.535c-.482 0-.91.178-1.286.535l-5.114 5.115c-.589.625-.884 1.339-.884 2.142 0 .803.295 1.509.884 2.116.607.589 1.312.883 2.115.883.804 0 1.509-.294 2.116-.883l4.07-4.07c.303-.25.598-.25.884 0 .268.303.268.597 0 .883l-4.07 4.07c-.857.857-1.857 1.286-3 1.286-1.142 0-2.15-.429-3.025-1.286-.84-.857-1.259-1.856-1.259-2.999 0-1.142.42-2.151 1.259-3.026l5.14-5.14c.626-.608 1.349-.911 2.17-.911.803 0 1.517.303 2.142.91.607.625.91 1.348.91 2.169 0 .803-.303 1.517-.91 2.142l-4.9 4.954c-.447.41-.955.607-1.527.59-.57-.037-1.053-.287-1.446-.75-.32-.43-.473-.884-.455-1.366.036-.5.232-.938.59-1.312l4.07-4.07c.303-.25.598-.25.883 0 .268.303.268.598 0 .883L8.1 11.385c-.268.303-.286.625-.054.964.143.16.322.25.536.268a.787.787 0 0 0 .535-.214l4.927-4.927c.357-.357.536-.777.536-1.259 0-.482-.179-.901-.536-1.258Z"
    />
  </svg>
)
const Memo = memo(SvgClipIcon)
export default Memo
