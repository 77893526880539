import * as React from 'react'
import { useMemo } from 'react'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Flex } from '@chakra-ui/react'

import { useToast } from '@enechain/common-ui'
import {
  DangerButton,
  PrimaryButton,
  Spacing,
  TextButton,
} from '@enechain/ecloud-designsystem'
import { Status } from '@enechain/etender-proto/proto/etender/order/v1/model_pb'

import { useDeleteOrder } from '~/pages/order/hooks/useDeleteOrder'
import { useDiscardEditingOrder } from '~/pages/order/hooks/useDiscardEditingOrder'
import { useEditingOrder } from '~/pages/order/hooks/useEditingOrder'
import { routePaths } from '~/routes/routePaths'

type OrderFooterData = {
  isOrderDisabled: boolean
  orderType: 'order' | 'edit'
  nextAction: 'confirm' | 'delete'
}

const useOrderFooterData = (orderId: string): OrderFooterData => {
  const { data } = useEditingOrder<OrderFooterData>(orderId, (response) => {
    const isValid = response.editingOrder?.validationResult?.isValid ?? false
    const isEditing = response.editingOrder?.isEditing ?? false

    const bids = response.editingOrder?.bids ?? []
    const isDraft = response.editingOrder?.status === Status.DRAFT

    return {
      isOrderDisabled: !(isValid && isEditing),
      orderType: isDraft ? 'order' : 'edit',
      nextAction: bids.length > 0 || isDraft ? 'confirm' : 'delete',
    }
  })
  return data
}

type Props = {
  tenderId: string
  orderId: string
}

const OrderFooter: React.FC<Props> = ({ tenderId, orderId }) => {
  const { t: commonT } = useTranslation('common')
  const { t } = useTranslation('trader', {
    keyPrefix: 'order',
  })

  const { showToast } = useToast({
    toastId: 'pages/order/Footer',
  })

  const { isOrderDisabled, nextAction, orderType } = useOrderFooterData(orderId)

  const navigate = useNavigate()
  const discardMutation = useDiscardEditingOrder()
  const deleteMutation = useDeleteOrder(tenderId)

  const onClickCancel = (): void => {
    discardMutation.mutate(
      { orderId },
      {
        onSuccess: () => {
          switch (orderType) {
            case 'order': {
              navigate(routePaths.tender())
              return
            }
            case 'edit': {
              navigate(routePaths.historyDetail({ params: { tenderId } }))
              return
            }
          }
        },
        onError: () => {
          showToast({
            status: 'error',
            toastMessage: t('failed_to_cancel'),
          })
        },
      },
    )
  }

  const actionButton = useMemo(() => {
    const onClickConfirm = (): void => {
      navigate(routePaths.orderConfirm({ params: { tenderId, orderId } }))
    }
    const onClickDelete = (): void => {
      deleteMutation.mutate(
        {
          orderId,
        },
        {
          onSuccess: () => {
            showToast({
              status: 'success',
              toastMessage: t('success_to_delete'),
            })
            navigate(-1)
          },
          onError: () => {
            showToast({
              status: 'error',
              toastMessage: t('failed_to_delete'),
            })
          },
        },
      )
    }

    switch (nextAction) {
      case 'confirm':
        return (
          <PrimaryButton isDisabled={isOrderDisabled} onClick={onClickConfirm}>
            {t('order_confirm')}
          </PrimaryButton>
        )
      case 'delete':
        return (
          <DangerButton
            isDisabled={deleteMutation.isPending}
            isLoading={deleteMutation.isPending}
            onClick={onClickDelete}
          >
            {t('order_delete')}
          </DangerButton>
        )
    }
  }, [
    deleteMutation,
    isOrderDisabled,
    navigate,
    nextAction,
    orderId,
    showToast,
    t,
    tenderId,
  ])

  return (
    <Flex
      alignItems="center"
      justifyContent="flex-end"
      gap={Spacing['2']}
      minHeight="72px"
      boxShadow="base"
      paddingX={Spacing['10']}
    >
      <TextButton
        isDisabled={discardMutation.isPending}
        isLoading={discardMutation.isPending}
        onClick={onClickCancel}
      >
        {commonT('action.cancel')}
      </TextButton>
      {actionButton}
    </Flex>
  )
}

export default React.memo(OrderFooter)
