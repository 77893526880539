import * as React from 'react'

import { useSuspenseQuery } from '@connectrpc/connect-query'
import { useTranslation } from 'react-i18next'

import { getShortenUUID } from '@enechain/common-ui'
import DealStatusLabel from '@enechain/common-ui/src/components/DealStatusLabel'
import PositionLabel from '@enechain/common-ui/src/components/PositionLabel'
import { FlexTableCell, FlexTableRow } from '@enechain/ecloud-designsystem'
import { listByTenderID } from '@enechain/etender-proto/proto/bff/v1/bid_service-BidService_connectquery'
import { SleeveType } from '@enechain/etender-proto/proto/etender/order/v1/model_pb'
import { Position } from '@enechain/etender-proto/proto/etender/tender_terms/v1/model_pb'

import { HistoryColumnKey } from '~/pages/history-detail/components/OrderHistorySection/HistoryTable/HistoryColumnStyle'

type BidHistoryTableBodyData = {
  position: 'bid' | 'offer'
  bidId: {
    shortenId: string
    id: string
  }
  withSleeveFee: boolean
  unitPrice: string
  volume: string
  dealStatus:
    | 'bidding_period'
    | 'in_processing'
    | 'filled'
    | 'partially_filled'
    | 'unfilled'
  dealOrganizationName: string
  dealUnitPrice: string
  dealVolume: string
}

const useBidHistoryTableBodyData = (
  tenderId: string,
  orderId: string,
  tenderTermsId: string,
  bidId: string,
): BidHistoryTableBodyData | undefined => {
  const { data } = useSuspenseQuery(
    listByTenderID,
    {
      tenderId,
    },
    {
      select: (response): BidHistoryTableBodyData | undefined => {
        const history = response.histories[orderId]
        const bids = history?.bids[tenderTermsId]
        const bid = bids?.bids.find((bid) => bid.id === bidId)
        if (bid == null) {
          return undefined
        }
        const order = response.dataSetForOrder?.orders[bid.orderId]
        if (order == null) {
          return undefined
        }

        const sleeveType = order.contents?.sleeveType ?? SleeveType.UNSPECIFIED
        const withSleeveFee =
          sleeveType === SleeveType.EEX ||
          sleeveType === SleeveType.TOCOM ||
          sleeveType === SleeveType.BILATERAL
        // NOTE: 現在はFeeと同じ扱いになっている
        const isSleeverOrder =
          sleeveType === SleeveType.EEX ||
          sleeveType === SleeveType.TOCOM ||
          sleeveType === SleeveType.BILATERAL

        const tender = response.dataSetForOrder?.tenders[tenderId]
        if (tender == null) {
          return undefined
        }

        const position =
          response.dataSetForOrder?.tenderTerms[tenderTermsId].position
        if (position == null || position === Position.UNSPECIFIED) {
          return undefined
        }
        const getPosition = (): 'bid' | 'offer' | undefined => {
          switch (position) {
            case Position.BID:
              return 'bid'
            case Position.ASK:
              return 'offer'
            default:
              return undefined
          }
        }
        // 応札はtenderのsideの逆に固定される
        // NOTE: 将来的にオークション形式が変わり、売買両方のポジションを取れるようになう可能性があるが未実装
        const bidPosition = getPosition() === 'bid' ? 'offer' : 'bid'

        const schedule = tender?.schedule
        if (schedule == null) {
          return undefined
        }
        const displayDealStatus = (): BidHistoryTableBodyData['dealStatus'] => {
          const currentDate = new Date()
          // UB応札締切が未設定ならclosedAtを用いる
          const ultimateBuyerCloseAt =
            schedule?.ultimateBuyerCloseAt != null
              ? schedule?.ultimateBuyerCloseAt
              : schedule?.closeAt
          if (
            // 通常応札はcloseAtで「応札期間」判定
            (!isSleeverOrder &&
              schedule?.closeAt != null &&
              currentDate < schedule.closeAt.toDate()) ||
            // UB/USの応札(スリーバー応札)は他よりも早く「応札期間」が終わる場合あり
            (isSleeverOrder &&
              ultimateBuyerCloseAt != null &&
              currentDate < ultimateBuyerCloseAt.toDate())
          ) {
            return 'bidding_period'
          }
          return 'in_processing'
        }
        const dealStatus = displayDealStatus()

        return {
          position: bidPosition,
          bidId: {
            shortenId: getShortenUUID(bid.id),
            id: bid.id,
          },
          withSleeveFee,
          unitPrice: bid.unitPrice ?? '',
          volume: bid.volume ?? '',
          dealStatus,
          dealOrganizationName: '-',
          dealUnitPrice: '-',
          dealVolume: '-',
        }
      },
    },
  )
  return data
}

type Props = {
  tenderId: string
  orderId: string
  tenderTermsId: string
  bidId: string
}

const BidHistoryTableBodyRow: React.FC<Props> = ({
  tenderId,
  orderId,
  tenderTermsId,
  bidId,
}) => {
  const { t } = useTranslation('trader', {
    keyPrefix: 'history_detail.order_history_section.bid_history_table',
  })

  const data = useBidHistoryTableBodyData(
    tenderId,
    orderId,
    tenderTermsId,
    bidId,
  )

  if (data == null) {
    return null
  }

  return (
    <FlexTableRow>
      <FlexTableCell<HistoryColumnKey> columnKey="bid_or_offer">
        <PositionLabel position={data.position} />
      </FlexTableCell>
      <FlexTableCell<HistoryColumnKey>
        columnKey="bidId"
        contents={{ text: data.bidId.shortenId, fullText: data.bidId.id }}
      />
      <FlexTableCell<HistoryColumnKey>
        columnKey="unitPrice"
        contents={{
          text: data.unitPrice,
          subText: data.withSleeveFee ? t('has_sleeve_fee') : undefined,
          subTextMaxLines: 2,
        }}
      />
      <FlexTableCell<HistoryColumnKey>
        columnKey="volume"
        contents={{ text: data.volume }}
      />
      <FlexTableCell<HistoryColumnKey> columnKey="dealStatus">
        <DealStatusLabel status={data.dealStatus} />
      </FlexTableCell>
      <FlexTableCell<HistoryColumnKey>
        columnKey="dealOrganizationName"
        contents={{ text: data.dealOrganizationName }}
      />
      <FlexTableCell<HistoryColumnKey>
        columnKey="dealUnitPrice"
        contents={{ text: data.dealUnitPrice }}
      />
      <FlexTableCell<HistoryColumnKey>
        columnKey="dealVolume"
        contents={{ text: data.dealVolume }}
      />
      <FlexTableCell<HistoryColumnKey>
        columnKey="recap"
        contents={{ text: '-' }}
      />
    </FlexTableRow>
  )
}

export default React.memo(BidHistoryTableBodyRow)
