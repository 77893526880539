export type OrderInformationColumnKey =
  | 'accordion'
  | 'position'
  | 'area'
  | 'deliveryTerms'
  | 'excludePeriods'
  | 'hourType'
  | 'productType'
  | 'priceRestriction'
  | 'maxVolume'
  | 'minVolume'
  | 'note'

export const orderInformationColumnStyle = {
  accordion: {
    alignment: 'left',
    width: {
      type: 'fixed',
      width: '36px',
    },
  },
  position: {
    alignment: 'left',
    width: {
      type: 'fixed',
      width: '88px',
    },
  },
  area: {
    alignment: 'left',
    width: {
      type: 'ratio',
      ratio: 0.5,
    },
  },
  deliveryTerms: {
    alignment: 'left',
    width: {
      type: 'ratio',
      ratio: 1,
    },
  },
  excludePeriods: {
    alignment: 'left',
    width: {
      type: 'ratio',
      ratio: 1,
    },
  },
  hourType: {
    alignment: 'left',
    width: {
      type: 'ratio',
      ratio: 1,
    },
  },
  productType: {
    alignment: 'left',
    width: {
      type: 'ratio',
      ratio: 1,
    },
  },
  priceRestriction: {
    alignment: 'left',
    width: {
      type: 'ratio',
      ratio: 1,
    },
  },
  maxVolume: {
    alignment: 'left',
    width: {
      type: 'ratio',
      ratio: 1,
    },
  },
  minVolume: {
    alignment: 'left',
    width: {
      type: 'ratio',
      ratio: 1,
    },
  },
  note: {
    alignment: 'left',
    width: {
      type: 'ratio',
      ratio: 1.7,
    },
  },
} as const
