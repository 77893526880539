import * as React from 'react'
import type { SVGProps } from 'react'
import { memo } from 'react'

const SvgBuildingIcon: React.FC<SVGProps<SVGSVGElement>> = (
  props: SVGProps<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#000"
      fillOpacity={0.62}
      d="M7.215 5.789c.018-.268.16-.411.429-.429h1.285c.268.018.41.16.428.429v1.285c-.018.268-.16.41-.428.428H7.644c-.268-.018-.411-.16-.429-.428V5.789Zm5.141-.429c.268.018.411.16.429.429v1.285c-.018.268-.16.41-.429.428h-1.285c-.268-.018-.41-.16-.428-.428V5.789c.018-.268.16-.411.428-.429h1.285Zm-5.14 3.856c.017-.268.16-.41.428-.428h1.285c.268.018.41.16.428.428v1.285c-.018.268-.16.411-.428.429H7.644c-.268-.018-.411-.16-.429-.429V9.216Zm5.14-.428c.268.018.411.16.429.428v1.285c-.018.268-.16.411-.429.429h-1.285c-.268-.018-.41-.16-.428-.429V9.216c.018-.268.16-.41.428-.428h1.285ZM4.86 4.718a1.73 1.73 0 0 1 .509-1.205 1.73 1.73 0 0 1 1.204-.51h6.856a1.73 1.73 0 0 1 1.204.51c.322.32.491.723.51 1.205V15a1.73 1.73 0 0 1-.51 1.205c-.32.321-.723.49-1.204.509H6.572a1.731 1.731 0 0 1-1.204-.509A1.73 1.73 0 0 1 4.858 15V4.718Zm1.285 0V15c.018.268.16.41.428.428h2.143v-1.713c.018-.357.143-.66.375-.91.25-.233.553-.358.91-.376.357.018.66.143.91.375.232.25.357.554.375.91v1.714h2.143c.267-.017.41-.16.428-.428V4.718c-.018-.268-.16-.411-.428-.429H6.572c-.267.018-.41.16-.428.429Z"
    />
  </svg>
)
const Memo = memo(SvgBuildingIcon)
export default Memo
