import * as React from 'react'

import { useSuspenseQuery } from '@connectrpc/connect-query'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Flex } from '@chakra-ui/react'

import {
  DateFormat,
  TextWithTooltip,
  formatTimestamp,
  getShortenUUID,
} from '@enechain/common-ui'
import {
  AccordionIconButton,
  FlexTableCell,
  FlexTableRow,
  SecondaryButton,
  Spacing,
  Typography,
} from '@enechain/ecloud-designsystem'
import { listOrdered } from '@enechain/etender-proto/proto/bff/v1/tender_service-TenderService_connectquery'
import { Attachment } from '@enechain/etender-proto/proto/etender/tender/v1/model_pb'

import AttachmentFileItem from '~/components/AttachmentFileItem'
import { TenderColumnKey } from '~/pages/history/components/OrderedTenderSection/TenderTable/TenderColumnStyles'
import { routePaths } from '~/routes/routePaths'

type TenderTableRowData = {
  tenderId: {
    id: string
    shortenId: string
  }
  ownerOrganizationName: string | undefined
  name: string
  openAt: string
  closeAt: string
  awardAnnounceAt: string
  lastAppliedAt: string
  attachments: Attachment[]
}

const useTenderTableRowData = (
  tenderId: string,
  pageSize: number,
  pageToken?: string,
): TenderTableRowData | undefined => {
  const { data } = useSuspenseQuery(
    listOrdered,
    { pageSize, pageToken },
    {
      select: (response): TenderTableRowData | undefined => {
        const tender = response?.dataSetForOrder?.tenders[tenderId]
        if (tender == null) {
          return undefined
        }

        const ownerOrganization =
          tender.ownerOrganizationId != null
            ? response.dataSet?.organizations[tender.ownerOrganizationId]
            : undefined

        return {
          tenderId: {
            id: tender.id ?? '',
            shortenId: getShortenUUID(tender.id),
          },
          ownerOrganizationName: ownerOrganization?.name,
          name: tender.properties?.name ?? '',
          openAt: formatTimestamp(
            tender.schedule?.openAt,
            DateFormat.DATE_TIME_MINUTE_SLASH,
          ),
          closeAt: formatTimestamp(
            tender.schedule?.closeAt,
            DateFormat.DATE_TIME_MINUTE_SLASH,
          ),
          awardAnnounceAt: formatTimestamp(
            tender.schedule?.awardAnnounceAt,
            DateFormat.DATE_TIME_MINUTE_SLASH,
          ),
          lastAppliedAt: formatTimestamp(
            response?.lastAppliedAtMap[tenderId],
            DateFormat.DATE_TIME_MINUTE_SLASH,
          ),
          attachments: tender.attachments,
        }
      },
    },
  )
  return data
}

type Props = {
  tenderId: string
  pageSize: number
  pageToken?: string
}

const TenderTableBodyRow: React.FC<Props> = ({
  tenderId,
  pageSize,
  pageToken,
}) => {
  const { t: commonT } = useTranslation('common')
  const { t } = useTranslation('trader', { keyPrefix: 'history.tender_table' })

  const data = useTenderTableRowData(tenderId, pageSize, pageToken)
  const navigate = useNavigate()

  const onClickDetail = (): void => {
    navigate(routePaths.historyDetail({ params: { tenderId } }))
  }

  if (data == null) {
    return null
  }

  return (
    <FlexTableRow>
      <FlexTableCell<TenderColumnKey> columnKey="accordion">
        <Flex marginStart={Spacing['-2']}>
          <AccordionIconButton />
        </Flex>
      </FlexTableCell>
      <FlexTableCell<TenderColumnKey>
        columnKey="tenderId"
        contents={{
          text: data.tenderId.shortenId,
          fullText: data.tenderId.id,
        }}
      />
      <FlexTableCell<TenderColumnKey>
        columnKey="ownerOrganization"
        contents={{
          text: data.ownerOrganizationName ?? commonT('label.private'),
          textMaxLines: 4,
        }}
      />
      <FlexTableCell<TenderColumnKey>
        columnKey="name"
        contents={{ text: data.name, textMaxLines: 4 }}
      />
      <FlexTableCell<TenderColumnKey>
        columnKey="openAt"
        contents={{ text: data.openAt, textMaxLines: 4 }}
      />
      <FlexTableCell<TenderColumnKey>
        columnKey="closeAt"
        contents={{
          text: data.closeAt,
          textMaxLines: 4,
        }}
      />
      <FlexTableCell<TenderColumnKey>
        columnKey="awardAnnounceAt"
        contents={{
          text: data.awardAnnounceAt,
          textMaxLines: 4,
        }}
      />
      <FlexTableCell<TenderColumnKey>
        columnKey="lastAppliedAt"
        contents={{
          text: data.lastAppliedAt,
          textMaxLines: 4,
        }}
      />
      <FlexTableCell<TenderColumnKey> columnKey="attachments">
        <Flex flexDirection="column" gap={1} width="100%">
          {data.attachments.length === 0 ? (
            <TextWithTooltip
              text={commonT('label.no_setting')}
              typography={Typography.textMd}
            />
          ) : (
            data.attachments.map((attachment) => {
              return (
                <AttachmentFileItem
                  key={attachment.id}
                  attachment={attachment}
                  tenderId={data.tenderId.id}
                />
              )
            })
          )}
        </Flex>
      </FlexTableCell>
      <FlexTableCell<TenderColumnKey> columnKey="detail">
        <SecondaryButton height="32px" onClick={onClickDetail}>
          {t('detail')}
        </SecondaryButton>
      </FlexTableCell>
    </FlexTableRow>
  )
}

export default React.memo(TenderTableBodyRow)
