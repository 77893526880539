import { Transport } from '@connectrpc/connect'
import { createQueryOptions } from '@connectrpc/connect-query'
import { QueryClient } from '@tanstack/react-query'
import { LoaderFunction, defer } from 'react-router-dom'

import { listByTenderID } from '@enechain/etender-proto/proto/bff/v1/bid_service-BidService_connectquery'

export type HistoryDetailUrlParams = {
  tenderId: string
}

export type HistoryDetailLoaderData = {
  urlParams: HistoryDetailUrlParams
}

type Response = ReturnType<typeof defer>

export function historyDetailLoader(
  transport: Transport,
  queryClient: QueryClient,
): LoaderFunction {
  return ({ params }): Response => {
    async function getUrlParams(): Promise<HistoryDetailUrlParams> {
      try {
        const tenderId = params.tenderId
        if (tenderId == null) {
          throw new Response('tenderId is required', { status: 400 })
        }

        const response = await queryClient.ensureQueryData(
          createQueryOptions(
            listByTenderID,
            {
              tenderId,
            },
            {
              transport,
            },
          ),
        )

        const tender = response.dataSetForOrder?.tenders[tenderId]
        if (tender == null) {
          throw new Response('tender is not found', { status: 404 })
        }
        return {
          tenderId: tender.id,
        }
      } catch (e) {
        return {
          tenderId: params.tenderId ?? '',
        }
      }
    }

    return defer({
      urlParams: getUrlParams(),
    })
  }
}
