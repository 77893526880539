import * as React from 'react'

import { useTranslation } from 'react-i18next'

import { Flex, Text } from '@chakra-ui/react'

import { SemanticColors, Typography } from '@enechain/ecloud-designsystem'
import { DataIcon } from '@enechain/etender-icons'

const BasicErrorPage: React.FC = () => {
  const { t } = useTranslation('common')

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      width="100vw"
      height="100vh"
    >
      <Flex alignItems="center" flexDirection="column" gap={2}>
        <DataIcon
          width="64px"
          height="64px"
          color={SemanticColors.Object.lowEmphasis}
        />
        <Text
          textColor={SemanticColors.Text.midEmphasis}
          {...Typography.headerMd}
        >
          {t('label.error_occurred')}
        </Text>
      </Flex>
    </Flex>
  )
}

export default BasicErrorPage
