import { useCallback, useMemo } from 'react'

import { useSearchParams } from 'react-router-dom'

import { DefaultOrderHistoryPageSize } from '~/pages/history/constants'

export function createHistoryPageToken(pageNumber: number): string {
  // 下記の形式のJSON文字列をbase64エンコードしたものをpageTokenとする
  // https://github.com/enechain/etender-app/blob/develop/backend/internal/query/page_token.go
  return btoa(
    `{"page_no": ${pageNumber}, "page_size": ${DefaultOrderHistoryPageSize}}`,
  )
}

type UseHistoryPageToken = string
type SetHistoryPageToken = (pageNumber: number) => void

export function useHistoryPageToken(): [
  UseHistoryPageToken,
  SetHistoryPageToken,
] {
  const [searchParams, setSearchParams] = useSearchParams()

  const pageToken = useMemo(() => {
    const pageNumber = Number(searchParams.get('page')) ?? 1
    return createHistoryPageToken(pageNumber)
  }, [searchParams])

  const setHistoryPageToken = useCallback(
    (pageNumber: number) => {
      setSearchParams({ page: pageNumber.toString() })
    },
    [setSearchParams],
  )

  return [pageToken, setHistoryPageToken]
}
