import * as React from 'react'
import type { SVGProps } from 'react'
import { memo } from 'react'

const SvgExpandIcon: React.FC<SVGProps<SVGSVGElement>> = (
  props: SVGProps<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 26 26"
    {...props}
  >
    <path
      fill="currentColor"
      d="M20.531 5.063a.774.774 0 0 1 .407.406.62.62 0 0 1 .062.281v4.469c-.042.479-.292.74-.75.781-.458-.042-.708-.292-.75-.75V7.562l-4.219 4.22c-.354.29-.708.29-1.062 0-.292-.355-.292-.71 0-1.063L18.438 6.5H15.75c-.458-.042-.708-.292-.75-.75.042-.458.302-.708.781-.75h4.469a.62.62 0 0 1 .281.063ZM5.47 20.938a.774.774 0 0 1-.407-.407A.62.62 0 0 1 5 20.25v-4.5c.042-.458.292-.708.75-.75.458.042.708.292.75.75v2.688l4.219-4.22c.354-.29.708-.29 1.062 0 .292.355.292.71 0 1.063L7.562 19.5h2.688c.458.042.708.292.75.75-.042.458-.302.708-.781.75H5.75a.62.62 0 0 1-.281-.063Z"
    />
  </svg>
)
const Memo = memo(SvgExpandIcon)
export default Memo
