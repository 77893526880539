// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file etender/tender_terms/v1/service.proto (package etender.tender_terms.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3, Timestamp } from "@bufbuild/protobuf";
import { DeliveryUnit, EditableTenderTerms, ExcludePeriod, Position, ProductTypeId, SelectableInputs, TenderTerms } from "./model_pb.js";

/**
 * 空の入札条件を作成するリクエスト
 *
 * @generated from message etender.tender_terms.v1.CreateEmptyRequest
 */
export const CreateEmptyRequest = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender_terms.v1.CreateEmptyRequest",
  () => [
    { no: 1, name: "tender_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * 空の入札条件を作成するレスポンス
 *
 * @generated from message etender.tender_terms.v1.CreateEmptyResponse
 */
export const CreateEmptyResponse = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender_terms.v1.CreateEmptyResponse",
  () => [
    { no: 1, name: "terms", kind: "message", T: EditableTenderTerms },
  ],
);

/**
 * 入札条件を複製するリクエスト
 *
 * @generated from message etender.tender_terms.v1.CloneRequest
 */
export const CloneRequest = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender_terms.v1.CloneRequest",
  () => [
    { no: 1, name: "tender_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "target_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * 入札条件を複製するレスポンス
 *
 * @generated from message etender.tender_terms.v1.CloneResponse
 */
export const CloneResponse = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender_terms.v1.CloneResponse",
  () => [
    { no: 1, name: "terms", kind: "message", T: EditableTenderTerms },
  ],
);

/**
 * 複数の入札案件の応札可能な入札条件の一覧を取得するリクエスト
 *
 * @generated from message etender.tender_terms.v1.BatchListRequest
 */
export const BatchListRequest = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender_terms.v1.BatchListRequest",
  () => [
    { no: 1, name: "tender_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * 複数の入札案件の応札可能な入札条件の一覧を取得するレスポンス
 *
 * @generated from message etender.tender_terms.v1.BatchListResponse
 */
export const BatchListResponse = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender_terms.v1.BatchListResponse",
  () => [
    { no: 1, name: "terms", kind: "message", T: TenderTerms, repeated: true },
  ],
);

/**
 * 編集可能な入札条件の一覧を取得するリクエスト
 *
 * @generated from message etender.tender_terms.v1.ListEditableRequest
 */
export const ListEditableRequest = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender_terms.v1.ListEditableRequest",
  () => [
    { no: 1, name: "tender_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "is_only_valid", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ],
);

/**
 * 編集可能な入札条件の一覧を取得するレスポンス
 *
 * @generated from message etender.tender_terms.v1.ListEditableResponse
 */
export const ListEditableResponse = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender_terms.v1.ListEditableResponse",
  () => [
    { no: 1, name: "terms", kind: "message", T: EditableTenderTerms, repeated: true },
  ],
);

/**
 * 複数の入札案件の編集可能で応札可能な入札条件の一覧を取得するリクエスト
 *
 * @generated from message etender.tender_terms.v1.BatchListEditableRequest
 */
export const BatchListEditableRequest = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender_terms.v1.BatchListEditableRequest",
  () => [
    { no: 1, name: "tender_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ],
);

/**
 * 複数の入札案件の編集可能で応札可能な入札条件の一覧を取得するレスポンス
 *
 * @generated from message etender.tender_terms.v1.BatchListEditableResponse
 */
export const BatchListEditableResponse = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender_terms.v1.BatchListEditableResponse",
  () => [
    { no: 1, name: "terms", kind: "message", T: EditableTenderTerms, repeated: true },
  ],
);

/**
 * 入札条件を更新するリクエスト
 *
 * @generated from message etender.tender_terms.v1.UpdateRequest
 */
export const UpdateRequest = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender_terms.v1.UpdateRequest",
  () => [
    { no: 1, name: "tender_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "tender_terms_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "position", kind: "enum", T: proto3.getEnumType(Position) },
    { no: 4, name: "delivery_unit", kind: "enum", T: proto3.getEnumType(DeliveryUnit) },
    { no: 5, name: "product_type_id", kind: "enum", T: proto3.getEnumType(ProductTypeId) },
    { no: 6, name: "fuel_surcharge_type_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "area_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "hour_type_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "start_at", kind: "message", T: Timestamp },
    { no: 10, name: "end_at", kind: "message", T: Timestamp },
    { no: 11, name: "has_exclude_period", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 12, name: "exclude_periods", kind: "message", T: ExcludePeriod, repeated: true },
    { no: 13, name: "max_volume", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "is_public_max_volume", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 15, name: "min_volume", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "min_unit_price", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "is_public_min_unit_price", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 18, name: "note", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * 入札条件を更新するレスポンス
 *
 * @generated from message etender.tender_terms.v1.UpdateResponse
 */
export const UpdateResponse = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender_terms.v1.UpdateResponse",
  () => [
    { no: 1, name: "terms", kind: "message", T: EditableTenderTerms },
  ],
);

/**
 * 入札条件を削除するリクエスト
 *
 * @generated from message etender.tender_terms.v1.DeleteRequest
 */
export const DeleteRequest = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender_terms.v1.DeleteRequest",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * 入札条件を削除するレスポンス
 *
 * @generated from message etender.tender_terms.v1.DeleteResponse
 */
export const DeleteResponse = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender_terms.v1.DeleteResponse",
  [],
);

/**
 * 入札条件の設定可能な入力項目を取得するリクエスト
 *
 * @generated from message etender.tender_terms.v1.GetSelectableInputsRequest
 */
export const GetSelectableInputsRequest = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender_terms.v1.GetSelectableInputsRequest",
  [],
);

/**
 * 入札条件の設定可能な入力項目を取得するレスポンス
 *
 * @generated from message etender.tender_terms.v1.GetSelectableInputsResponse
 */
export const GetSelectableInputsResponse = /*@__PURE__*/ proto3.makeMessageType(
  "etender.tender_terms.v1.GetSelectableInputsResponse",
  () => [
    { no: 1, name: "inputs", kind: "message", T: SelectableInputs },
  ],
);

