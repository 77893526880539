// @generated by protoc-gen-connect-query v1.3.1 with parameter "target=js+dts"
// @generated from file bff/v1/bid_service.proto (package bff.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { MethodKind } from "@bufbuild/protobuf";
import { GetAllByTenderIDAsCsvRequest, GetAllByTenderIDAsCsvResponse, ListAllByTenderIDRequest, ListAllByTenderIDResponse, ListByTenderIDRequest, ListByTenderIDResponse } from "./bid_service_pb.js";

/**
 * 指定した入札案件の自分の応札を取得する
 *
 * @generated from rpc bff.v1.BidService.ListByTenderID
 */
export const listByTenderID = {
  localName: "listByTenderID",
  name: "ListByTenderID",
  kind: MethodKind.Unary,
  I: ListByTenderIDRequest,
  O: ListByTenderIDResponse,
  service: {
    typeName: "bff.v1.BidService"
  }
};

/**
 * 指定した入札案件のすべての応札を取得する
 *
 * @generated from rpc bff.v1.BidService.ListAllByTenderID
 */
export const listAllByTenderID = {
  localName: "listAllByTenderID",
  name: "ListAllByTenderID",
  kind: MethodKind.Unary,
  I: ListAllByTenderIDRequest,
  O: ListAllByTenderIDResponse,
  service: {
    typeName: "bff.v1.BidService"
  }
};

/**
 * 指定した入札案件のすべての応札をCSV形式で取得する
 *
 * @generated from rpc bff.v1.BidService.GetAllByTenderIDAsCsv
 */
export const getAllByTenderIDAsCsv = {
  localName: "getAllByTenderIDAsCsv",
  name: "GetAllByTenderIDAsCsv",
  kind: MethodKind.Unary,
  I: GetAllByTenderIDAsCsvRequest,
  O: GetAllByTenderIDAsCsvResponse,
  service: {
    typeName: "bff.v1.BidService"
  }
};
