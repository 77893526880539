import { PartialMessage } from '@bufbuild/protobuf'
import { createConnectQueryKey, useMutation } from '@connectrpc/connect-query'
import { UseMutationResult, useQueryClient } from '@tanstack/react-query'

import { listOrders } from '@enechain/etender-proto/proto/bff/v1/order_service-OrderService_connectquery'
import { createEmpty } from '@enechain/etender-proto/proto/etender/order/v1/service-Service_connectquery'
import {
  CreateEmptyRequest,
  CreateEmptyResponse,
} from '@enechain/etender-proto/proto/etender/order/v1/service_pb'

export function useCreateEmptyOrder(): UseMutationResult<
  CreateEmptyResponse,
  Error,
  PartialMessage<CreateEmptyRequest>
> {
  const queryClient = useQueryClient()
  return useMutation(createEmpty, {
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: createConnectQueryKey(listOrders),
        type: 'all',
      })
    },
  })
}
