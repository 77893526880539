import * as React from 'react'
import type { SVGProps } from 'react'
import { memo } from 'react'

const SvgChevronRightIcon: React.FC<SVGProps<SVGSVGElement>> = (
  props: SVGProps<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="m10.47 5.197 5.957 6.25a.717.717 0 0 1 .228.52c0 .196-.076.38-.228.554l-5.957 6.217c-.347.304-.716.315-1.107.033-.304-.37-.304-.738 0-1.107l5.469-5.73-5.469-5.63c-.304-.391-.304-.76 0-1.107.39-.304.76-.304 1.107 0Z"
    />
  </svg>
)
const Memo = memo(SvgChevronRightIcon)
export default Memo
