import * as React from 'react'
import type { SVGProps } from 'react'
import { memo } from 'react'

const SvgClockIcon: React.FC<SVGProps<SVGSVGElement>> = (
  props: SVGProps<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#000"
      fillOpacity={0.62}
      d="M9.357 6.217c.036-.393.25-.607.643-.643.393.036.607.25.643.643v3.294l2.276 1.526c.321.25.375.544.16.884-.232.32-.517.375-.857.16l-2.57-1.713c-.196-.108-.295-.286-.295-.536V6.217ZM10 3.004c1.285.018 2.437.33 3.454.937a6.791 6.791 0 0 1 2.464 2.463c.607 1.018.92 2.17.937 3.455-.018 1.285-.33 2.437-.937 3.454a6.79 6.79 0 0 1-2.464 2.463c-1.017.608-2.169.92-3.454.938-1.285-.018-2.437-.33-3.454-.937a6.79 6.79 0 0 1-2.464-2.464c-.607-1.018-.92-2.169-.937-3.454.018-1.286.33-2.437.937-3.455a6.791 6.791 0 0 1 2.464-2.463c1.017-.607 2.169-.92 3.454-.937ZM4.43 9.859c.036 1.57.58 2.883 1.634 3.936 1.053 1.053 2.365 1.598 3.936 1.633 1.57-.035 2.883-.58 3.936-1.633s1.598-2.365 1.634-3.936c-.036-1.571-.58-2.883-1.634-3.937C12.883 4.87 11.571 4.325 10 4.29c-1.57.036-2.883.58-3.936 1.633C5.01 6.976 4.466 8.288 4.43 9.86Z"
    />
  </svg>
)
const Memo = memo(SvgClockIcon)
export default Memo
