import * as React from 'react'

import { useSuspenseQuery } from '@connectrpc/connect-query'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import {
  AccordionButton,
  AccordionPanel,
  Flex,
  IconButton,
  Text,
} from '@chakra-ui/react'

import {
  AccordionIcon,
  DateFormat,
  Divider,
  formatTimestamp,
} from '@enechain/common-ui'
import {
  BorderRadius,
  SemanticColors,
  Spacing,
  TertiaryButton,
  Typography,
} from '@enechain/ecloud-designsystem'
import { listByTenderID } from '@enechain/etender-proto/proto/bff/v1/bid_service-BidService_connectquery'
import { SleeveType } from '@enechain/etender-proto/proto/etender/order/v1/model_pb'

import OrderSleeveTypeTag from '~/components/order/OrderSleeveTypeTag'
import HistoryTable from '~/pages/history-detail/components/OrderHistorySection/HistoryTable'
import TenderTermsTable from '~/pages/history-detail/components/OrderHistorySection/TenderTermsTable'
import { getTenderStatus } from '~/pages/history-detail/tenderStatus'
import { routePaths } from '~/routes/routePaths'

type OrderInformationData = {
  sleeveType: SleeveType
  appliedAt: string | undefined
  tenderTermsIds: string[]
  bidIDs: string[]
  isBiddingInProgress: boolean
}

const useOrderInformationData = (
  tenderId: string,
  orderId: string,
): OrderInformationData | undefined => {
  const { data } = useSuspenseQuery(
    listByTenderID,
    {
      tenderId,
    },
    {
      select: (response): OrderInformationData | undefined => {
        const tender = response.dataSetForOrder?.tenders[tenderId]
        const order = response.dataSetForOrder?.orders[orderId]
        const history = response.histories[orderId]
        if (tender == null || order == null || history == null) {
          return undefined
        }

        const sleeveType = order.contents?.sleeveType ?? SleeveType.UNSPECIFIED
        const tenderStatus = getTenderStatus(tender, new Date(), sleeveType)

        const bidIDs: string[] = []
        for (const tenderTermsId of history.tenderTermsIds) {
          const bids = history.bids[tenderTermsId]
          if (bids == null) {
            continue
          }
          for (const bid of bids.bids) {
            bidIDs.push(bid.id)
          }
        }

        return {
          sleeveType: sleeveType,
          appliedAt:
            order.appliedAt != null
              ? formatTimestamp(
                  order.appliedAt,
                  DateFormat.DATE_TIME_MINUTE_SLASH,
                )
              : undefined,
          tenderTermsIds: history.tenderTermsIds,
          bidIDs,
          isBiddingInProgress: tenderStatus === 'biddingInProgress',
        }
      },
    },
  )
  return data
}

type Props = {
  tenderId: string
  orderId: string
}

const OrderInformation: React.FC<Props> = ({ tenderId, orderId }) => {
  const { t: commonT } = useTranslation('common')
  const { t } = useTranslation('trader', {
    keyPrefix: 'history_detail.order_history_section.order_information',
  })
  const navigate = useNavigate()

  const data = useOrderInformationData(tenderId, orderId)

  const onClickEdit = (): void => {
    if (data == null) {
      return
    }
    navigate(
      routePaths.order({
        params: {
          tenderId,
          orderId,
        },
      }),
    )
  }

  if (data == null) {
    return null
  }

  return (
    <Flex
      flexDirection="column"
      gap={Spacing['4']}
      padding={Spacing['4']}
      borderWidth="1px"
      borderColor={SemanticColors.Border.lowEmphasis}
      borderRadius={BorderRadius.radiiLg}
    >
      <Flex
        alignItems="center"
        justifyContent="space-between"
        flexDirection="row"
      >
        <Flex flexDirection="column" gap={Spacing['2']}>
          <Flex alignItems="center" flexDirection="row" gap={Spacing['3']}>
            <Text
              color={SemanticColors.Text.highEmphasis}
              {...Typography.headerMd}
            >
              {t('title')}
            </Text>
            <OrderSleeveTypeTag sleeveType={data.sleeveType} />
          </Flex>
          {data.appliedAt != null && (
            <Text
              color={SemanticColors.Text.lowEmphasis}
              {...Typography.textMd}
            >
              {data.appliedAt}
            </Text>
          )}
        </Flex>
        <Flex gap={Spacing['4']}>
          <TertiaryButton
            isDisabled={!data.isBiddingInProgress}
            onClick={onClickEdit}
          >
            {commonT('action.edit')}
          </TertiaryButton>
          <AccordionButton
            as={IconButton}
            width="40px"
            height="40px"
            marginRight={Spacing['-1']}
            padding={Spacing['2']}
            backgroundColor={SemanticColors.Surface.primary}
          >
            <AccordionIcon closeIcon="up" openIcon="down" size="40px" />
          </AccordionButton>
        </Flex>
      </Flex>
      <AccordionPanel
        flexDirection="column"
        gap={Spacing['4']}
        display="flex"
        padding={Spacing['0']}
      >
        <Divider />
        {data.tenderTermsIds.map((tenderTermsId) => (
          <TenderTermsTable
            key={tenderTermsId}
            orderId={orderId}
            tenderId={tenderId}
            tenderTermsId={tenderTermsId}
          >
            <HistoryTable
              displayBidIDs={data.bidIDs}
              displayType="bid"
              orderId={orderId}
              tenderId={tenderId}
              tenderTermsId={tenderTermsId}
            />
          </TenderTermsTable>
        ))}
      </AccordionPanel>
    </Flex>
  )
}

export default React.memo(OrderInformation)
