// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file etender/bid/v1/service.proto (package etender.bid.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";
import { EditingBid } from "./model_pb.js";

/**
 * 空の応札を作成するリクエスト
 *
 * @generated from message etender.bid.v1.CreateEmptyRequest
 */
export const CreateEmptyRequest = /*@__PURE__*/ proto3.makeMessageType(
  "etender.bid.v1.CreateEmptyRequest",
  () => [
    { no: 1, name: "order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "tender_terms_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ],
);

/**
 * 空の応札を作成するレスポンス
 *
 * @generated from message etender.bid.v1.CreateEmptyResponse
 */
export const CreateEmptyResponse = /*@__PURE__*/ proto3.makeMessageType(
  "etender.bid.v1.CreateEmptyResponse",
  () => [
    { no: 1, name: "bid", kind: "message", T: EditingBid },
  ],
);

/**
 * 応札を編集するリクエスト
 *
 * @generated from message etender.bid.v1.EditRequest
 */
export const EditRequest = /*@__PURE__*/ proto3.makeMessageType(
  "etender.bid.v1.EditRequest",
  () => [
    { no: 1, name: "order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "bid_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "tender_terms_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "unit_price", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "volume", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * 応札を編集するレスポンス
 *
 * @generated from message etender.bid.v1.EditResponse
 */
export const EditResponse = /*@__PURE__*/ proto3.makeMessageType(
  "etender.bid.v1.EditResponse",
  () => [
    { no: 1, name: "bid", kind: "message", T: EditingBid },
  ],
);

/**
 * 応札を削除するリクエスト
 *
 * @generated from message etender.bid.v1.DeleteRequest
 */
export const DeleteRequest = /*@__PURE__*/ proto3.makeMessageType(
  "etender.bid.v1.DeleteRequest",
  () => [
    { no: 1, name: "order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "bid_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ],
);

/**
 * 応札を削除するレスポンス
 *
 * @generated from message etender.bid.v1.DeleteResponse
 */
export const DeleteResponse = /*@__PURE__*/ proto3.makeMessageType(
  "etender.bid.v1.DeleteResponse",
  [],
);

