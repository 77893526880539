import * as React from 'react'

import { useSuspenseQuery } from '@connectrpc/connect-query'
import { useTranslation } from 'react-i18next'

import { Flex, Text } from '@chakra-ui/react'

import { Spacing, Typography } from '@enechain/ecloud-designsystem'
import { getEditingOrder } from '@enechain/etender-proto/proto/bff/v1/order_service-OrderService_connectquery'
import { SleeveType } from '@enechain/etender-proto/proto/etender/order/v1/model_pb'

import OrderSleeveTypeTag from '~/components/order/OrderSleeveTypeTag'

type OrderHeaderData = {
  sleeveType: SleeveType
}

const useOrderHeaderData = (orderId: string): OrderHeaderData | undefined => {
  const { data } = useSuspenseQuery(
    getEditingOrder,
    {
      orderId,
    },
    {
      select: (response): OrderHeaderData | undefined => {
        const contents = response.editingOrder?.contents
        if (contents == null) {
          return undefined
        }
        return {
          sleeveType: contents.sleeveType,
        }
      },
    },
  )
  return data
}

type Props = {
  orderId: string
}

const OrderHeader: React.FC<Props> = ({ orderId }) => {
  const { t } = useTranslation('trader', {
    keyPrefix: 'order',
  })

  const data = useOrderHeaderData(orderId)

  if (data == null) {
    return null
  }

  return (
    <Flex alignItems="center" flexDirection="row" gap={Spacing['4']}>
      <Text {...Typography['(headerLg+)']}>{t('page_title')}</Text>
      <OrderSleeveTypeTag sleeveType={data.sleeveType} />
    </Flex>
  )
}

export default React.memo(OrderHeader)
