import { PathFunction, generatePathFromQuery } from '@enechain/common-ui'

export const routePaths: {
  root: PathFunction
  tender: PathFunction
  order: PathFunction<{
    tenderId: string
    orderId: string
  }>
  orderConfirm: PathFunction<{
    tenderId: string
    orderId: string
  }>
  history: PathFunction<
    undefined,
    {
      page: string
    }
  >
  historyDetail: PathFunction<{
    tenderId: string
  }>
} = {
  root: () => '/',
  tender: () => '/tender',
  order: (
    { params } = { params: { tenderId: ':tenderId', orderId: ':orderId' } },
  ): string => `/tender/${params.tenderId}/order/${params.orderId}`,
  orderConfirm: (
    { params } = { params: { tenderId: ':tenderId', orderId: ':orderId' } },
  ): string => `/tender/${params.tenderId}/order/${params.orderId}/confirm`,
  history: (args) => `/tender/history${generatePathFromQuery(args?.query)}`,
  historyDetail: ({ params } = { params: { tenderId: ':tenderId' } }) =>
    `/tender/history/${params.tenderId}`,
}

type ESquareRoutePaths = {
  root: PathFunction
  edpm: PathFunction
  report: PathFunction
  history: PathFunction
  chat: PathFunction
  profile: PathFunction
}

const eSquareBaseUrl = import.meta.env.VITE_ESQUARE_URL
export const eSquareRoutePaths: ESquareRoutePaths = {
  root: () => `${eSquareBaseUrl}`,
  edpm: () => `${eSquareBaseUrl}/daily-power-market`,
  report: () => `${eSquareBaseUrl}/reports`,
  history: () => `${eSquareBaseUrl}/history`,
  chat: () => `${eSquareBaseUrl}/chat`,
  profile: () => `${eSquareBaseUrl}/user-profile`,
}

type ECompassRoutePaths = {
  root: PathFunction
}

const eCompassBaseUrl = import.meta.env.VITE_ECOMPASS_URL
export const eCompassRoutePaths: ECompassRoutePaths = {
  root: () => `${eCompassBaseUrl}`,
}

type ECloudRoutePaths = {
  root: PathFunction
  profile: PathFunction
}

const eCloudBaseUrl = import.meta.env.VITE_ECLOUD_URL
export const eCloudRoutePaths: ECloudRoutePaths = {
  root: () => `${eCloudBaseUrl}`,
  profile: () => `${eCloudBaseUrl}/profile`,
}
