import * as React from 'react'
import type { SVGProps } from 'react'
import { memo } from 'react'

const SvgCloneIcon: React.FC<SVGProps<SVGSVGElement>> = (
  props: SVGProps<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 16 16"
    {...props}
  >
    <path
      fill="#1D1D1D"
      d="M14.432 3.682a.78.78 0 0 1 .235.573v5.912c-.018.468-.183.859-.495 1.171a1.683 1.683 0 0 1-1.172.495H8a1.683 1.683 0 0 1-1.172-.495 1.636 1.636 0 0 1-.52-1.171V3.5c.017-.469.182-.86.494-1.172a1.683 1.683 0 0 1 1.172-.495h4.27a.78.78 0 0 1 .574.235l1.614 1.614Zm-1.015 6.485h-.026v-5h-1.224a.811.811 0 0 1-.6-.235.811.811 0 0 1-.234-.599l-.026-1.25H7.974c-.26.035-.4.174-.417.417v6.667c.018.26.157.399.417.416H13c.26-.017.4-.156.417-.416Zm-5 3.333.026-.833h1.224v.833c-.018.469-.183.86-.495 1.172A1.683 1.683 0 0 1 8 15.167H3a1.683 1.683 0 0 1-1.172-.495 1.683 1.683 0 0 1-.495-1.172V6.833c.018-.468.183-.859.495-1.172A1.683 1.683 0 0 1 3 5.167h2.5v1.25H3c-.26.017-.4.156-.417.416L2.557 13.5c.018.26.157.4.417.417H8c.26-.018.4-.157.417-.417Z"
    />
  </svg>
)
const Memo = memo(SvgCloneIcon)
export default Memo
