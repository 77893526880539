import { useEffect } from 'react'

import { useLocation } from 'react-router-dom'
import { atom, useRecoilState } from 'recoil'

const pageUrlState = atom<{ previousUrl: string; currentUrl: string }>({
  key: 'trader/previousPageUrl',
  default: {
    previousUrl: '',
    currentUrl: '',
  },
})

export function useSetPreviousPageUrl(): void {
  const [pageUrl, setPageUrl] = useRecoilState(pageUrlState)

  const location = useLocation()
  useEffect(() => {
    setPageUrl({
      currentUrl: location.pathname,
      previousUrl: pageUrl.currentUrl,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, setPageUrl])
}

export function usePreviousPageUrl(): string {
  const [pageUrl, _] = useRecoilState(pageUrlState)
  return pageUrl.previousUrl
}
