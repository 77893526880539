import * as React from 'react'

import { useTranslation } from 'react-i18next'

import { Flex, Text } from '@chakra-ui/react'

import { Typography } from '@enechain/ecloud-designsystem'

const Header: React.FC = () => {
  const { t } = useTranslation('trader', { keyPrefix: 'tender' })

  return (
    <Flex flexDirection="column" gap={4} paddingTop={4} paddingX={4}>
      <Text {...Typography.headerXL}>{t('page_title')}</Text>
    </Flex>
  )
}

export default Header
