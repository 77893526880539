import * as React from 'react'

import { Text, ThemeTypings, Tooltip } from '@chakra-ui/react'
import { TypographyProps } from '@chakra-ui/system'

import { SemanticColors, Typography } from '@enechain/ecloud-designsystem'

export type TextWithTooltipProps = {
  text?: string
  fullText?: string
  typography?: TypographyProps
  textColor?: ThemeTypings['colors']
}

const TextWithTooltip: React.FC<TextWithTooltipProps> = ({
  text,
  fullText = text,
  typography = Typography.textMd,
  textColor = SemanticColors.Text.highEmphasis,
}) => {
  if (text == null) {
    return null
  }

  return (
    <Tooltip label={fullText} placement="bottom-start">
      <Text
        overflow="hidden"
        width="100%"
        textColor={textColor}
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        {...typography}
      >
        {text}
      </Text>
    </Tooltip>
  )
}

export default React.memo(TextWithTooltip)
