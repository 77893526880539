import * as React from 'react'
import type { SVGProps } from 'react'
import { memo } from 'react'

const SvgPlusIcon: React.FC<SVGProps<SVGSVGElement>> = (
  props: SVGProps<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M19.187 12.328c-.043.471-.3.728-.772.771h-5.143v5.144c-.043.471-.3.729-.772.771-.472-.043-.729-.3-.772-.771v-5.144H6.585c-.472-.043-.729-.3-.772-.771.043-.472.3-.73.772-.772h5.143V6.412c.043-.471.3-.729.772-.771.472.042.729.3.772.771v5.144h5.143c.472.043.729.3.772.772Z"
    />
  </svg>
)
const Memo = memo(SvgPlusIcon)
export default Memo
