import * as React from 'react'
import type { SVGProps } from 'react'
import { memo } from 'react'

const SvgChevronDownIcon: React.FC<SVGProps<SVGSVGElement>> = (
  props: SVGProps<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="currentColor"
      d="m15.597 8.6-5.115 4.9c-.178.143-.34.215-.482.215a.682.682 0 0 1-.455-.188L4.404 8.6a.62.62 0 0 1-.188-.455.62.62 0 0 1 .188-.455c.285-.25.589-.25.91 0L10 12.188l4.686-4.498c.321-.25.625-.241.91.027.125.107.188.25.188.428a.62.62 0 0 1-.188.455Z"
    />
  </svg>
)
const Memo = memo(SvgChevronDownIcon)
export default Memo
