import * as React from 'react'

import { useSuspenseQuery } from '@connectrpc/connect-query'
import { useTranslation } from 'react-i18next'

import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Flex,
  Text,
} from '@chakra-ui/react'

import {
  AccordionIcon,
  DateFormat,
  formatTimestamp,
  isNotEmptyOrNull,
} from '@enechain/common-ui'
import {
  BorderRadius,
  SemanticColors,
  Spacing,
  Typography,
} from '@enechain/ecloud-designsystem'
import {
  BuildingIcon,
  ClipIcon,
  ClockIcon,
  GavelIcon,
} from '@enechain/etender-icons'
import { listByTenderID } from '@enechain/etender-proto/proto/bff/v1/bid_service-BidService_connectquery'
import { Attachment } from '@enechain/etender-proto/proto/etender/tender/v1/model_pb'

import AttachmentFileItem from '~/components/AttachmentFileItem'
import InformationLayout from '~/pages/history-detail/components/TenderInformationSection/InformationLayout'

type TenderInformationSectionData = {
  tenderId: string
  ownerOrganizationName: string | undefined
  openAt: string
  closeAt: string
  awardAnnounceAt: string
  appliedAt: string | undefined
  attachments: Attachment[]
}

const useTenderInformationSectionData = (
  tenderId: string,
): TenderInformationSectionData | undefined => {
  const { data } = useSuspenseQuery(
    listByTenderID,
    {
      tenderId,
    },
    {
      select: (response): TenderInformationSectionData | undefined => {
        const tender = response.dataSetForOrder?.tenders[tenderId]
        if (tender == null) {
          return undefined
        }

        const organization =
          tender.ownerOrganizationId != null
            ? response.dataSet?.organizations[tender.ownerOrganizationId]
            : undefined

        return {
          tenderId: tender.id ?? '',
          ownerOrganizationName: isNotEmptyOrNull(organization?.name)
            ? organization?.name
            : undefined,
          openAt: formatTimestamp(
            tender.schedule?.openAt,
            DateFormat.DATE_TIME_MINUTE_SLASH,
          ),
          closeAt: formatTimestamp(
            tender.schedule?.closeAt,
            DateFormat.DATE_TIME_MINUTE_SLASH,
          ),
          awardAnnounceAt: formatTimestamp(
            tender.schedule?.awardAnnounceAt,
            DateFormat.DATE_TIME_MINUTE_SLASH,
          ),
          appliedAt:
            response.lastAppliedAt != null
              ? formatTimestamp(
                  response.lastAppliedAt,
                  DateFormat.DATE_TIME_SLASH,
                )
              : undefined,
          attachments: tender.attachments ?? [],
        }
      },
    },
  )
  return data
}

type Props = {
  tenderId: string
}

const TenderInformationSection: React.FC<Props> = ({ tenderId }) => {
  const { t: commonT } = useTranslation('common')
  const { t } = useTranslation('trader', {
    keyPrefix: 'history_detail.tender_information_section',
  })

  const data = useTenderInformationSectionData(tenderId)

  if (data == null) {
    return null
  }

  return (
    <Accordion allowToggle defaultIndex={0}>
      <AccordionItem
        flexDirection="column"
        display="flex"
        borderWidth={0}
        borderRadius={BorderRadius.radiiLg}
        _last={{ borderBottomWidth: 0 }}
        backgroundColor={SemanticColors.Surface.neutralMid}
        paddingY={Spacing['2']}
      >
        <AccordionButton
          gap={Spacing['2']}
          display="flex"
          borderRadius={BorderRadius.radiiMd}
        >
          <AccordionIcon size="20px" />
          <Text
            color={SemanticColors.Text.midEmphasis}
            {...Typography.headerSm}
          >
            {t('title')}
          </Text>
        </AccordionButton>
        <AccordionPanel
          flexDirection="column"
          gap={Spacing['2']}
          display="flex"
        >
          <InformationLayout
            data={data.tenderId}
            icon={GavelIcon}
            title={t('tender_id')}
          />
          <InformationLayout
            data={data.ownerOrganizationName ?? commonT('label.private')}
            icon={BuildingIcon}
            title={t('owner_organization_name')}
          />
          <InformationLayout
            data={data.openAt}
            icon={ClockIcon}
            title={t('open_at')}
          />
          <InformationLayout
            data={data.closeAt}
            icon={ClockIcon}
            title={t('close_at')}
          />
          <InformationLayout
            data={data.awardAnnounceAt}
            icon={ClockIcon}
            title={t('award_announce_at')}
          />
          <InformationLayout
            data={data.appliedAt != null ? data.appliedAt : t('empty_bid')}
            icon={ClockIcon}
            title={t('applied_at')}
          />
          <InformationLayout
            data={
              data.attachments.length > 0 ? (
                <Flex flexDirection="row">
                  {data.attachments.map((attachment) => (
                    <AttachmentFileItem
                      key={attachment.id}
                      attachment={attachment}
                      tenderId={tenderId}
                    />
                  ))}
                </Flex>
              ) : (
                commonT('label.no_setting')
              )
            }
            icon={ClipIcon}
            title={t('attachments')}
          />
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}

export default React.memo(TenderInformationSection)
