import { useSuspenseQuery } from '@connectrpc/connect-query'
import { UseQueryResult } from '@tanstack/react-query'

import { getEditingOrder } from '@enechain/etender-proto/proto/bff/v1/order_service-OrderService_connectquery'
import { GetEditingOrderResponse } from '@enechain/etender-proto/proto/bff/v1/order_service_pb'

type EditingOrderResponse<T> = {
  data: T
} & Pick<UseQueryResult, 'refetch'>

export function useEditingOrder<T>(
  orderId: string,
  select: (response: GetEditingOrderResponse) => T,
): EditingOrderResponse<T> {
  const { data, refetch } = useSuspenseQuery(
    getEditingOrder,
    {
      orderId,
    },
    {
      select,
    },
  )

  return {
    data,
    refetch,
  }
}
