import {
  LoaderFunction,
  RedirectFunction,
  defer,
  redirect,
} from 'react-router-dom'

import { createHistoryPageToken } from '~/pages/history/hooks/useHistoryPageToken'
import { routePaths } from '~/routes/routePaths'

export type HistoryUrlParams = {
  pageNumber: number
  pageToken: string
}

export type HistoryData = {
  urlParams: HistoryUrlParams
}

export function historyLoader(): LoaderFunction {
  return ({ request }) => {
    function getUrlParams():
      | { type: 'success'; value: Promise<HistoryUrlParams> }
      | { type: 'redirect'; value: ReturnType<RedirectFunction> } {
      const url = new URL(request.url)
      const page = url.searchParams.get('page') ?? undefined
      if (page == null) {
        return {
          type: 'redirect',
          value: redirect(routePaths.history({ query: { page: '1' } })),
        }
      }
      const pageNumber = Number(page)
      if (Number.isNaN(pageNumber)) {
        throw new Response('page is not a number', { status: 404 })
      }

      const pageToken = createHistoryPageToken(pageNumber)

      return {
        type: 'success',
        value: new Promise((resolve) => {
          resolve({
            pageNumber,
            pageToken,
          })
        }),
      }
    }

    const { type, value } = getUrlParams()
    if (type === 'redirect') {
      return value
    }

    return defer({
      urlParams: value,
    })
  }
}
