import * as React from 'react'
import type { SVGProps } from 'react'
import { memo } from 'react'

const SvgMoreIcon: React.FC<SVGProps<SVGSVGElement>> = (
  props: SVGProps<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      d="M15.646 12.002c.021-.434.173-.803.456-1.107a1.699 1.699 0 0 1 1.106-.455c.434.021.803.173 1.107.455.282.304.434.673.456 1.107a1.699 1.699 0 0 1-.456 1.107 1.699 1.699 0 0 1-1.107.456 1.699 1.699 0 0 1-1.106-.456 1.698 1.698 0 0 1-.456-1.107Zm-5.209 0c.022-.434.174-.803.456-1.107A1.699 1.699 0 0 1 12 10.44c.434.021.803.173 1.107.455.282.304.434.673.456 1.107a1.699 1.699 0 0 1-.456 1.107 1.699 1.699 0 0 1-1.107.456 1.699 1.699 0 0 1-1.107-.456 1.699 1.699 0 0 1-.456-1.107Zm-2.083 0a1.699 1.699 0 0 1-.456 1.107 1.699 1.699 0 0 1-1.106.456 1.699 1.699 0 0 1-1.107-.456 1.699 1.699 0 0 1-.456-1.107c.022-.434.174-.803.456-1.107a1.699 1.699 0 0 1 1.107-.455c.434.021.803.173 1.106.455.283.304.434.673.456 1.107Z"
    />
  </svg>
)
const Memo = memo(SvgMoreIcon)
export default Memo
