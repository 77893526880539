import * as React from 'react'
import type { SVGProps } from 'react'
import { memo } from 'react'

const SvgDataIcon: React.FC<SVGProps<SVGSVGElement>> = (
  props: SVGProps<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 64 64"
    {...props}
  >
    <path
      fill="currentColor"
      d="M23.773 26.06c-.8 0-1.457-.257-1.972-.771-.514-.515-.771-1.172-.771-1.972s.257-1.457.771-1.97c.515-.515 1.172-.772 1.972-.772s1.457.257 1.97.771c.515.514.772 1.172.772 1.971 0 .8-.257 1.457-.771 1.972-.514.514-1.172.771-1.971.771Zm6.17-2.742c.114-1.258.8-1.943 2.057-2.057h8.227c1.258.114 1.943.8 2.057 2.056-.114 1.258-.8 1.943-2.056 2.057H32c-1.257-.114-1.943-.8-2.057-2.056Zm0 8.227c.114-1.257.8-1.943 2.057-2.057h8.227c1.258.114 1.943.8 2.057 2.057-.114 1.257-.8 1.943-2.056 2.057H32c-1.257-.114-1.943-.8-2.057-2.057Zm0 8.227c.114-1.256.8-1.942 2.057-2.056h8.227c1.258.114 1.943.8 2.057 2.056-.114 1.258-.8 1.943-2.056 2.057H32c-1.257-.114-1.943-.8-2.057-2.056Zm-6.17-10.97c.8 0 1.457.258 1.97.772.515.514.772 1.171.772 1.971s-.257 1.457-.771 1.971-1.172.772-1.971.772c-.8 0-1.457-.258-1.972-.772-.514-.514-.771-1.171-.771-1.971s.257-1.457.771-1.971c.515-.514 1.172-.772 1.972-.772Zm0 13.713c-.8 0-1.457-.257-1.972-.771-.514-.514-.771-1.172-.771-1.971 0-.8.257-1.458.771-1.972.515-.514 1.172-.771 1.972-.771s1.457.257 1.97.771c.515.514.772 1.172.772 1.971 0 .8-.257 1.458-.771 1.972s-1.172.771-1.971.771Zm-10.97-24.683c.057-1.542.6-2.828 1.628-3.856 1.028-1.029 2.314-1.571 3.857-1.629h27.424c1.543.058 2.829.6 3.857 1.629 1.029 1.028 1.571 2.314 1.628 3.856v27.425c-.057 1.543-.6 2.829-1.628 3.857-1.028 1.029-2.314 1.571-3.857 1.629H18.287c-1.542-.058-2.828-.6-3.856-1.629-1.029-1.028-1.571-2.314-1.628-3.856V17.832Zm4.113 0v27.425c.057.858.514 1.315 1.372 1.372h27.424c.857-.057 1.315-.514 1.372-1.371V17.832c-.057-.857-.515-1.314-1.372-1.37H18.287c-.857.056-1.314.514-1.37 1.37Z"
    />
  </svg>
)
const Memo = memo(SvgDataIcon)
export default Memo
