import * as React from 'react'

import { Button, Text, Tooltip } from '@chakra-ui/react'

import { SemanticColors, Typography } from '@enechain/ecloud-designsystem'
import { DownloadFileIcon } from '@enechain/etender-icons'

export type DownloadAttachmentFileButtonProps = {
  fileName: string
  onClick?: () => void
}

const DownloadAttachmentFileButton: React.FC<
  DownloadAttachmentFileButtonProps
> = ({ fileName, onClick }) => {
  return (
    <Tooltip label={fileName} placement="bottom-start">
      <Button
        justifyContent="flex-start"
        gap={2}
        overflow="hidden"
        width="100%"
        height="32px"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        backgroundColor="transparent"
        iconSpacing={0}
        leftIcon={
          <DownloadFileIcon
            width="24px"
            height="24px"
            color={SemanticColors.Object.midEmphasis}
          />
        }
        onClick={onClick}
        paddingX={1}
      >
        <Text
          overflow="hidden"
          textColor={SemanticColors.Text.highEmphasis}
          textOverflow="ellipsis"
          {...Typography.textMd}
        >
          {fileName}
        </Text>
      </Button>
    </Tooltip>
  )
}

export default React.memo(DownloadAttachmentFileButton)
