export type HistoryColumnKey =
  | 'bid_or_offer'
  | 'bidId'
  | 'unitPrice'
  | 'volume'
  | 'dealStatus'
  | 'dealOrganizationName'
  | 'dealUnitPrice'
  | 'dealVolume'
  | 'recap'

export const HistoryColumnStyle = {
  bid_or_offer: {
    alignment: 'left',
    width: {
      type: 'ratio',
      ratio: 1,
    },
  },
  bidId: {
    alignment: 'left',
    width: {
      type: 'ratio',
      ratio: 1,
    },
  },
  unitPrice: {
    alignment: 'left',
    width: {
      type: 'ratio',
      ratio: 1,
    },
  },
  volume: {
    alignment: 'left',
    width: {
      type: 'ratio',
      ratio: 1,
    },
  },
  dealStatus: {
    alignment: 'left',
    width: {
      type: 'ratio',
      ratio: 1,
    },
  },
  dealOrganizationName: {
    alignment: 'left',
    width: {
      type: 'ratio',
      ratio: 1,
    },
  },
  dealUnitPrice: {
    alignment: 'left',
    width: {
      type: 'ratio',
      ratio: 1,
    },
  },
  dealVolume: {
    alignment: 'left',
    width: {
      type: 'ratio',
      ratio: 1,
    },
  },
  recap: {
    alignment: 'left',
    width: {
      type: 'ratio',
      ratio: 1,
    },
  },
} as const
