import * as React from 'react'

import { DownloadAttachmentFileButton } from '@enechain/common-ui'
import { Attachment } from '@enechain/etender-proto/proto/etender/tender/v1/model_pb'

import { useDownloadAttachmentFile } from '~/pages/tender/hooks/useDownloadAttachmentFile'

type Props = {
  tenderId: string
  attachment: Attachment
}

const AttachmentFileItem: React.FC<Props> = ({ tenderId, attachment }) => {
  const mutation = useDownloadAttachmentFile(attachment.name)

  const onClick = (): void => {
    mutation.mutate({
      tenderId,
      attachmentId: attachment.id,
    })
  }

  return (
    <DownloadAttachmentFileButton
      fileName={attachment.name}
      onClick={onClick}
    />
  )
}

export default React.memo(AttachmentFileItem)
