// @generated by protoc-gen-es v1.9.0 with parameter "target=js+dts"
// @generated from file etender/bid/v1/model.proto (package etender.bid.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { proto3 } from "@bufbuild/protobuf";

/**
 * *
 * 応札のバリデーションエラーが発生したフィールド
 *
 * @generated from enum etender.bid.v1.ValidationErrorField
 */
export const ValidationErrorField = /*@__PURE__*/ proto3.makeEnum(
  "etender.bid.v1.ValidationErrorField",
  [
    {no: 0, name: "VALIDATION_ERROR_FIELD_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "VALIDATION_ERROR_FIELD_TENDER_TERMS", localName: "TENDER_TERMS"},
    {no: 2, name: "VALIDATION_ERROR_FIELD_UNIT_PRICE", localName: "UNIT_PRICE"},
    {no: 3, name: "VALIDATION_ERROR_FIELD_VOLUME", localName: "VOLUME"},
  ],
);

/**
 * *
 * 応札のバリデーションエラーの種類
 *
 * @generated from enum etender.bid.v1.ValidationErrorType
 */
export const ValidationErrorType = /*@__PURE__*/ proto3.makeEnum(
  "etender.bid.v1.ValidationErrorType",
  [
    {no: 0, name: "VALIDATION_ERROR_TYPE_UNSPECIFIED", localName: "UNSPECIFIED"},
    {no: 1, name: "VALIDATION_ERROR_TYPE_REQUIRED", localName: "REQUIRED"},
    {no: 2, name: "VALIDATION_ERROR_TYPE_INVALID_FORMAT", localName: "INVALID_FORMAT"},
    {no: 3, name: "VALIDATION_ERROR_TYPE_OUT_OF_RANGE", localName: "OUT_OF_RANGE"},
    {no: 4, name: "VALIDATION_ERROR_TYPE_DUPLICATED", localName: "DUPLICATED"},
  ],
);

/**
 * 応札
 *
 * @generated from message etender.bid.v1.Bid
 */
export const Bid = /*@__PURE__*/ proto3.makeMessageType(
  "etender.bid.v1.Bid",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "order_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "tender_terms_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "unit_price", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "volume", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "can_be_partial_execution", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "remarks", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ],
);

/**
 * 応札の一覧
 *
 * @generated from message etender.bid.v1.Bids
 */
export const Bids = /*@__PURE__*/ proto3.makeMessageType(
  "etender.bid.v1.Bids",
  () => [
    { no: 1, name: "bids", kind: "message", T: Bid, repeated: true },
  ],
);

/**
 * 編集中の応札
 *
 * @generated from message etender.bid.v1.EditingBid
 */
export const EditingBid = /*@__PURE__*/ proto3.makeMessageType(
  "etender.bid.v1.EditingBid",
  () => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "tender_terms_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "unit_price", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "volume", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 8, name: "validation_result", kind: "message", T: ValidationResult },
  ],
);

/**
 * *
 * 応札のバリデーション結果
 *
 * @generated from message etender.bid.v1.ValidationResult
 */
export const ValidationResult = /*@__PURE__*/ proto3.makeMessageType(
  "etender.bid.v1.ValidationResult",
  () => [
    { no: 1, name: "is_valid", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "errors", kind: "message", T: ValidationError, repeated: true },
  ],
);

/**
 * *
 * 応札のバリデーションエラー
 *
 * @generated from message etender.bid.v1.ValidationError
 */
export const ValidationError = /*@__PURE__*/ proto3.makeMessageType(
  "etender.bid.v1.ValidationError",
  () => [
    { no: 1, name: "field", kind: "enum", T: proto3.getEnumType(ValidationErrorField) },
    { no: 2, name: "type", kind: "enum", T: proto3.getEnumType(ValidationErrorType) },
    { no: 3, name: "message", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ],
);

