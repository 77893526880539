import * as React from 'react'
import type { SVGProps } from 'react'
import { memo } from 'react'

const SvgEmptyIcon: React.FC<SVGProps<SVGSVGElement>> = (
  props: SVGProps<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#000"
      fillOpacity={0.54}
      d="M16.748 11.17c.071.269.107.545.107.83v2.143c-.018.482-.187.884-.509 1.205-.321.321-.723.491-1.205.509H4.86a1.731 1.731 0 0 1-1.205-.509 1.73 1.73 0 0 1-.509-1.205v-2.142c0-.286.036-.562.107-.83l1.634-6.694c.124-.393.401-.607.83-.643h8.568c.429.018.705.24.83.67l1.634 6.667ZM6.064 5.147l-1.393 5.57h2.33c.268.018.464.134.589.348l.67 1.365h3.48l.697-1.365c.107-.214.294-.33.562-.348h2.33l-1.366-5.57h-7.9Zm9.506 8.997v-2.142H13.4l-.696 1.366c-.107.214-.294.33-.562.348H7.858c-.268-.018-.455-.134-.563-.348L6.6 12H4.43v2.142c.018.268.161.41.429.429H15.14c.268-.018.41-.161.429-.429Z"
    />
  </svg>
)
const Memo = memo(SvgEmptyIcon)
export default Memo
