import * as React from 'react'
import type { SVGProps } from 'react'
import { memo } from 'react'

const SvgNegativeHandshakeIcon: React.FC<SVGProps<SVGSVGElement>> = (
  props: SVGProps<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 20 21"
    {...props}
  >
    <path
      fill="#870103"
      d="M16.828 13.786a.802.802 0 0 1-.589-.24.834.834 0 0 1-.24-.617V8.217h-1.474a.642.642 0 0 1-.321-.215c-.018-.071-.268-.33-.75-.776s-1.133-.759-1.955-.937c-.285-.036-.544.053-.776.267L8.768 8.565l.75.589.99-1.045c.304-.25.608-.25.911 0 .25.304.25.607 0 .91l-.91.911 7.792 6.105c.286.268.321.572.107.911-.268.286-.571.321-.91.107L1.672 4.655c-.285-.268-.321-.571-.107-.91a.66.66 0 0 1 .91-.107l3.294 2.57c.304-.25.67-.49 1.098-.723.41-.214.893-.366 1.446-.455a2.014 2.014 0 0 1 1.66.482 2.13 2.13 0 0 1 1.714-.482c.821.16 1.508.437 2.062.83.553.41.955.768 1.205 1.071h3.588v6.855h-1.714Zm.429-1.713c-.25.035-.393.17-.429.401.036.268.179.41.429.429.25-.018.383-.161.401-.429-.018-.232-.151-.366-.401-.402ZM7.75 7.76l1.312-1.339A.91.91 0 0 0 8.5 6.29c-.678.142-1.24.392-1.686.75l.937.722Zm3.668 6.56.214-.24 1.58 1.232a1.38 1.38 0 0 1-.964-.027 1.533 1.533 0 0 1-.883.428 1.584 1.584 0 0 1-1.152-.348l-.027-.027a2.498 2.498 0 0 1-1.017.375h-.214c-.518 0-.991-.17-1.42-.509l-2.061-1.847H3.84a.848.848 0 0 1-.723.428H1.431V6.931h1.071l1.5 1.178v3.963h1.981l2.41 2.17c.179.142.402.205.67.187a.942.942 0 0 0 .616-.348l.08-.08.402-.349.883.723c.072.054.134.072.188.054.036 0 .098-.036.187-.107ZM2.717 12.93c.267-.018.401-.16.401-.428-.035-.25-.17-.384-.401-.402-.233.018-.366.152-.402.402.018.268.152.41.402.428Z"
    />
  </svg>
)
const Memo = memo(SvgNegativeHandshakeIcon)
export default Memo
