import * as React from 'react'

import { HelmetProvider } from 'react-helmet-async'
import { RecoilRoot } from 'recoil'

import { ChakraProvider, type ThemeConfig, extendTheme } from '@chakra-ui/react'

import { theme } from '@enechain/ecloud-designsystem'

type Props = {
  children: React.ReactNode
}

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
}

const LibProvider: React.FC<Props> = ({ children }: Props) => {
  return (
    <ChakraProvider
      theme={extendTheme(theme, {
        config,
      })}
    >
      <RecoilRoot>
        <HelmetProvider>{children}</HelmetProvider>
      </RecoilRoot>
    </ChakraProvider>
  )
}

export default LibProvider
