import * as React from 'react'

import { Flex, Text } from '@chakra-ui/react'

import {
  BorderRadius,
  SemanticColors,
  Spacing,
  Typography,
} from '@enechain/ecloud-designsystem'

type Props = {
  children: JSX.Element
  label: string
  description?: string
}

const FormContainer: React.FC<Props> = ({ children, label, description }) => {
  return (
    <Flex flexDirection="column" gap={Spacing['4']}>
      <Flex flexDirection="column" gap={Spacing['1']}>
        <Text color={SemanticColors.Text.highEmphasis} {...Typography.headerLg}>
          {label}
        </Text>
        {description != null && (
          <Text color={SemanticColors.Text.highEmphasis} {...Typography.textMd}>
            {description}
          </Text>
        )}
      </Flex>
      <Flex
        flexDirection="column"
        gap={Spacing['6']}
        display="flex"
        padding={Spacing['6']}
        borderWidth="1px"
        borderColor={SemanticColors.Border.midEmphasis}
        borderRadius={BorderRadius.radiiLg}
      >
        {children}
      </Flex>
    </Flex>
  )
}

export default React.memo(FormContainer)
