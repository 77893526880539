import * as React from 'react'

import { useTranslation } from 'react-i18next'

import { Flex } from '@chakra-ui/react'

import { Spacing } from '@enechain/ecloud-designsystem'

import EclearForm from '~/pages/order-confirm/components/AdditionalFieldsConfirmSection/EclearForm'
import FormContainer from '~/pages/order-confirm/components/AdditionalFieldsConfirmSection/FormContainer'
import TocomForm from '~/pages/order-confirm/components/AdditionalFieldsConfirmSection/TocomForm'
import { useEditingOrder } from '~/pages/order/hooks/useEditingOrder'

type AdditionalFieldsConfirmSectionData = {
  isShowEclearForm: boolean
  isShowEexForm: boolean
  isShowTocomForm: boolean
}

const useAdditionalFieldsConfirmSectionData = (
  orderId: string,
): AdditionalFieldsConfirmSectionData | undefined => {
  const { data } = useEditingOrder(orderId, (response) => {
    if (response.additionalFields?.requirements == null) {
      return undefined
    }
    const requirements = response.additionalFields.requirements
    return {
      isShowEclearForm: requirements.eclear,
      isShowEexForm: requirements.eex,
      isShowTocomForm: requirements.tocom,
    }
  })
  return data
}

type Props = {
  orderId: string
}

const AdditionalFieldsConfirmSection: React.FC<Props> = ({ orderId }) => {
  const { t } = useTranslation('trader', {
    keyPrefix: 'order_confirm.additional_fields_section',
  })

  const data = useAdditionalFieldsConfirmSectionData(orderId)

  if (
    data == null ||
    (!data.isShowEclearForm && !data.isShowEexForm && !data.isShowTocomForm)
  ) {
    return null
  }

  return (
    <Flex flexDirection="column" gap={Spacing['4']}>
      {data.isShowEclearForm && (
        <FormContainer
          description={t('eclear.description')}
          label={t('eclear.label')}
        >
          <EclearForm orderId={orderId} />
        </FormContainer>
      )}
      {data.isShowTocomForm && (
        <FormContainer label={t('tocom.label')}>
          <TocomForm orderId={orderId} />
        </FormContainer>
      )}
    </Flex>
  )
}

export default React.memo(AdditionalFieldsConfirmSection)
