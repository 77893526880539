import * as React from 'react'
import { ChangeEventHandler, FocusEventHandler, useState } from 'react'

import { useDebounce } from 'react-use'

type TargetHTMLElement = HTMLInputElement | HTMLTextAreaElement

export type UseDebouncedInputProps = {
  initialValue: string
  onChange: (newValue: string) => void
  milliseconds: number
}

type UseDebouncedInputReturn<T extends TargetHTMLElement> = {
  value: string
  onChange: ChangeEventHandler<T>
  onBlur: FocusEventHandler<T>
}

export function useDebouncedInput<T extends TargetHTMLElement>({
  initialValue,
  onChange,
  milliseconds,
}: UseDebouncedInputProps): UseDebouncedInputReturn<T> {
  const [previous, setPrevious] = useState(initialValue)
  const [current, setCurrent] = useState(initialValue)

  useDebounce(
    () => {
      if (previous === current) {
        return
      }
      setPrevious(current)
      onChange(current)
    },
    milliseconds,
    [current],
  )

  function _onChange(e: React.ChangeEvent<T>): void {
    setCurrent(e.target.value)
  }

  function onBlur(e: React.FocusEvent<T>): void {
    if (previous === e.target.value) {
      return
    }
    setPrevious(current)
    onChange(e.target.value)
  }

  return {
    value: current,
    onChange: _onChange,
    onBlur,
  }
}
