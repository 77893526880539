// ref: https://date-fns.org/v2.29.3/docs/format
export enum DateFormat {
  RFC3339 = 'yyyy-MM-ddTHH:mm:ssZ',
  DATE = 'yyyy-MM-dd',
  DATE_TIME_HOUR = 'yyyy-MM-dd HH',
  DATE_TIME_MINUTE = 'yyyy-MM-dd HH:mm',
  DATE_TIME_MINUTE_UNDERSCORE = 'yyyyMMdd_HHmmss',
  DATE_TIME_MINUTE_SLASH = 'yyyy/MM/dd HH:mm',
  DATE_TIME = 'yyyy-MM-dd HH:mm:ss',
  DATE_TIME_SLASH = 'yyyy/MM/dd HH:mm:ss',
  DATE_WEEK_OF_DAY = 'yyyy-MM-dd (eee)',
  DATE_SLASH = 'yyyy/MM/dd',
  DATE_JA = 'yyyy年MM月dd日',
  DATE_JA_SLASH = 'yyyy/MM/dd(ddd)',
  DATE_TIME_JA = 'yyyy年MM月dd日(ddd) HH:mm',
  DATE_TIME_JA_SLASH = 'yyyy/MM/dd/(ddd) HH:mm',
  TIME = 'HH:mm:ss.SSSZ',
  MINUTES = 'mm:ss.SSSZ',
  HOUR_MINUTE = 'HH:mm',
  YEAR = 'yyyy',
  YEAR_JA = 'yyyy年',
  MONTH = 'MM',
  MONTH_JA = 'MM月',
  DAY_JA = 'dd日',
  MONTH_DAY_TIME_JA = 'M月d日（ddd） HH:mm',
  YEAR_MONTH = 'yyyy-MM',
  YEAR_MONTH_SLASH = 'yyyy/MM',
  YEAR_MONTH_JA = 'yyyy年M月',
  BIGQUERY_TIMESTAMP = 'yyyy-MM-dd HH:mm:ss.SSSZ',
}
