// @generated by protoc-gen-connect-query v1.3.1 with parameter "target=js+dts"
// @generated from file etender/tender_terms/v1/service.proto (package etender.tender_terms.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { MethodKind } from "@bufbuild/protobuf";
import { BatchListEditableRequest, BatchListEditableResponse, BatchListRequest, BatchListResponse, CloneRequest, CloneResponse, CreateEmptyRequest, CreateEmptyResponse, DeleteRequest, DeleteResponse, GetSelectableInputsRequest, GetSelectableInputsResponse, ListEditableRequest, ListEditableResponse, UpdateRequest, UpdateResponse } from "./service_pb.js";

/**
 * 空の入札条件を作成する
 *
 * @generated from rpc etender.tender_terms.v1.Service.CreateEmpty
 */
export const createEmpty = {
  localName: "createEmpty",
  name: "CreateEmpty",
  kind: MethodKind.Unary,
  I: CreateEmptyRequest,
  O: CreateEmptyResponse,
  service: {
    typeName: "etender.tender_terms.v1.Service"
  }
};

/**
 * 入札条件を複製する
 *
 * @generated from rpc etender.tender_terms.v1.Service.Clone
 */
export const clone = {
  localName: "clone",
  name: "Clone",
  kind: MethodKind.Unary,
  I: CloneRequest,
  O: CloneResponse,
  service: {
    typeName: "etender.tender_terms.v1.Service"
  }
};

/**
 * 複数の入札案件の有効な入札条件の一覧を取得する
 *
 * @generated from rpc etender.tender_terms.v1.Service.BatchList
 */
export const batchList = {
  localName: "batchList",
  name: "BatchList",
  kind: MethodKind.Unary,
  I: BatchListRequest,
  O: BatchListResponse,
  service: {
    typeName: "etender.tender_terms.v1.Service"
  }
};

/**
 * 編集可能な入札条件の一覧を取得する
 *
 * @generated from rpc etender.tender_terms.v1.Service.ListEditable
 */
export const listEditable = {
  localName: "listEditable",
  name: "ListEditable",
  kind: MethodKind.Unary,
  I: ListEditableRequest,
  O: ListEditableResponse,
  service: {
    typeName: "etender.tender_terms.v1.Service"
  }
};

/**
 * 複数の入札案件の編集可能で有効な入札条件の一覧を取得する
 *
 * @generated from rpc etender.tender_terms.v1.Service.BatchListEditable
 */
export const batchListEditable = {
  localName: "batchListEditable",
  name: "BatchListEditable",
  kind: MethodKind.Unary,
  I: BatchListEditableRequest,
  O: BatchListEditableResponse,
  service: {
    typeName: "etender.tender_terms.v1.Service"
  }
};

/**
 * 入札条件を更新する
 *
 * @generated from rpc etender.tender_terms.v1.Service.Update
 */
export const update = {
  localName: "update",
  name: "Update",
  kind: MethodKind.Unary,
  I: UpdateRequest,
  O: UpdateResponse,
  service: {
    typeName: "etender.tender_terms.v1.Service"
  }
};

/**
 * 入札条件を削除する
 *
 * @generated from rpc etender.tender_terms.v1.Service.Delete
 */
export const delete$ = {
  localName: "delete",
  name: "Delete",
  kind: MethodKind.Unary,
  I: DeleteRequest,
  O: DeleteResponse,
  service: {
    typeName: "etender.tender_terms.v1.Service"
  }
};

/**
 * 入札条件の設定可能な入力項目を取得する
 *
 * @generated from rpc etender.tender_terms.v1.Service.GetSelectableInputs
 */
export const getSelectableInputs = {
  localName: "getSelectableInputs",
  name: "GetSelectableInputs",
  kind: MethodKind.Unary,
  I: GetSelectableInputsRequest,
  O: GetSelectableInputsResponse,
  service: {
    typeName: "etender.tender_terms.v1.Service"
  }
};
