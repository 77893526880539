import * as React from 'react'
import type { SVGProps } from 'react'
import { memo } from 'react'

const SvgCollapseIcon: React.FC<SVGProps<SVGSVGElement>> = (
  props: SVGProps<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 26 26"
    {...props}
  >
    <path
      fill="currentColor"
      d="M14.063 11.531a.774.774 0 0 0 .406.406.62.62 0 0 0 .281.063h4.469c.479-.042.74-.292.781-.75-.042-.458-.292-.708-.75-.75h-2.688l4.22-4.219c.29-.354.29-.708 0-1.062-.355-.292-.71-.292-1.063 0L15.5 9.437V6.75c-.042-.458-.292-.708-.75-.75-.458.042-.708.292-.75.75v4.5c0 .104.02.198.063.281ZM11.938 14.469a.774.774 0 0 0-.407-.406.62.62 0 0 0-.281-.063H6.781c-.479.042-.74.292-.781.75.042.458.292.708.75.75h2.688l-4.22 4.219c-.29.354-.29.708 0 1.062.355.292.71.292 1.063 0l4.219-4.218v2.687c.042.458.292.708.75.75.458-.042.708-.292.75-.75v-4.5a.62.62 0 0 0-.063-.281Z"
    />
  </svg>
)
const Memo = memo(SvgCollapseIcon)
export default Memo
