import * as React from 'react'
import type { SVGProps } from 'react'
import { memo } from 'react'

const SvgChevronUpIcon: React.FC<SVGProps<SVGSVGElement>> = (
  props: SVGProps<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="#000"
      fillOpacity={0.62}
      d="m5.281 13.838 6.173-5.883c.17-.193.353-.289.546-.289.193 0 .375.075.546.225l6.14 5.883c.3.365.3.729 0 1.093-.342.3-.706.311-1.092.032L12 9.531l-5.626 5.4c-.386.3-.75.29-1.093-.032-.3-.364-.3-.718 0-1.06Z"
    />
  </svg>
)
const Memo = memo(SvgChevronUpIcon)
export default Memo
