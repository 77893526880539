import * as React from 'react'
import type { SVGProps } from 'react'
import { memo } from 'react'

const SvgBellIcon: React.FC<SVGProps<SVGSVGElement>> = (
  props: SVGProps<SVGSVGElement>,
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 25 24"
    {...props}
  >
    <path
      fill="#0C00C5"
      d="M13.094 5.126v.579c1.2.214 2.176.76 2.926 1.64.771.878 1.168 1.94 1.19 3.182v1.061c.02 1.5.492 2.84 1.414 4.019l.482.61c.193.258.225.526.096.804a.816.816 0 0 1-.707.45H5.635a.816.816 0 0 1-.707-.45c-.128-.278-.096-.546.097-.803l.482-.611a6.508 6.508 0 0 0 1.415-4.019v-1.06c.021-1.244.418-2.305 1.19-3.184.75-.878 1.724-1.425 2.925-1.64v-.578c0-.3.096-.546.289-.74.193-.192.44-.288.74-.288s.546.096.739.289c.193.193.29.44.29.74Zm-1.286 2.058c-.943.021-1.736.343-2.379.964-.621.643-.943 1.436-.964 2.38v1.06a7.748 7.748 0 0 1-1.286 4.34h9.773a7.748 7.748 0 0 1-1.286-4.34v-1.06c-.021-.944-.343-1.737-.964-2.38-.643-.621-1.436-.943-2.38-.964h-.514ZM14.123 18.5c0 .557-.204 1.04-.61 1.447-.408.407-.89.61-1.448.61-.557 0-1.039-.203-1.446-.61a1.977 1.977 0 0 1-.611-1.447h4.115ZM1.778 10.527c.043-.471.3-.729.771-.771h2.572c.472.042.73.3.772.771-.043.472-.3.729-.772.772H2.55c-.471-.043-.728-.3-.771-.772Zm19.803-.771c.472.042.73.3.772.771-.043.472-.3.729-.772.772H19.01c-.471-.043-.728-.3-.771-.772.043-.471.3-.729.771-.771h2.572Zm-.578-4.983c.15.45.032.793-.354 1.028l-2.057 1.03c-.45.17-.793.063-1.03-.322-.17-.45-.064-.793.322-1.03l2.058-1.028c.45-.171.803-.064 1.06.322ZM3.482 5.8c-.386-.235-.493-.578-.322-1.028.236-.386.579-.493 1.03-.322L6.246 5.48c.385.236.503.579.353 1.029-.257.385-.61.493-1.06.321L3.481 5.801Z"
    />
  </svg>
)
const Memo = memo(SvgBellIcon)
export default Memo
