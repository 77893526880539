import * as React from 'react'
import { SVGProps } from 'react'

import { Flex, Text } from '@chakra-ui/react'

import {
  SemanticColors,
  Spacing,
  Typography,
} from '@enechain/ecloud-designsystem'

type Props = {
  icon: React.ElementType<SVGProps<SVGSVGElement>>
  title: string
  data: string | React.ReactNode
}

const InformationLayout: React.FC<Props> = ({
  icon: IconComponent,
  title,
  data,
}) => {
  return (
    <Flex>
      <Flex alignItems="center" flex={1} gap={Spacing['1']}>
        <IconComponent
          width="20px"
          height="20px"
          color={SemanticColors.Object.midEmphasis}
        />
        <Text color={SemanticColors.Text.midEmphasis} {...Typography.textMd}>
          {title}
        </Text>
      </Flex>
      <Flex alignItems="center" flex={3} gap={Spacing['2']}>
        <Text color={SemanticColors.Text.highEmphasis} {...Typography.textMd}>
          :
        </Text>
        {typeof data === 'string' ? (
          <Text color={SemanticColors.Text.highEmphasis} {...Typography.textMd}>
            {data}
          </Text>
        ) : (
          data
        )}
      </Flex>
    </Flex>
  )
}

export default React.memo(InformationLayout)
