import * as React from 'react'

import { useTranslation } from 'react-i18next'

import { Box } from '@chakra-ui/react'

import {
  FlexTable,
  FlexTableBody,
  FlexTableCell,
  FlexTableHeader,
  FlexTableRow,
  SemanticColors,
} from '@enechain/ecloud-designsystem'

import EmptyBidRow from '~/components/order/EmptyBidRow'
import {
  BidConfirmColumnKey,
  BidConfirmColumnStyle,
} from '~/pages/order-confirm/components/OrderConfirmSection/BidConfirmTable/BidConfirmColumnStyle'
import BidConfirmTableBodyRow from '~/pages/order-confirm/components/OrderConfirmSection/BidConfirmTable/BidConfirmTableBodyRow'
import { useEditingOrder } from '~/pages/order-confirm/hooks/useEditingOrder'

type BidTableData = {
  bidIds: string[]
}

const useBidTableData = (
  orderId: string,
  tenderTermsId: string,
): BidTableData => {
  const { data } = useEditingOrder(orderId, (response) => {
    return {
      bidIds:
        response.editingOrder?.bids
          ?.filter((bid) => bid.tenderTermsId === tenderTermsId)
          .map((bid) => bid.id) ?? [],
    }
  })

  return data
}

type Props = {
  orderId: string
  tenderTermsId: string
}

const BidConfirmTable: React.FC<Props> = ({ orderId, tenderTermsId }) => {
  const { t } = useTranslation('trader', {
    keyPrefix: 'bid_table',
  })

  const { bidIds } = useBidTableData(orderId, tenderTermsId)

  return (
    <FlexTable<BidConfirmColumnKey> columnStyles={BidConfirmColumnStyle}>
      <FlexTableHeader style="sub-table">
        <FlexTableRow>
          <FlexTableCell<BidConfirmColumnKey> columnKey="accordionSpace" />
          <FlexTableCell<BidConfirmColumnKey>
            columnKey="bidId"
            contents={{ text: t('bid_id') }}
          />
          <FlexTableCell<BidConfirmColumnKey>
            columnKey="unitPrice"
            contents={{ text: t('unit_price.label') }}
          />
          <FlexTableCell<BidConfirmColumnKey>
            columnKey="volume"
            contents={{ text: t('volume.label') }}
          />
        </FlexTableRow>
      </FlexTableHeader>
      <FlexTableBody style="sub-table">
        {bidIds.length > 0 ? (
          bidIds.map((bidId) => (
            <Box
              key={bidId}
              borderColor={SemanticColors.Border.lowEmphasis}
              borderBottomWidth="1px"
              _last={{ borderBottomWidth: 0 }}
            >
              <BidConfirmTableBodyRow
                bidId={bidId}
                orderId={orderId}
                tenderTermsId={tenderTermsId}
              />
            </Box>
          ))
        ) : (
          <EmptyBidRow />
        )}
      </FlexTableBody>
    </FlexTable>
  )
}

export default React.memo(BidConfirmTable)
