// @generated by protoc-gen-connect-query v1.3.1 with parameter "target=js+dts"
// @generated from file etender/order/v1/service.proto (package etender.order.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { MethodKind } from "@bufbuild/protobuf";
import { ApplyRequest, ApplyResponse, CreateEmptyByAgentRequest, CreateEmptyByAgentResponse, CreateEmptyRequest, CreateEmptyResponse, DeleteRequest, DeleteResponse, DisableRequest, DisableResponse, DiscardRequest, DiscardResponse, EditContentsRequest, EditContentsResponse, GetEditingRequest, GetEditingResponse, ListAllByTenderIDRequest, ListAllByTenderIDResponse, ListByTenderIDRequest, ListByTenderIDResponse, ListRequest, ListResponse } from "./service_pb.js";

/**
 * 自分の注文の一覧を取得する
 *
 * @generated from rpc etender.order.v1.Service.List
 */
export const list = {
  localName: "list",
  name: "List",
  kind: MethodKind.Unary,
  I: ListRequest,
  O: ListResponse,
  service: {
    typeName: "etender.order.v1.Service"
  }
};

/**
 * 指定した入札案件に入った自分の注文の一覧を取得する
 *
 * @generated from rpc etender.order.v1.Service.ListByTenderID
 */
export const listByTenderID = {
  localName: "listByTenderID",
  name: "ListByTenderID",
  kind: MethodKind.Unary,
  I: ListByTenderIDRequest,
  O: ListByTenderIDResponse,
  service: {
    typeName: "etender.order.v1.Service"
  }
};

/**
 * 指定した入札案件に入ったすべての注文を取得する
 *
 * @generated from rpc etender.order.v1.Service.ListAllByTenderID
 */
export const listAllByTenderID = {
  localName: "listAllByTenderID",
  name: "ListAllByTenderID",
  kind: MethodKind.Unary,
  I: ListAllByTenderIDRequest,
  O: ListAllByTenderIDResponse,
  service: {
    typeName: "etender.order.v1.Service"
  }
};

/**
 * 指定した編集中の注文を取得する
 *
 * @generated from rpc etender.order.v1.Service.GetEditing
 */
export const getEditing = {
  localName: "getEditing",
  name: "GetEditing",
  kind: MethodKind.Unary,
  I: GetEditingRequest,
  O: GetEditingResponse,
  service: {
    typeName: "etender.order.v1.Service"
  }
};

/**
 * 空の注文を下書き状態で作成する
 *
 * @generated from rpc etender.order.v1.Service.CreateEmpty
 */
export const createEmpty = {
  localName: "createEmpty",
  name: "CreateEmpty",
  kind: MethodKind.Unary,
  I: CreateEmptyRequest,
  O: CreateEmptyResponse,
  service: {
    typeName: "etender.order.v1.Service"
  }
};

/**
 * 空の注文を下書き状態で代理作成する
 *
 * @generated from rpc etender.order.v1.Service.CreateEmptyByAgent
 */
export const createEmptyByAgent = {
  localName: "createEmptyByAgent",
  name: "CreateEmptyByAgent",
  kind: MethodKind.Unary,
  I: CreateEmptyByAgentRequest,
  O: CreateEmptyByAgentResponse,
  service: {
    typeName: "etender.order.v1.Service"
  }
};

/**
 * 注文内容を編集する
 *
 * @generated from rpc etender.order.v1.Service.EditContents
 */
export const editContents = {
  localName: "editContents",
  name: "EditContents",
  kind: MethodKind.Unary,
  I: EditContentsRequest,
  O: EditContentsResponse,
  service: {
    typeName: "etender.order.v1.Service"
  }
};

/**
 * 注文を申し込む
 *
 * @generated from rpc etender.order.v1.Service.Apply
 */
export const apply = {
  localName: "apply",
  name: "Apply",
  kind: MethodKind.Unary,
  I: ApplyRequest,
  O: ApplyResponse,
  service: {
    typeName: "etender.order.v1.Service"
  }
};

/**
 * 注文を取り下げる
 *
 * @generated from rpc etender.order.v1.Service.Disable
 */
export const disable = {
  localName: "disable",
  name: "Disable",
  kind: MethodKind.Unary,
  I: DisableRequest,
  O: DisableResponse,
  service: {
    typeName: "etender.order.v1.Service"
  }
};

/**
 * 編集中の注文の変更差分を破棄する
 *
 * @generated from rpc etender.order.v1.Service.Discard
 */
export const discard = {
  localName: "discard",
  name: "Discard",
  kind: MethodKind.Unary,
  I: DiscardRequest,
  O: DiscardResponse,
  service: {
    typeName: "etender.order.v1.Service"
  }
};

/**
 * 注文を削除する
 *
 * @generated from rpc etender.order.v1.Service.Delete
 */
export const delete$ = {
  localName: "delete",
  name: "Delete",
  kind: MethodKind.Unary,
  I: DeleteRequest,
  O: DeleteResponse,
  service: {
    typeName: "etender.order.v1.Service"
  }
};
