import { useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import { OrderAdditionalField } from '@enechain/etender-proto/proto/bff/v1/order_model_pb'
import { ValidationErrorType } from '@enechain/etender-proto/proto/etender/order/v1/model_pb'

export function useOrderValidationErrorMessage(
  field: OrderAdditionalField | undefined,
): string | undefined {
  const { t } = useTranslation('trader', {
    keyPrefix: 'order.additional_fields_section.validation_error_type',
  })

  return useMemo(() => {
    if (field == null || field.errors.length === 0) {
      return undefined
    }

    const errorType = field.errors[0].type
    switch (errorType) {
      case ValidationErrorType.REQUIRED:
        return t('required')
      case ValidationErrorType.INVALID_FORMAT:
        return t('invalid_format')
      default:
        return t('error')
    }
  }, [t, field])
}
