import * as React from 'react'

import { useTranslation } from 'react-i18next'

import { FlexTableCell, FlexTableRow } from '@enechain/ecloud-designsystem'
import { Position } from '@enechain/etender-proto/proto/etender/tender_terms/v1/model_pb'

import { TenderTermsColumnKey } from '~/pages/tender/components/TenderTermsTable/TenderTermsColumnStyles'
import { useTenderTermsListByTenderId } from '~/pages/tender/hooks/useTenderTermsList'

type Props = {
  tenderId: string
}

type TenderTermsTableHeaderData = {
  allTenderTermsPosition: 'ask' | 'bid' | 'other'
}

const useTenderTermsTableHeader = (
  tenderId: string,
): TenderTermsTableHeaderData => {
  const positions = useTenderTermsListByTenderId(tenderId, (terms) => {
    return terms.position
  })

  const getAllTenderTermsPosition = (): 'ask' | 'bid' | 'other' => {
    if (positions.length <= 0) {
      return 'other'
    }

    if (positions.every((position) => position === Position.ASK)) {
      return 'ask'
    }

    if (positions.every((position) => position === Position.BID)) {
      return 'bid'
    }

    return 'other'
  }

  return {
    allTenderTermsPosition: getAllTenderTermsPosition(),
  }
}

const TenderTermsTableHeader: React.FC<Props> = ({ tenderId }) => {
  const { t } = useTranslation('trader', {
    keyPrefix: 'tender.tender_terms_table',
  })

  const { allTenderTermsPosition } = useTenderTermsTableHeader(tenderId)

  const getPriceRestrictionLabel = (): string => {
    switch (allTenderTermsPosition) {
      case 'ask':
        return t('min_unit_price')
      case 'bid':
        return t('max_unit_price')
      default:
        return t('price_restriction')
    }
  }

  return (
    <FlexTableRow>
      <FlexTableCell<TenderTermsColumnKey> columnKey="accordionSpace" />
      <FlexTableCell<TenderTermsColumnKey>
        columnKey="position"
        contents={{ text: t('bid_or_offer') }}
      />
      <FlexTableCell<TenderTermsColumnKey>
        columnKey="area"
        contents={{ text: t('area') }}
      />
      <FlexTableCell<TenderTermsColumnKey>
        columnKey="deliveryTerms"
        contents={{ text: t('delivery_terms') }}
      />
      <FlexTableCell<TenderTermsColumnKey>
        columnKey="excludePeriods"
        contents={{ text: t('exclude_periods') }}
      />
      <FlexTableCell<TenderTermsColumnKey>
        columnKey="hourType"
        contents={{ text: t('hour_type') }}
      />
      <FlexTableCell<TenderTermsColumnKey>
        columnKey="productType"
        contents={{ text: t('product_type') }}
      />
      <FlexTableCell<TenderTermsColumnKey>
        columnKey="priceRestriction"
        contents={{ text: getPriceRestrictionLabel() }}
      />
      <FlexTableCell<TenderTermsColumnKey>
        columnKey="maxVolume"
        contents={{ text: t('max_volume') }}
      />
      <FlexTableCell<TenderTermsColumnKey>
        columnKey="minVolume"
        contents={{ text: t('min_volume') }}
      />
      <FlexTableCell<TenderTermsColumnKey>
        columnKey="note"
        contents={{ text: t('note') }}
      />
    </FlexTableRow>
  )
}

export default React.memo(TenderTermsTableHeader)
