import { useSuspenseQuery } from '@connectrpc/connect-query'
import { UseQueryResult } from '@tanstack/react-query'

import { TenderTerms } from '@enechain/etender-proto/proto/etender/tender_terms/v1/model_pb'
import { batchList } from '@enechain/etender-proto/proto/etender/tender_terms/v1/service-Service_connectquery'
import { BatchListResponse } from '@enechain/etender-proto/proto/etender/tender_terms/v1/service_pb'

import { useTenderIds } from '~/pages/tender/hooks/useTenderList'

type TenderTermsListResponse<T> = {
  data: T
} & Pick<UseQueryResult, 'refetch'>

export function useTenderTermsList<T>(
  select: (response: BatchListResponse) => T,
): TenderTermsListResponse<T> {
  const tenderIds = useTenderIds()

  const { data, refetch } = useSuspenseQuery(
    batchList,
    { tenderIds },
    {
      select,
    },
  )

  return {
    data,
    refetch,
  }
}

export function useTenderTermsListByTenderId<T>(
  tenderId: string,
  select: (terms: TenderTerms) => T,
): T[] {
  const { data: tenderTermsList } = useTenderTermsList((response) =>
    response.terms
      .filter((terms) => terms.tenderId === tenderId)
      .map((terms) => {
        return select(terms)
      }),
  )

  return tenderTermsList
}

export function useTenderTermsById<T>(
  tenderTermsId: string,
  select: (terms: TenderTerms) => T,
): T | undefined {
  const { data } = useTenderTermsList((response) => {
    const tenderTerms = response.terms.find(
      (terms) => terms.id === tenderTermsId,
    )

    if (tenderTerms == null) {
      return undefined
    }

    return select(tenderTerms)
  })
  return data
}
